export const Button = ({
    className,
    label,
    disabled,
    onClick = () => {}
}) => {
    return (
    <button className={`py-3 px-5 mt-5 w-full border border-transparent shadow-sm rounded-lg bg-blue-500 text-white 
        hover:bg-blue-600 focus:ring-2 focus:ring-offset-2 focus:outline-none focus:ring-blue-600 disabled:opacity-50
        ${className}`}
        disabled={disabled}
        onClick={onClick}>
        {label}
    </button>)
}