import { useTranslation } from "react-i18next"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { OrderSection } from "../components/OrderSection"
import { NavBar } from "../components/NavBar"
import { joinWords } from "../utils/StringUtils"
import { LogoHeader } from "../components/LogoHeader"
import { CHANGE_ADDRESS } from "../constants/shippingIncidents"
import { PATH_HOME } from "../navigation/RouterPaths"
import { EmailSentIcon } from "../components/Icons/EmailSentIcon"
import { Button } from "../components/Button"
import { addDaysToETA, adjustDateForWeekend } from "./ReportIncidentContainer"
import { FormattedRelativeTime } from "../components/FormattedRelativeTime"
import { editOrderETA } from "../services/orderServices"
import { Loader } from "../components/Loader"
import { useState } from "react"

export const ConfirmationContainer = () => {
    const { i18n } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const data = location?.state?.data
    const [isLoading, setIsLoading] = useState(false)
    const { order_id } = useParams()
    const category = location?.state?.category
    const [errors, setErrors] = useState();

    const handleEditETA = async () => {
        setIsLoading(true)
        try {
            const newEta = adjustDateForWeekend(addDaysToETA(data?.order))
            await editOrderETA(order_id, {
                estimated_time_arrival: newEta,
                delivery_sla: newEta
            })
            setIsLoading(false)
            navigate(`/orders/${order_id}/incident-created`, { state: { data: data, category: category } })
        } catch (error) {
            setIsLoading(false)
            setErrors(values => ({
                ...values,
                serverError: error.code === 'ERR_NETWORK' ?
                    i18n.t('findOrder.form.errors.ERR_NETWORK')
                    : i18n.t(`reportIncident.form.errors.${error.response?.data?.code}`)
            }))
        }
    }


    return (
        <>{isLoading ? (
            <><Loader className="bg-gray-50" show={true}></Loader></>
        ) : errors ? <div>{errors}</div>
            :
            data === undefined ? (
                <Navigate to={PATH_HOME} replace />
            ) : (
                <>
                    <div className="bg-gray-50 py-5 px-10 sm:max-w-[480px]">
                        <LogoHeader src={data?.order.store.logo_file?.path && process.env.REACT_APP_PLATFORM_URL + data?.order.store.logo_file?.path} />
                    </div>
                    <div className="flex flex-col space-y-5 bg-gray-50">
                        <NavBar title={i18n.t('helpOrder.shipping_incident.category.RESCHEDULE')} className='mt-5'
                            onClick={() => navigate(-1)} />
                        <div className="mx-7 px-5 py-5 bg-white rounded-xl ">
                            <div>{i18n.t('reportIncident.form.labels.RESCHEDULE.desire')} <b>{i18n.t('reportIncident.form.labels.RESCHEDULE.confirm')}</b> {i18n.t('reportIncident.form.labels.RESCHEDULE.order_reschedule')}
                            &nbsp;<FormattedRelativeTime
                                className="font-bold text-md"
                                forceDays={true}
                                etaOffset={data?.order.shipping_coverage.eta_margin_of_error_in_hours}
                                date={adjustDateForWeekend(addDaysToETA(data?.order))}
                                time_zone={data?.order.store.warehouses[0]?.time_zone}
                            /> ?
                            </div>

                        </div>
                        <div className="flex flex-row gap-3 px-7">
                            <Button label={i18n.t('reportIncident.form.labels.RESCHEDULE.cancel_request')} onClick={() => navigate(-1)} className="bg-gray-400" />

                            <Button label={i18n.t('reportIncident.form.labels.RESCHEDULE.confirm_request')} onClick={() => handleEditETA()} />
                        </div>
                    </div>
                </>)}
        </>
    )
}