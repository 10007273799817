import { useTranslation } from "react-i18next"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import { OrderSection } from "../components/OrderSection"
import { NavBar } from "../components/NavBar"
import { joinWords } from "../utils/StringUtils"
import { LogoHeader } from "../components/LogoHeader"
import { CHANGE_ADDRESS } from "../constants/shippingIncidents"
import { PATH_HOME } from "../navigation/RouterPaths"
import { EmailSentIcon } from "../components/Icons/EmailSentIcon"

export const ShippingDetailsContainer = () => {
    const { i18n } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const data = location?.state?.data

    const customerData = [
        {
            key: "customer",
            description: <div>
                <p className="text-gray-600">
                    <span className="text-gray-800 font-medium">
                        {i18n.t("orderDetails.shipping.address")}</span>
                        {joinWords(data?.order.shipping.address_1, data?.order.shipping.address_2)}</p>
                <p className="text-gray-600">
                    <span className="text-gray-800 font-medium">
                        {i18n.t("orderDetails.shipping.state")}</span>{(data?.order.shipping?.state || "")}</p>
                <p className="text-gray-600">
                    <span className="text-gray-800 font-medium">
                        {i18n.t("orderDetails.shipping.city")}</span>{(data?.order.shipping?.city || "")}</p>
                <p className="text-gray-600">
                    <span className="text-gray-800 font-medium">
                        {i18n.t("orderDetails.shipping.zip_code")}</span>{(data?.order.shipping?.zip_code || "")}</p>
                </div>
        }
    ]

    const change_address_incident = data?.order?.shipping_incidents.filter(incident => incident.category === CHANGE_ADDRESS)
    const changeAddressIncident = JSON.parse(change_address_incident[0]?.description)
    const referencesData = [
        {
            key: "customer",
            description: <div>
                <p className="text-gray-600">
                    <span className="text-gray-800 font-medium">
                        {i18n.t("orderDetails.shipping.address")}</span>
                        {joinWords(changeAddressIncident?.shipping_address_1, changeAddressIncident?.shipping_address_2)}</p>
                <p className="text-gray-600">
                    <span className="text-gray-800 font-medium">
                        {i18n.t("orderDetails.shipping.state")}</span>{(changeAddressIncident?.state || "")}</p>
                <p className="text-gray-600">
                    <span className="text-gray-800 font-medium">
                        {i18n.t("orderDetails.shipping.city")}</span>{(changeAddressIncident?.city || "")}</p>
                <p className="text-gray-600">
                    <span className="text-gray-800 font-medium">
                        {i18n.t("orderDetails.shipping.zip_code")}</span>{(changeAddressIncident.zip_code || "")}</p>
                </div>
        }
    ]
    
    return (
        <>
        {data === undefined ? (
            <Navigate to={PATH_HOME} replace />
        ) : (
        <>
        <div className="bg-gray-50 py-5 px-10 sm:max-w-[480px]">
            <LogoHeader src={data?.order.store.logo_file?.path && process.env.REACT_APP_PLATFORM_URL + data?.order.store.logo_file?.path}/>
        </div>
        <div className="flex flex-col space-y-5 bg-gray-50">
            <NavBar title={i18n.t('showOrder.address_update')} className='mt-5'
                onClick={() => navigate(-1)}/>
            <div className="flex flex-col space-y-10 mx-5 px-5 py-5 bg-white rounded-xl ">
                <OrderSection customIcon={<EmailSentIcon className="flex-shrink-0 h-5 w-5 text-gray-300 mr-2 font-black" aria-hidden="true" />} title={i18n.t('orderDetails.updated_address')} fieldsData={referencesData}/>
                <OrderSection icon={'customer_data'} title={i18n.t('orderDetails.previous_address')} fieldsData={customerData}/>
            </div>
        </div>
        </>)}
        </>
    )
}