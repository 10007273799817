import React, { useState } from 'react';
import { ChevronLeftIcon } from './Icons/ChevronLeftIcon';
import { XMarkIcon } from '@heroicons/react/24/outline';

export const FilePopover = ({files, onClose, startIndex}) => {
    const [currentIndex, setCurrentIndex] = useState(startIndex)
    const nextImage = () => {
        if(currentIndex < files.length - 1)
            setCurrentIndex(currentIndex + 1)
    }

    const previousImage = () => {
        if(currentIndex > 0)
            setCurrentIndex(currentIndex - 1)
    }

    const canGoNext = currentIndex < files.length - 1
    const canGoPrevious = currentIndex > 0

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start z-50">
            <div className="sm:max-w-[480px] mx-auto flex">

                <div className="w-1/12 flex justify-center items-center min-h-screen">
                    <button disabled={!canGoPrevious} onClick={previousImage} className="p-2 py-4 bg-white rounded-md bg-white/50 disabled:opacity-0">
                        <ChevronLeftIcon className="w-6 h-6" />
                    </button>
                </div>

                <div className="w-10/12 flex flex-col">
                    <div className="self-end mb-2">
                        <button onClick={onClose} className="p-2 px-4 bg-white rounded-md bg-white/50">
                            <XMarkIcon className='w-6 h-6' />
                        </button>
                    </div>
                    <div className="flex-grow flex items-center">
                        <img src={files[currentIndex]?.path} alt="" className="max-w-full max-h-screen" />
                    </div>
                </div>

                <div className="w-1/12 flex justify-center items-center">
                    <button disabled={!canGoNext} onClick={nextImage} className="p-2 py-4 bg-white rounded-md bg-white/50 disabled:opacity-0">
                        <ChevronLeftIcon className="w-6 h-6 transform rotate-180" />
                    </button>
                </div>

            </div>
        </div>
    )
}