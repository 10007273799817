import { Icon } from "./Icons/Icon"

export const NavBar = ({
    className,
    title,
    description,
    onClick = () => {}
}) => {
    return (
        <div>
            <div className={`flex flex-row items-center cursor-pointer px-5 ${className}`}
                onClick={onClick}>
                <Icon iconCode={'left_arrow'} className="h-5 w-5 mr-2"/>
                <div className="font-semibold text-lg">{title}</div>
            </div>
            <div className="pl-12 pr-5 text-gray-500 text-sm">{description}</div>
        </div>
    )
}