export const en = {
    showOrder : {
        title: "Hello!",
        order_number : "Order #{{order_number}} of {{store}}",
        estimated_delivery: "Estimated delivery:",
        rescheduled_delivery: "You rescheduled the delivery for:",
        estimated_delivery_undefined: "It will be shown soon",
        eta_not_available: "Not available",
        extended_zone: "The order has a longer delivery time than usual since its destination is a remote area",
        delivered: "It has been delivered!",
        canceled: "It won't be delivered",
        view_order_details: "View order information",
        shipping_method_label : "Carrier",
        shipping_status_label: "Shipping status",
        view_tracking: "Track shipment from {{carrier_name}}",
        view_tracking_cubbo: "Carrier: Cubbo SD",
        shipping_incident_label : "Order Events",
        help_with_order: "Need help with my order",
        view_proof_of_delivery: "View proof of delivery",
        address_references: "Address complement",
        address_update: "Change in delivery address",
        watch: "See changes",
        replacement_order_link: "here",
        edit_address: "Change address",
        check_address: "Check that the address is complete and correct",
        order_to_be_picked: "This order will be delivered to a physical location for collection",
        pick_up_location_name: "Pick Up Point Name",
        pick_up_location_address: "Address",
        last_delivery_attempt_description: "After failed delivery attempts, the package will return to the warehouse.",
        last_delivery_attempt_help: "If you need any clarification, write to {{store}}",
        timeline: {
            shipping_status: {
                initial: "Ordered",
                processing: "Packing your order",
                shipped: 'Packed, waiting for pickup',
                shipment_in_transit: "In transit",
                shipment_delivered: "Delivered",
                // waiting_pick_up: "Packed, waiting for pickup",
                // shipment_created: "Packed, waiting for pickup",
                shipment_collected: 'Collected by carrier',
                shipment_with_incident: 'Incident during delivery',
                shipment_canceled: 'Shipment Canceled',
                shipment_returned: 'Returning to warehouse',
                // returned: 'Shipment Canceled',
                // returned_by_client: 'Exchange requested',
                returning: 'Returning to warehouse',
                shipment_to_be_self_collected: 'Pickup at location',
                shipment_estimated_arrival: 'Estimated delivery',
                shipment_out_for_delivery: 'Out for delivery',
                delivery_attempt_failed: "Delivery attempt failed",
                replacement_order: "Order Resent",
                error: "Information missing",
                processing_delayed: "Preparing your order",
                unpaid: "Pending payment",
                returned: "Returned to the warehouse",
                return_arrived: "Returned to the warehouse",
                shipped_pickup: "Sent with external courier",
                pending: "Payment received",
                canceled: "Order canceled",
                interrupted: "Order canceled",
                entirely_dropshipping: "Fulfilled Externally"
            },
            shipping_description: {
                initial: "Order {{order_number}}",
                shipped: 'Shipping number: {{shipping_number}}',
                shipment_in_transit: 'Your order is out for delivery',
                shipment_in_transit_delayed: '',
                shipment_delivered: 'Enjoy!',
                // waiting_pick_up: "Carrier will pick in the next few hours",
                // shipment_created: "Carrier will pick in the next few hours",
                shipment_collected: '',
                shipment_out_for_delivery: 'Your order will arrive soon',
                shipment_with_incident: 'There was an incident during delivery',
                shipment_canceled: 'Contact {{store}} for more information',
                shipment_returned: 'After unsuccessful delivery attempts',
                // returned: 'The order will return to the warehouse',
                returning: 'Due to return request',
                shipment_to_be_self_collected: 'Find the post office by clicking on the shipping number',
                shipment_to_be_self_collected_carrier: 'Contact {{carrier}} to find the post office',
                complement_address: 'Add your address complement',
                replacement_order: "Follow up on the new order from  ",
                error: "The delivery address is incomplete",
                edit: "Updated address",
                processing_delayed: "The order might take a little longer than usual to be processed",
                unpaid: "The pending payment is required to process the order",
                returned: "",
                return_arrived: "",
                shipped_pickup: "Check with the courier for the tracking of your order",
                pending: "It will be packed soon",
                canceled: "In case you need clarification, write directly to {{store}}",
                interrupted: "In case you need clarification, write directly to {{store}}",
                entirely_dropshipping: "Your order will be sent by another method. If you need clarification, write directly to {{store}}"
            },
            shipment_with_incident_descriptions: {
                default: '',
                missing_references: 'Due to missing references to be able to deliver',
                not_at_home: 'Due to buyer not at home',
                refused_payment: 'Buyer refused to pay',
                refused_package: 'Buyer refused the package',
                stolen: 'It will not be possible to deliver within the estimated time because the package was stolen',
                lost: 'It will not be possible to deliver within the estimated time because the package was lost',
                damaged: 'It will not be possible to deliver within the estimated time because the package was damaged',
                //returned_by_carrier: 'After unsuccessful delivery attempts, order will return to warehouse',
                //returned_by_client: 'Order will return to warehouse due to buyer return request',
                resolve_incident: 'Please contact {{store}} to resolve this incident',
                second_try: '',
                third_try: ''
            },
            shipping_incident : {
                description: {
                    CANCELED: "Shipment cancelled",
                    DELAYED: "{{carrier}} help was requested to speed up your delivery",
                    DAMAGED: 'Starting investigation of damaged products. Please contact {{store}} to look for a solution',
                    MISSING_PRODUCT: 'Starting investigation for missing products. Please contact {{store}} to look for a solution',
                    FALSE_DELIVERY: 'Starting investigation for incorrect delivery. Please contact {{store}} to look for a solution',
                    MISSING_REFERENCES: 'Address complement shared with {{carrier}}',
                    RESCHEDULE: 'You have rescheduled your delivery',
                    STOLEN: '{{carrier}} informs that the order has been stolen',
                    LOST: '{{carrier}} informs that the order has been lost',
                    RETURNED: '{{carrier}} returned the order to the warehouse',
                    DELIVERED: '{{carrier}} delivered the order',
                    NON_REFUNDABLE_DAMAGED: '{{carrier}} informs that the order has been damaged',
                    REFUNDABLE: 'It will not be possible to deliver this order. Please contact {{store}}.',
                    CHANGE_ADDRESS: 'Updated address shared with {{carrier}}'
                },
            }
        }
    },
    proofOfDelivery: {
        title: "Proof of delivery",
        received_by: "Received by",
        unavailable: "Proof of delivery is not available",
        proof: "Proof",
    },
    orderDetails: {
        shipment: "Shipment",
        shipping_number: "Tracking number",
        customer_data: "Delivery Address",
        order_products: "Order products",
        previous_address: "Previous address",
        updated_address: "Updated address",
        updated_reference: "Updated reference",
        pieces : "{{quantity}} piece{{plural}}",
        shipping : {
            address: "Address: ",
            state: "State: ",
            city: "City: ",
            number: "Number: ",
            neighborhood: "Neighborhood: ",
            zip_code: "Postal Code: ",
        }
    },
    findOrder : {
        find_my_order: "Track my order",
        help_with_order: "Do you need help with your order?",
        form: {
            shipping_number: "Shipping number",
            email: "Buyer's email",
            errors : {
                blank: "Please enter a valid tracking number and email.",
                INVALID_EMAIL: "The email does not match the tracking number.",
                ERR_NETWORK: "Unable to connect to server.",
                INVALID_SHIPPING_NUMBER: 'Shipping number not found.',
            }
        }
    },
    helpOrder : {
        created_by: 'Reported by {{user}}',
        store_partner: 'logistic partner',
        you: 'you',
        specialized_attention: 'It seems like your order has encountered an issue',
        specialized_attention_description_1: 'Please write to us at ',
        specialized_attention_description_2: ' to receive specialized assistance from one of our agents',
        shipping_incident : {
            status_label: 'Status',
            status : {
                PENDING: "Waiting review from {{carrier}}",
                IN_REVIEW: "In review by {{carrier}}",
                RESOLVED: "Resolved by {{carrier}}",
            },
            category : {
                CANCELED: "Shipment canceled",
                DELAYED: "Shipping delay",
                DAMAGED: 'Order damaged',
                MISSING_PRODUCT: 'Missing products',
                FALSE_DELIVERY: 'Incorrect delivery',
                MISSING_REFERENCES: 'Complete address',
                RESCHEDULE: 'Reschedule delivery date',
                MORE_HELP: 'Return and cancellation policies',
                CHANGE_ADDRESS: 'Change delivery address'
            },
            resolution: {
                STOLEN: '{{carrier}} reports that the order was order stolen',
                LOST: '{{carrier}} reports that the order was lost',
                RETURNED: '{{carrier}} has returned the order to the warehouse',
                DELIVERED: '{{carrier}} delivered the order',
                NON_REFUNDABLE_DAMAGED: '{{carrier}} reports that the order was damaged',
                REFUNDABLE: 'It will not be possible to deliver this order. Contact {{store}} will solve this problem.'
            },
            options: {
                CHANGE_ADDRESS: 'I want to change the delivery address',
                CHANGE_ADDRESS_CUBBO: 'Change the delivery address',
                DELAYED: "My order is delayed",
                DELAYED_CUBBO: "My order is delayed",
                DAMAGED: 'The products arrived damaged',
                DAMAGED_CUBBO: 'The products arrived damaged',
                MISSING_PRODUCT: 'Missing products in my order',
                MISSING_PRODUCT_CUBBO: 'Missing products in my order',
                FALSE_DELIVERY: 'I have not received my order and it appears as delivered',
                FALSE_DELIVERY_CUBBO: 'I have not received my order and it appears as delivered',
                WITHOUT_MOVEMENT: 'My order has no movement',
                WITHOUT_MOVEMENT_CUBBO: 'My order has no movement',
                MISSING_REFERENCES: 'Add references for delivery',
                MISSING_REFERENCES_CUBBO: 'Add references for delivery',
                EXPIRED: 'The product arrived expired or does not work',
                EXPIRED_CUBBO: 'The product arrived expired or does not work',
                WRONG_PRODUCT: 'I received an incorrect order, none of the received products were ordered',
                WRONG_PRODUCT_CUBBO: 'I received an incorrect order, none of the received products were ordered',
                EXTRA_PRODUCT: 'I received additional products that I did not order',
                EXTRA_PRODUCT_CUBBO: 'I received additional products that I did not order',
                RESCHEDULE: 'Reschedule delivery date',
                RESCHEDULE_CUBBO: 'Reschedule delivery date',
                MORE_HELP: 'Return and cancellation policies',
                MORE_HELP_CUBBO: 'Return and cancellation policies',
                RATE_CARRIER: 'Leave us your suggestions',
                RATE_CARRIER_CUBBO: 'Leave us your suggestions'
            },
            errors : {
                default: "This incident has already been reported to {{carrier}}. If after 6 working days it still has not been resolved, please contact {{store}}.",
                MISSING_REFERENCES: 'You have already complemented your address previously.',
                CHANGE_ADDRESS: 'You have already changed your address previously.',
                RESCHEDULE: 'You have already rescheduled the delivery previously.',
                NO_ETA: 'It is not possible to reschedule your order as it does not yet have an estimated delivery date',
                FIRST_DELIVERY_ATTEMPT: 'There has already been a delivery attempt, so it is not possible to reschedule your order'
            }
        },
        need_help : 'Do you need help with anything else?',
        need_help_description: 'To request an exchange, refund or cancellation of the order, consult the policies on the {{store}} website',
        need_help_description_email: 'If you still need help, please contact '
    },
    reportIncident : {
        create: "Send",
        form: {
            labels: {
                description: 'Additional comments',
                package_images: 'Photos of the 6 sides of the package',
                label_images: 'Readable photo of the delivery label',
                damaged_product_images: 'Photo of each damaged product',
                damage_zone_images: 'Detailed photo of damaged or violated zone',
                sorry_for_incovenience: 'Sorry for the inconvenience.',
                dont_worry: "Don't worry, there are still {{days}} days left for your order to be delivered.",
                dont_worry_time: "Don't worry, there's still time for your order to be delivered on time.",
                tracking_website: 'If you want to know more details about the shipment, check the tracking page of {{carrier}} ',
                tracking_cubbo: "Don't worry, Cubbo will deliver your order within 48 hours.",
                EXTRA_PRODUCT: 'Please contact {{store}} to return the products sent by mistake.',
                thanks_for_reporting: 'Thanks for reporting!',
                here: 'here',
                MISSING_REFERENCES : {
                    intro: 'Keep in mind that the current data cannot be changed as the order is already in transit. If you require a change of address, please contact ',
                    intro_cubbo: 'To facilitate delivery, share details like apartment number, description of the facade, phone number of the recipient, etc...',
                    current_address: 'Current delivery address',
                    additional_address: 'Additional delivery details',
                    additional_address_cubbo: 'Delivery references',
                    change_address: 'Is your address incorrect? Correct it '
                },
                CHANGE_ADDRESS: {
                    order_in_movement: "Your order is already on the move, so it won't be possible to change the delivery address.",
                    order_in_movement_cubbo: 'Your order is already in transit, so some data cannot be modified.',
                    complement_address: 'You can add your address complement without modifying current data ',
                    contact_email: 'If the address provided is incorrect, please contact ',
                    update_address: 'Update address',
                    state: "State",
                    city: "City",
                    zipcode: "Zip Code",
                    address: "Street and number",
                    references: "Neighborhood"
                },
                EXPIRED: {
                    contact: 'Please contact ',
                    solve_problem: ' to find a solution for this problem.'
                },
                WRONG_PRODUCT: {
                    reason: "There was probably a packaging error and you received someone else's order.",
                    contact: 'Please contact ',
                    solve_problem: ' to find a solution for this problem, so you can receive your order as soon as possible.'
                },
                RESCHEDULE: {
                    your_order: 'Your order will be delivered on ',
                    timezone_range: 'at an estimated time from 8:30 AM to 9:00 PM',
                    reschedule: 'If you reschedule the delivery, your new delivery date will be: ',
                    button: 'Reschedule for ',
                    scheduled_delivery: 'Estimated delivery',
                    reschedule_delivery: 'Reschedule delivery date',
                    delivery_here: 'Will be delivered here: ',
                    incorrect_address: 'Incorrect address',
                    desire: 'Do you want to',
                    confirm: 'confirm',
                    order_reschedule: 'rescheduling your order for ',
                    confirm_request: 'Confirm',
                    cancel_request: 'Cancel'
                },
                check_policies : 'Consult the policies on the {{store}} website'
            },
            errors : {
                UNRESOLVED_INCIDENT: 'This order already has an unresolved incident.',
                ERROR_ORDER_ALREADY_RETURNED: 'This order already has already been returned.',
                ERROR_ORDER_NOT_SHIPPED: "This order hasn't been shipped.",
                ERROR_INVALID_CATEGORY_FOR_ORDER_STATUS: "It is not possible to create this type of incident due to the status of the order.",
                ERROR_MAX_IMAGES: 'First and third field are limited to 6 images, second field is limited to 2 images. Each image must not exceed 5MB.',
                ERROR_ZIP_CODE_DIGITS: 'Invalid ZIP code, it must be 5 digits',
                ERROR_ZIP_CODE_COVERAGE: 'The shipping company handling your order does not cover this new ZIP code. Enter another ZIP code or return to the previous menu to discard the change'
            },
            add_image: "Add image",
        }
    },
    incidentCreated : {
        go_home: 'Return to home page',
        send_email: 'Send email',
        intro: 'We have shared your request of',
        sucess: 'with the shipping company.',
        description: 'Carrier should respond to this request within 72 hours'
    },
    formatted_relative_time: {
        today: "Today",
        yesterday: "Yesterday",
        tomorrow: "Tomorrow",
        date: "{{month}} {{day}}",
        range: "From {{initial_month}} {{initial}} - {{final_month}} {{final}}",
        from_now: "{{days}} days ago"
    },
    fileInputField: {
        not_pdf: "File is not PDF",
        max_file: "File is too big. Max",
        mb: "MB",
        min_file: "File is too small. Min ",
        max_images: "Maximum of",
        max_images_text: "images"
    },

    imageInputField: {
        not_an_image: "File is not image .PNG/JPG/JPEG",
        max_image: "Image is too big. Max",
        mb: "MB",
        min_image: "Image is too small. Max",
        max_images: "Maximum of",
        max_images_text: "images"
    },
    awaiting_order: {
        title: "Thank you for your purchase!",
        description_start: "In a few minutes, you will be able to monitor the movement of your ",
        description_order: "order #{{order_number}} ",
        description_end: "from this page"
    },
    edit_address: {
        title_description: "Change the delivery address",
        submit_address_button: "Submit changes",
        no_coverage: "There is no coverage for the entered postal code",
        submit_editing_order: "Updating the delivery address...",
        errors: {
            ERROR_EXACT_LENGTH: {
                shipping_zip_code: "The postal code must be [EXACT_QUANTITY] digits",
            },
            ERROR_MIN_LENGTH: {
                shipping_zip_code_international: "The postal code is required",
                shipping_zip_code: "The postal code must be at least [MIN_QUANTITY] digits",
                shipping_state: "The state is required",
                shipping_city: "The city is required",
                shipping_address_1: "The address is required",
                shipping_address_2: "The address is required",
                shipping_neighborhood: "The neighborhood is required",
                shipping_number: "The number is required"
            },
            ERROR_MAX_LENGTH: {
                shipping_zip_code: "The postal code must be at most [MAX_QUANTITY] digits",
                shipping_state: "The state must be at most [MAX_QUANTITY] characters",
                shipping_city: "The city must be at most [MAX_QUANTITY] characters",
                shipping_address_1: "The address must be at most [MAX_QUANTITY] characters",
                shipping_address_2: "The address must be at most [MAX_QUANTITY] characters",
                shipping_neighborhood: "The neighborhood must be at most [MAX_QUANTITY] characters",
                shipping_number: "The number must be at most [MAX_QUANTITY] characters"
            },
            ERROR_INVALID_CONTENT: {
                shipping_zip_code: "Enter a valid postal code",
                shipping_state: "Enter a valid state",
                shipping_city: "Enter a valid city",
                shipping_address_1: "Enter a valid address",
                shipping_address_2: "Enter a valid address",
                shipping_neighborhood: "Enter a valid neighborhood",
                shipping_number: "Enter a valid number"
            }
        },
        edit_authorization: {
            title: "Security question",
            description: "To change the delivery address of this order, provide the following information",
            fields: {
                email: "Email",
                tax_id: "CPF"
            },
            errors: {
                ERROR_MIN_LENGTH: {
                    email: "The email is required",
                    tax_id: "The CPF is required"
                },
                ERROR_INVALID_CONTENT: {
                    email: "Enter a valid email",
                    tax_id: "Incorrect CPF format"
                }
            },
            submit_button: "Continue",
            validating_submit: "Validating the information...",
            edit_unauthorized: "Incorrect information, please verify the provided information"
        },
        fields: {
            shipping_address_1: "Street and number",
            shipping_address_2: "Colony",
            shipping_state: "State",
            shipping_city: "City",
            shipping_zip_code: "Postal Code",
            shipping_neighborhood: "Neighborhood",
            shipping_number: "Number"
        }
    }
}