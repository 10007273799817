export const EmailReceivedIcon = ({ className = null }) => {
    return (
        <svg 
            viewBox="0 0 105 64" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            stroke="currentColor">
        <path d="M101.44 12.352V51.328C101.44 53.5431 100.538 55.6675 98.931 57.2338C97.3246 58.8001 95.1457 59.68 92.8739 59.68H35.7662C33.4943 59.68 31.3154 58.8001 29.709 57.2338C28.1025 55.6675 27.2 53.5431 27.2 51.328V12.352M101.44 12.352C101.44 10.1369 100.538 8.01255 98.931 6.44624C97.3246 4.87994 95.1457 4 92.8739 4H35.7662C33.4943 4 31.3154 4.87994 29.709 6.44624C28.1025 8.01255 27.2 10.1369 27.2 12.352M101.44 12.352V13.254C101.44 14.68 101.066 16.0822 100.353 17.3272C99.6397 18.5721 98.6116 19.6184 97.3663 20.3662L68.8125 37.4971C67.4617 38.3083 65.9064 38.7378 64.32 38.7378C62.7336 38.7378 61.1783 38.3083 59.8275 37.4971L31.2737 20.3699C30.0284 19.6221 29.0003 18.5759 28.2872 17.3309C27.5741 16.0859 27.1999 14.6837 27.2 13.2577V12.352" fill="white"/>
        <path d="M101.44 12.352V51.328C101.44 53.5431 100.538 55.6675 98.931 57.2338C97.3246 58.8001 95.1457 59.68 92.8739 59.68H35.7662C33.4943 59.68 31.3154 58.8001 29.709 57.2338C28.1025 55.6675 27.2 53.5431 27.2 51.328V12.352M101.44 12.352C101.44 10.1369 100.538 8.01255 98.931 6.44624C97.3246 4.87994 95.1457 4 92.8739 4H35.7662C33.4943 4 31.3154 4.87994 29.709 6.44624C28.1025 8.01255 27.2 10.1369 27.2 12.352M101.44 12.352V13.254C101.44 14.68 101.066 16.0822 100.353 17.3272C99.6397 18.5721 98.6116 19.6184 97.3663 20.3662L68.8125 37.4971C67.4617 38.3083 65.9064 38.7378 64.32 38.7378C62.7336 38.7378 61.1783 38.3083 59.8275 37.4971L31.2737 20.3699C30.0284 19.6221 29.0003 18.5759 28.2872 17.3309C27.5741 16.0859 27.1999 14.6837 27.2 13.2577V12.352" strokeWidth="6.96" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.6 59.6797L4.00001 45.7597ZM4.00001 45.7597L15.6 31.8397ZM4.00001 45.7597L45.76 45.7597Z" fill="white"/>
        <path d="M15.6 59.6797L4.00001 45.7597M4.00001 45.7597L15.6 31.8397M4.00001 45.7597L45.76 45.7597" strokeWidth="6.96" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}