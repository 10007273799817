import { useTranslation } from "react-i18next"
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useQuery } from "react-query"
import { fetchOrder } from "../services/orderServices"
import { Loader } from "../components/Loader"
import { PATH_HOME } from "../navigation/RouterPaths"
import { LogoHeader } from "../components/LogoHeader"
import { ProofOfDelivery } from "../components/ProofOfDelivery"
import { ChevronLeftIcon } from "../components/Icons/ChevronLeftIcon"
import { NavBar } from "../components/NavBar"

export const ProofOfDeliveryContainer = () => {
    const { i18n } = useTranslation()
    const { order_id } = useParams()
    const [ searchParams ] = useSearchParams()
    const navigate = useNavigate()
    const location = useLocation()

    const {
        isLoading,
        isError,
        error,  
        data,
        isPreviousData
    } = useQuery(['order', order_id], () => fetchOrder(order_id), { 
        retry: 1
    })

    return (
        <>
        {!isPreviousData && isLoading ? (
            <><Loader className="bg-gray-50" show={true}></Loader></>
        ) : isError ? (
            <Navigate to={PATH_HOME} state={{ error: error.response?.data?.code}} replace={true} />
        ) : (
        <>  
            <div className="bg-gray-50 py-5 px-10 sm:max-w-[480px]">
                <LogoHeader src={data?.order.store.logo_file?.path && process.env.REACT_APP_PLATFORM_URL + data?.order.store.logo_file?.path}/>
            </div>
            <div className="py-4 bg-gray-50">
                <NavBar title={i18n.t('proofOfDelivery.title')} className='mt-5'
                onClick={() => navigate(-1)}/>
            </div>
            <div className="flex flex-col space-y-5 py-4 px-8 bg-white mx-4 rounded-lg">
                <ProofOfDelivery order={data?.order} proofs={data?.order.proof_of_delivery} />
            </div>
        </>)}
        </>
    )
}