import axiosInstance from "../helpers/axiosInstance"

export const fetchOrderByShippingNumber = async (shipping_number) => {
    const res = await axiosInstance().get(`${process.env.REACT_APP_PLATFORM_URL}/tracking-api/orders/shipping/${shipping_number}`, {})
    return res.data
}

export const fetchOrder = async (order_id) => {
    const res = await axiosInstance().get(`${process.env.REACT_APP_PLATFORM_URL}/tracking-api/orders/${order_id}`, {})
    return res.data
}

export const createIncident = async (data) => {
    const res = await axiosInstance().post(`${process.env.REACT_APP_PLATFORM_URL}/tracking-api/shipping-incidents/create`, data)
    return res.data
}

export const editOrderAddress = async (order_id, data) => {
    const res = await axiosInstance().patch(`${process.env.REACT_APP_PLATFORM_URL}/tracking-api/orders/update-order-address-from-tracking/${order_id}`, data)
    return res.data
}

export const editOrderETA = async (order_id, data) => {
    const res = await axiosInstance().patch(`${process.env.REACT_APP_PLATFORM_URL}/tracking-api/orders/update-order-eta-from-tracking/${order_id}`, data)
    return res.data
}

export const validateCoverage = async (order_id, shipping_method_id, value) => {
    const res = await axiosInstance().get(`${process.env.REACT_APP_PLATFORM_URL}/tracking-api/orders/validate-coverage/${order_id}/shipping-method/${shipping_method_id}/value/${value}`)
    return res.data
}

export const redirectToOrderTracking = async (channelId, params) => {
    const res = await axiosInstance().get(`${process.env.REACT_APP_PLATFORM_URL}/tracking-api/channel/${channelId}`, {params: params})
    return res.data
}

export const editOrderSecurityValidation = async(order_id, data) => {
    const res = await axiosInstance().post(`${process.env.REACT_APP_PLATFORM_URL}/tracking-api/orders/edit-order/${order_id}/security-validation`, data)
    return res.data
}

export const editOrder = async(order_id, data) => {
    const res = await axiosInstance().patch(`${process.env.REACT_APP_PLATFORM_URL}/tracking-api/orders/${order_id}`, data)
    return res.data
}
