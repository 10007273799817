import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from "moment-timezone";
import "moment/locale/es";
import "moment/locale/pt";
import { capitalizeFirstLetter } from '../utils/StringUtils';

export const FormattedRelativeTime = ({ date, etaOffset = null, time_zone = '', forceDays = false, className = '' }) => {
  const { i18n } = useTranslation()
  const [relativeTime, setRelativeTime] = useState('') 
  const intervalDuration = 60*1000
  
  const updateValues = () => {
    moment.locale(i18n.language)
    
    let now = time_zone ? moment().tz(time_zone) : moment()
    const relativeDate = time_zone ? moment(date).tz(time_zone) : moment(date)
    
    const isPast = moment(relativeDate).isBefore(now, 'day')

    if(forceDays){
      const day = relativeDate.date()
      const weekday = capitalizeFirstLetter(relativeDate.format('dddd'))
      const month = capitalizeFirstLetter(relativeDate.format('MMMM'))
      const daysElapsed = Math.abs(now.startOf('day').diff(relativeDate.startOf('day'), 'days', false))

      let relativeTime = ''
      if (etaOffset) {
        const dateEtaOffset = relativeDate.add(etaOffset, 'hours')
        const dayEtaOffset = dateEtaOffset.date()
        const monthEtaOffset= capitalizeFirstLetter(dateEtaOffset.format('MMMM'))
        if (month == monthEtaOffset) {
          relativeTime = i18n.t('formatted_relative_time.range', {initial: day, initial_month: "", final: dayEtaOffset, final_month: monthEtaOffset})
        }
        else {
          relativeTime = i18n.t('formatted_relative_time.range', {initial: day, initial_month: month, final: dayEtaOffset, final_month: monthEtaOffset})
        }
        setRelativeTime(relativeTime)
      }
      else {
        if(daysElapsed == 1 && isPast) relativeTime = i18n.t('formatted_relative_time.yesterday')
        else if(daysElapsed == 0)  relativeTime = i18n.t('formatted_relative_time.today')
        else if(daysElapsed == 1 && !isPast) relativeTime = i18n.t('formatted_relative_time.tomorrow')
        else if (daysElapsed > 1 && isPast) relativeTime = i18n.t('formatted_relative_time.from_now', {days: daysElapsed})
        else relativeTime = weekday
        relativeTime +=  ', ' + i18n.t('formatted_relative_time.date', {day: day, month: month})
        setRelativeTime(relativeTime)
      }
      return false
    }else{
      setRelativeTime(moment(date).fromNow())
      return Math.abs(now.diff(relativeDate, 'minutes')) < 60
    }
  }

  useEffect(() => {
    let shouldUpdateNext = updateValues()
    let intervalId
    if(shouldUpdateNext){
      intervalId = setInterval(() => {  
        shouldUpdateNext = updateValues()
        if(!shouldUpdateNext) clearInterval(intervalId)
      }, intervalDuration) //Update every minute if less than a minute
    }
    return () => clearInterval(intervalId);
  }, [date, i18n.language]);

  return ( 
    date && <span className={className}>{relativeTime}</span>
  )
}
