export const OrderLineCard = ({
    thumbnail_path,
    img_path,
    title,
    description
}) => {
    return (
        <div className="flex flex-row space-x-5 items-center">
            <div className={`h-10 w-10 bg-gray-200 rounded-md overflow-hidden shrink-0 ${img_path && 'cursor-pointer'}`} 
                onClick={() => {
                    if (img_path) {
                        window.open(process.env.REACT_APP_PLATFORM_URL+img_path, "_blank")
                    }
                }}>
                {thumbnail_path &&
                <img src={process.env.REACT_APP_PLATFORM_URL+thumbnail_path} alt=''
                    className="h-full w-full object-contain"/>}
            </div>
            <div className="flex flex-col">
                <div className="text-sm text-gray-800 leading-tight">{title}</div>
                <div className="text-sm">{description}</div>
            </div>
        </div>
    )
}