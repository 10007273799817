import { useNavigate } from "react-router-dom"
import cubboLogo from "../assets/cubbo_logo.png"

export const LogoHeader = ({
    src
}) => {
    const navigate = useNavigate()
    return (
        <img className="image-logo cursor-pointer" src={src || cubboLogo} alt="" onClick={() => navigate("/")}/>
    )
}