import { useTranslation } from "react-i18next"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { SelectOptions } from "../components/SelectOptions"
import { CHANGE_ADDRESS, DAMAGED, DELAYED, EXPIRED, FALSE_DELIVERY, INCIDENT_RESOLVED, MISSING_PRODUCT, MISSING_REFERENCES, MORE_HELP, RATE_CARRIER, RESCHEDULE, WITHOUT_MOVEMENT, WRONG_PRODUCT } from "../constants/shippingIncidents"
import { STATUS_RETURNED, STATUS_RETURNING, STATUS_SHIPPED, STATUS_SHIPMENT_DELIVERED, STATUS_SHIPMENT_RETURNED } from "../constants/orderStatuses"
import { NavBar } from "../components/NavBar"
import { useEffect, useState } from "react"
import { LogoHeader } from "../components/LogoHeader"
import { PATH_HOME } from "../navigation/RouterPaths"
import { CARRIER_NAME_CORREIOS, CARRIER_NAME_CUBBO } from "../constants/shippingMethods"
import { useQuery } from "react-query"
import { fetchOrder } from "../services/orderServices"
import { Loader } from "../components/Loader"
import { sendAnalytics } from "../utils/analyticsUtils"

export const HelpOrderContainer = () => {
    const { i18n } = useTranslation()
    const { order_id } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const [errors, setErrors] = useState()
    const [data, setData] = useState(location?.state?.data);


    const { isLoading, isError, isPreviousData, isFetching, data: dataFromQuery } = useQuery(
        [`order${order_id}`, order_id],
        () => fetchOrder(order_id),
        {
            retry: 1,
            enabled: !data
        }
    )

    useEffect(() => {
        if (dataFromQuery) {
            setData(dataFromQuery)
        }
    }, [dataFromQuery])

    if(!data) return <Loader className="bg-gray-50" show={true}></Loader>

    let fieldsData = null

    const shippingIncidentsDisabled = data?.order.shipping_incidents.reduce((acc, incident) =>
        incident?.status !== INCIDENT_RESOLVED ?
            { ...acc, [incident?.category]: true } : acc, {})

    const isFalseDeliveryOlderThan5Days = data?.order.shipping_incidents?.filter(shipping_incident => shipping_incident.category === FALSE_DELIVERY && (new Date() - new Date(shipping_incident.created_at)) >= 6 * 24 * 60 * 60 * 1000)

    //const showChangeAddressHelp = data?.order?.shipping_labels?.length > 0 || !data?.order?.is_editable ? true : false
    const showChangeAddressHelp = false
    const showAddMissingReferencesHelp = false

    if (data?.order?.shipping_labels[0]?.shipping_method === CARRIER_NAME_CUBBO && data?.order.shipping?.country?.toUpperCase() !== "BR") {
        if ([STATUS_SHIPPED, STATUS_RETURNING].includes(data?.order?.virtual_status) && [STATUS_SHIPMENT_DELIVERED, STATUS_SHIPMENT_RETURNED].includes(data?.order?.shipping_status)) {
            fieldsData = [
                {
                    key: DAMAGED,
                    label: i18n.t(`helpOrder.shipping_incident.options.${DAMAGED}`),
                    disabled: shippingIncidentsDisabled[DAMAGED],
                },
                {
                    key: MISSING_PRODUCT,
                    label: i18n.t(`helpOrder.shipping_incident.options.${MISSING_PRODUCT}`),
                    disabled: shippingIncidentsDisabled[MISSING_PRODUCT],

                },
                {
                    key: EXPIRED,
                    label: i18n.t(`helpOrder.shipping_incident.options.${EXPIRED}`),
                },
                {
                    key: WRONG_PRODUCT,
                    label: i18n.t(`helpOrder.shipping_incident.options.${WRONG_PRODUCT}`),
                }
            ]
            if (data?.order?.shipping_status === STATUS_SHIPMENT_DELIVERED && [STATUS_SHIPPED].includes(data?.order?.virtual_status)) {
                fieldsData.unshift({
                    key: FALSE_DELIVERY,
                    label: i18n.t(`helpOrder.shipping_incident.options.${FALSE_DELIVERY}`),
                    disabled: shippingIncidentsDisabled[FALSE_DELIVERY],
                })
            }
        } else if (data?.order.is_delayed) {
            fieldsData = [
                /*
                {
                    key: RESCHEDULE,
                    label: i18n.t(`helpOrder.shipping_incident.options.${RESCHEDULE}`),
                    disabled: shippingIncidentsDisabled[RESCHEDULE] || data?.order?.first_delivery_attempt_at !== null || data?.order?.estimated_time_arrival === null,
                },*/
                {
                    key: RATE_CARRIER,
                    label: i18n.t(`helpOrder.shipping_incident.options.${RATE_CARRIER}`),
                    disabled: shippingIncidentsDisabled[RATE_CARRIER],
                },
                {
                    key: MORE_HELP,
                    label: i18n.t(`helpOrder.shipping_incident.options.${MORE_HELP}`),
                    disabled: shippingIncidentsDisabled[MORE_HELP],
                }
            ]

            if(showChangeAddressHelp){
                fieldsData.push({
                    key: CHANGE_ADDRESS,
                    label: i18n.t(`helpOrder.shipping_incident.options.${CHANGE_ADDRESS}_CUBBO`),
                    disabled: shippingIncidentsDisabled[CHANGE_ADDRESS] || data?.order?.first_delivery_attempt_at,
                })
            }

            if(showAddMissingReferencesHelp){
                fieldsData.push({
                    key: MISSING_REFERENCES,
                    label: i18n.t(`helpOrder.shipping_incident.options.${MISSING_REFERENCES}_CUBBO`),
                    disabled: shippingIncidentsDisabled[MISSING_REFERENCES],
                })
            }

            if (![STATUS_RETURNED].includes(data?.order?.virtual_status)) {
                fieldsData.unshift({
                    key: DELAYED,
                    label: i18n.t(`helpOrder.shipping_incident.options.${DELAYED}`),
                    disabled: shippingIncidentsDisabled[DELAYED],
                })
            }
        }else{
            fieldsData = [
                {
                    key: RATE_CARRIER,
                    label: i18n.t(`helpOrder.shipping_incident.options.${RATE_CARRIER}`),
                    disabled: shippingIncidentsDisabled[RATE_CARRIER],
                },
                {
                    key: MORE_HELP,
                    label: i18n.t(`helpOrder.shipping_incident.options.${MORE_HELP}`),
                    disabled: shippingIncidentsDisabled[MORE_HELP],
                }
            ]

            if(data?.order?.shipping_coverage?.eta_margin_of_error_in_hours === 0 || data?.order?.shipping_coverage?.eta_margin_of_error_in_hours === null){
                fieldsData.unshift({
                    key: RESCHEDULE,
                    label: i18n.t(`helpOrder.shipping_incident.options.${RESCHEDULE}`),
                    disabled: shippingIncidentsDisabled[RESCHEDULE] || data?.order?.first_delivery_attempt_at !== null || data?.order?.estimated_time_arrival === null,
                })
            }

            if(showAddMissingReferencesHelp){
                fieldsData.push({
                    key: MISSING_REFERENCES,
                    label: i18n.t(`helpOrder.shipping_incident.options.${MISSING_REFERENCES}_CUBBO`),
                    disabled: shippingIncidentsDisabled[MISSING_REFERENCES],
                })
            }

            if(showChangeAddressHelp){
                fieldsData.push({
                    key: CHANGE_ADDRESS,
                    label: i18n.t(`helpOrder.shipping_incident.options.${CHANGE_ADDRESS}_CUBBO`),
                    disabled: shippingIncidentsDisabled[CHANGE_ADDRESS] || data?.order?.first_delivery_attempt_at,
                })
            }

            if (![STATUS_RETURNED].includes(data?.order?.virtual_status)) {
                fieldsData.unshift({
                    key: WITHOUT_MOVEMENT,
                    label: i18n.t(`helpOrder.shipping_incident.options.${WITHOUT_MOVEMENT}`),
                    disabled: shippingIncidentsDisabled[WITHOUT_MOVEMENT],
                })
            }
        }
    }
    else {

        if ([STATUS_SHIPPED, STATUS_RETURNING].includes(data?.order?.virtual_status) && [STATUS_SHIPMENT_DELIVERED, STATUS_SHIPMENT_RETURNED].includes(data?.order?.shipping_status)) {
            fieldsData = [
                {
                    key: DAMAGED,
                    label: i18n.t(`helpOrder.shipping_incident.options.${DAMAGED}`),
                    disabled: shippingIncidentsDisabled[DAMAGED],
                },
                {
                    key: MISSING_PRODUCT,
                    label: i18n.t(`helpOrder.shipping_incident.options.${MISSING_PRODUCT}`),
                    disabled: shippingIncidentsDisabled[MISSING_PRODUCT],

                },
                {
                    key: EXPIRED,
                    label: i18n.t(`helpOrder.shipping_incident.options.${EXPIRED}`),
                },
                {
                    key: WRONG_PRODUCT,
                    label: i18n.t(`helpOrder.shipping_incident.options.${WRONG_PRODUCT}`),
                },
                {
                    key: MORE_HELP,
                    label: i18n.t(`helpOrder.shipping_incident.options.${MORE_HELP}`),
                    disabled: shippingIncidentsDisabled[MORE_HELP],
                }
                // {
                //     key: EXTRA_PRODUCT,
                //     label: i18n.t(`helpOrder.shipping_incident.options.${EXTRA_PRODUCT}`)
                // },
            ]
            if (data?.order?.shipping_status === STATUS_SHIPMENT_DELIVERED && [STATUS_SHIPPED].includes(data?.order?.virtual_status)) {
                fieldsData.unshift({
                    key: FALSE_DELIVERY,
                    label: i18n.t(`helpOrder.shipping_incident.options.${FALSE_DELIVERY}`),
                    disabled: shippingIncidentsDisabled[FALSE_DELIVERY],
                })
            }
        }
        else if (data?.order?.is_delayed) {
            fieldsData = [{
                key: MORE_HELP,
                label: i18n.t(`helpOrder.shipping_incident.options.${MORE_HELP}`),
                disabled: shippingIncidentsDisabled[MORE_HELP],
            }]

            if(showChangeAddressHelp){
                fieldsData.push({
                    key: CHANGE_ADDRESS,
                    label: i18n.t(`helpOrder.shipping_incident.options.${CHANGE_ADDRESS}`),
                    disabled: shippingIncidentsDisabled[CHANGE_ADDRESS],
                })
            }

            if (data?.order?.shipping_labels[0]?.shipping_method === CARRIER_NAME_CORREIOS) {
                fieldsData.unshift({
                    key: MISSING_REFERENCES,
                    label: i18n.t(`helpOrder.shipping_incident.options.${MISSING_REFERENCES}`),
                    disabled: shippingIncidentsDisabled[MISSING_REFERENCES],
                })
            }

            if (![STATUS_RETURNED].includes(data?.order?.virtual_status)) {
                fieldsData.unshift({
                    key: DELAYED,
                    label: i18n.t(`helpOrder.shipping_incident.options.${DELAYED}`),
                    disabled: shippingIncidentsDisabled[DELAYED],
                })
            }
        }
        else {
            fieldsData = [{
                key: MORE_HELP,
                label: i18n.t(`helpOrder.shipping_incident.options.${MORE_HELP}`),
                disabled: shippingIncidentsDisabled[MORE_HELP],
            }]

            if(showChangeAddressHelp){
                fieldsData.push({
                    key: CHANGE_ADDRESS,
                    label: i18n.t(`helpOrder.shipping_incident.options.${CHANGE_ADDRESS}`),
                    disabled: shippingIncidentsDisabled[CHANGE_ADDRESS],
                })
            }

            if (data?.order?.shipping_labels[0]?.shipping_method === CARRIER_NAME_CORREIOS) {
                fieldsData.unshift({
                    key: MISSING_REFERENCES,
                    label: i18n.t(`helpOrder.shipping_incident.options.${MISSING_REFERENCES}`),
                    disabled: shippingIncidentsDisabled[MISSING_REFERENCES],
                })
            }

            if (![STATUS_RETURNED].includes(data?.order?.virtual_status)) {
                fieldsData.unshift({
                    key: WITHOUT_MOVEMENT,
                    label: i18n.t(`helpOrder.shipping_incident.options.${WITHOUT_MOVEMENT}`),
                    disabled: shippingIncidentsDisabled[WITHOUT_MOVEMENT],
                })
            }
        }
    }

    const buildRescheduleIncidentErrorDescription = (category) => {
        if (!data?.order?.estimated_time_arrival){
            return i18n.t(`helpOrder.shipping_incident.errors.NO_ETA`, { store: data?.order?.store.name, carrier: data?.order?.shipping_labels[0]?.shipping_method })
        }else if(data?.order?.first_delivery_attempt_at){
            return i18n.t(`helpOrder.shipping_incident.errors.FIRST_DELIVERY_ATTEMPT`, { store: data?.order?.store.name, carrier: data?.order?.shipping_labels[0]?.shipping_method }) 
        }else{
            return i18n.t(`helpOrder.shipping_incident.errors.${category}`, { store: data?.order?.store.name, carrier: data?.order?.shipping_labels[0]?.shipping_method })
        }
    }

    const buildChangeAddressIncidentErrorDescription = (category) => {
        if(data?.order?.first_delivery_attempt_at){
            return i18n.t(`helpOrder.shipping_incident.errors.FIRST_DELIVERY_ATTEMPT`, { store: data?.order?.store.name, carrier: data?.order?.shipping_labels[0]?.shipping_method })
        }else{
            return i18n.t(`helpOrder.shipping_incident.errors.${category}`, { store: data?.order?.store.name, carrier: data?.order?.shipping_labels[0]?.shipping_method })
        }
    }

    const handleOnSelect = (category, disabled) => {
        if (category === RATE_CARRIER) {
            sendAnalytics('Tracking - Rate carrier', data)

            window.open('https://docs.google.com/forms/d/e/1FAIpQLSevqmnuwVeBxaHj2P_LNJWts9krg4VrLL_VIzXep6DfE4KqhA/viewform?embedded=true', '_blank');
        }        
        else if (!disabled) {
            navigate(`/orders/${order_id}/report-incident`, { state: { data: data, category: category } })
        }
        else {
            setErrors(values => ({
                ...values,
                [category]: category === MISSING_REFERENCES ?
                    i18n.t(`helpOrder.shipping_incident.errors.${category}`, { store: data?.order?.store.name, carrier: data?.order?.shipping_labels[0]?.shipping_method }) :
                category === CHANGE_ADDRESS ?
                    (buildChangeAddressIncidentErrorDescription(category)) :
                category === RESCHEDULE ? 
                    (buildRescheduleIncidentErrorDescription(category))
                    :
                i18n.t(`helpOrder.shipping_incident.errors.default`, { store: data?.order?.store.name, carrier: data?.order?.shipping_labels[0]?.shipping_method })
            }))
        }
    }

    return (
        <>
        {(!isPreviousData && isLoading) || (isFetching) || (!data)? (
            <><Loader className="bg-gray-50" show={true}></Loader></>
        ) : isError ? (
            <Navigate to={PATH_HOME} replace={true} />
        ) : (

        
        <>
            {data === undefined ? (
                <Navigate to={PATH_HOME} replace />
            ) : (
                (data?.order?.shipping_labels[0]?.shipping_method === CARRIER_NAME_CUBBO && data?.order.shipping?.country?.toUpperCase() !== "BR") ? (
                    <>
                        <div className="bg-gray-50 py-5 px-10 sm:max-w-[480px]">
                            <LogoHeader src={data?.order.store.logo_file?.path && process.env.REACT_APP_PLATFORM_URL + data?.order.store.logo_file?.path} />
                        </div>
                        <div className="flex flex-col bg-gray-50">
                            <NavBar title={i18n.t('showOrder.help_with_order')} className='mt-5' onClick={() => {
                                    const result = navigate(-1)
                                    if (!result) {
                                        navigate(`/orders/${order_id}`)
                                    }
                                }}
                            />
                            <div className="flex flex-col h-full justify-center py-5 px-10">
                                <SelectOptions fieldsData={fieldsData} onSelect={handleOnSelect} errors={errors} />
                            </div>

                            {isFalseDeliveryOlderThan5Days?.length > 0 && (
                            <div className="px-10 py-5">
                                <div className="font-semibold mb-2">{i18n.t('helpOrder.specialized_attention')}</div>
                                <p className="text-sm mb-3">
                                {i18n.t('helpOrder.specialized_attention_description_1', { store: data?.order?.store.name })} 
                                <a href="mailto:hola@cubbo.com" className="text-blue-500">hola@cubbo.com</a> 
                                {i18n.t('helpOrder.specialized_attention_description_2', { store: data?.order?.store.name })}
                                </p>

                            </div>
                            )}



                        </div>
                    </>
                ) : (
                    <>
                        <div className="bg-gray-50 py-5 px-10 sm:max-w-[480px]">
                            <LogoHeader src={data?.order?.store.logo_file?.path && process.env.REACT_APP_PLATFORM_URL + data?.orde?.store.logo_file?.path} />
                        </div>
                        <div className="flex flex-col bg-gray-50">
                            <NavBar title={i18n.t('showOrder.help_with_order')} className='mt-5' onClick={() => {
                                    const result = navigate(-1)
                                    if (!result) {
                                        navigate(`/orders/${order_id}`)
                                    }
                                }}
                            />
                            <div className="flex flex-col h-full justify-center py-5 px-10">
                                <SelectOptions fieldsData={fieldsData} onSelect={handleOnSelect} errors={errors} />
                            </div>
                        </div>
                    </>
                )
            )}
        </>
        )}

        </>
    )
}