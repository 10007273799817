import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"

import { LogoHeader } from "../components/LogoHeader"
import { NavBar } from "../components/NavBar"
import { OrderSection } from "../components/OrderSection"
import { EmailSentIcon } from "../components/Icons/EmailSentIcon"
import { joinWords } from "../utils/StringUtils"
import { FormattedDate } from "../components/FormattedDate"

export const EditedAddressDetailsContainer = () => {
    const { i18n } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const orderHistoryMetadata = location?.state?.orderHistoryMetadata
    const orderHistoryReferenceTimestamp = location?.state?.orderHistoryReferenceTimestamp
    const orderData = location?.state?.orderData

    const buildAddressData = (shippingData) => {
        const storeWarehouse = orderData?.order.store.warehouses[0]
        const shippingCountry = orderData?.order.shipping.country

        return [
            {
                key: "customer",
                description: (
                    <div>
                        <p className="text-gray-600">
                            <span className="text-gray-800 font-medium">
                                {i18n.t("orderDetails.shipping.address")}
                            </span>
                            {joinWords(shippingData?.shipping_address_1, shippingData?.shipping_address_2)}
                        </p>
                        <p className="text-gray-600">
                            <span className="text-gray-800 font-medium">
                                {i18n.t("orderDetails.shipping.state")}
                            </span>
                            {(shippingData?.shipping_state || "")}
                        </p>
                        <p className="text-gray-600">
                            <span className="text-gray-800 font-medium">
                                {i18n.t("orderDetails.shipping.city")}
                            </span>
                            {(shippingData?.shipping_city || "")}
                        </p>
                        <p className="text-gray-600">
                            <span className="text-gray-800 font-medium">
                                {i18n.t("orderDetails.shipping.zip_code")}
                            </span>
                            {(shippingData?.shipping_zip_code || "")}
                        </p>
                        {storeWarehouse?.country === "BR" && shippingCountry === "BR" && (
                            <>
                                <p className="text-gray-600">
                                    <span className="text-gray-800 font-medium">
                                        {i18n.t("orderDetails.shipping.neighborhood")}
                                    </span>
                                    {(shippingData?.shipping_neighborhood || "")}
                                </p>
                                <p className="text-gray-600">
                                    <span className="text-gray-800 font-medium">
                                        {i18n.t("orderDetails.shipping.number")}
                                    </span>
                                    {(shippingData?.shipping_number || "")}
                                </p>
                            </>
                        )}
                    </div>
                )
            }
        ]
    }

    return (
        <div>
            <div className="bg-gray-50 py-5 px-10 sm:max-w-[480px]">
                <LogoHeader src={orderData?.order.store.logo_file?.path && process.env.REACT_APP_PLATFORM_URL + orderData?.order.store.logo_file?.path}/>
            </div>

            <div className="flex flex-col space-y-5 bg-gray-50">
                <NavBar
                    title={(
                        <div>
                            <span>{i18n.t('showOrder.address_update')}</span>
                            <span className="font-normal">{" - "}</span>
                            <span className="font-normal">
                                <FormattedDate date={orderHistoryReferenceTimestamp} shortDate={true}/>
                            </span>
                        </div>
                    )}
                    className='mt-5'
                    onClick={() => navigate(-1)}
                />
                <div className="flex flex-col space-y-10 mx-5 px-5 py-5 bg-white rounded-xl ">
                    <OrderSection
                        customIcon={<EmailSentIcon className="flex-shrink-0 h-5 w-5 text-gray-300 mr-2 font-black" aria-hidden="true" />}
                        title={i18n.t('orderDetails.updated_address')}
                        fieldsData={buildAddressData(orderHistoryMetadata?.updated_order_data)}
                    />
                    <OrderSection
                        icon={'customer_data'}
                        title={i18n.t('orderDetails.previous_address')}
                        fieldsData={buildAddressData(orderHistoryMetadata?.previous_order_data)}
                    />
                </div>
            </div>
        </div>
    )
}
