export const TextField = ({
    label,
    name,
    onChange = () => {},
    onKeyDown = () => {}
}) => {

    const onChangeHandler = (event) => {
        if (onChange!==null) onChange(event.target.value)
    }

    return (
        <>
            <label className="rounded mb-2 ml-1">{label}</label>
            <input className="rounded p-2 shadow border-gray-300 border rounded-lg" name={name} onChange={onChangeHandler} onKeyDown={onKeyDown}/>
        </>
    )
}