export const pt = {
    showOrder : {
        title: "Olá!",
        order_number : "Pedido #{{order_number}} de {{store}}",
        estimated_delivery: "Entrega estimada:",
        rescheduled_delivery: "Reagendaste a entrega para o:",
        estimated_delivery_undefined: "Será mostrado em breve",
        eta_not_available: "Não disponível",
        extended_zone: "O pedido possui um maior prazo de entrega já que o destino pertence a uma zona remota",
        delivered: "Foi entregue!",
        canceled: "Não será entregue",
        view_order_details: "Ver informação do pedido",
        shipping_method_label : "Transportadora",
        shipping_status_label: "Estado do envio",
        view_tracking_cubbo: "Transportadora: Cubbo SD",
        view_tracking: "Acompanhar o envio em {{carrier_name}}",
        shipping_incident_label : "Eventos del pedido",
        help_with_order: "Preciso de ajuda com meu pedido",
        view_proof_of_delivery: "Ver comprovante de entrega",
        address_references: "Complemento de endereço",
        address_update: "Atualização do endereço de entrega",
        watch: "Ver alterações",
        replacement_order_link: "aqui",
        edit_address: "Mudar endereço",
        check_address: "Verifique se o endereço está completo e correto",
        order_to_be_picked: "Este pedido será entregue em um ponto físico para coleta",
        pick_up_location_name: "Nome da propriedade",
        pick_up_location_address: "Endereço",
        last_delivery_attempt_description: "Após tentativas de entrega falhadas, o pacote retornará ao armazém.",
        last_delivery_attempt_help: "Se precisar de alguma clarificação, escreva para {{store}}",
        timeline: {
            shipping_status: {
                initial: "Compra realizada",
                processing: "Preparando o teu pedido",
                shipped: 'Empacotado, aguardando coleta',
                shipment_in_transit: "Em trânsito",
                shipment_delivered: "Entregue",
                // waiting_pick_up: "Empacotado, aguardando coleta",
                // shipment_created: "Empacotado, aguardando coleta",
                shipment_collected: "Coletado pela transportadora",
                shipment_with_incident: 'Incidente na entrega',
                shipment_canceled: 'Entrega Cancelada',
                shipment_returned: 'Retornando ao armazém',
                // returned: 'Entrega Cancelada',
                // returned_by_client: 'Devolução solicitada',
                returning: 'Retornando ao armazém',
                shipment_to_be_self_collected: 'Retire no ponto de coleta',
                shipment_estimated_arrival: 'Entrega estimada',
                shipment_out_for_delivery: 'Saiu para entrega',
                delivery_attempt_failed: "Tentativa de entrega sem sucesso",
                replacement_order: "Pedido reenviado",
                error: "Falta informação",
                processing_delayed: "Preparando seu pedido",
                unpaid: "Pagamento pendente",
                returned: "Retornou ao armazém",
                return_arrived: "Retornou ao armazém",
                shipped_pickup: "Enviado com transportadora externa",
                pending: "Pagamento recebido",
                canceled: "Pedido cancelado",
                interrupted: "Pedido cancelado",
                entirely_dropshipping: "Atendido Externamente"
            },
            shipping_description: {
                initial: "Nº do pedido: {{order_number}}",
                shipped: "Nº de rastreio: {{shipping_number}}",
                shipment_in_transit: 'Seu pedido está a caminho',
                shipment_in_transit_delayed: '',
                shipment_delivered: 'Aproveite sua compra!',
                shipment_collected: '',
                shipment_out_for_delivery: 'Seu pedido está chegando',
                shipment_with_incident: 'Ocorreu um incidente durante a entrega',
                shipment_canceled: 'Entre em contato com {{store}} para mais informações',
                shipment_returned: 'Após tentativas de entrega sem sucesso',
                // returned: 'O pedido retornará ao armazém',
                returning: 'Por devolução solicitada',
                shipment_to_be_self_collected: 'Encontre o ponto de coleta clicando no número de rastreio',
                shipment_to_be_self_collected_carrier: 'Entre em contato com {{carrier}} para localizar o ponto de coleta',
                complement_address: 'Complemente seu endereço',
                replacement_order: "Acompanhe o novo pedido ",
                error: "O endereço de entrega está incompleto",
                edit: "Endereço atualizado",
                processing_delayed: "É possível que o pedido demore um pouco mais do que o habitual para ser processado",
                unpaid: "É necessário o pagamento pendente para processar o pedido",
                returned: "",
                return_arrived: "",
                shipped_pickup: "Consulte com a transportadora o rastreamento do seu pedido",
                pending: "Será embalado em breve",
                canceled: "Em caso de precisar de esclarecimento, escreva diretamente para {{store}}",
                interrupted: "Em caso de precisar de esclarecimento, escreva diretamente para {{store}}",
                entirely_dropshipping: "Seu pedido será enviado por outro meio. Caso precise de esclarecimentos, escreva diretamente para {{store}}"
            },
            shipment_with_incident_descriptions: {
                default: '',
                missing_references: 'Por falta de referências para poder entregar',
                not_at_home: 'O comprador estava ausente',
                refused_payment: 'O comprador se recusou a pagar',
                refused_package: 'O comprador recusou o pacote',
                stolen: 'Não será possível entregar no prazo estimado porque o pedido foi roubado',
                lost: 'Não será possível entregar no prazo estimado porque o pedido foi extraviado',
                damaged: 'Não será possível entregar no prazo estimado porque o pedido foi avariado',
                //returned_by_carrier: 'Após tentativas de entrega sem sucesso, o pedido retornará ao armazém',
                //returned_by_client: 'O pedido retornará ao armazém por devolução solicitada',
                resolve_incident: 'Entre em contato com {{store}} para solucionar este incidente',
                second_try: '',
                third_try: ''
            },
            shipping_incident : {
                description: {
                    CANCELED: "Entrega cancelada",
                    DELAYED: "Solicitou-se ajuda de {{carrier}} para agilizar a entrega",
                    DAMAGED: 'Estamos investigando possível danificação do pedido. Entre em contato com {{store}} para encontrar uma solução.',
                    MISSING_PRODUCT: 'Estamos investigando possível falta de produtos no seu pedido. Entre em contato com {{store}} para encontrar uma solução.',
                    FALSE_DELIVERY: 'Estamos investigando possível entrega incorreta. Entre em contato com {{store}} para encontrar uma solução.',
                    MISSING_REFERENCES: 'Complemento de endereço compartilhado com {{carrier}}',
                    RESCHEDULE: 'Você reprogramou sua entrega',
                    STOLEN: '{{carrier}} reporta que o pedido foi roubado',
                    LOST: '{{carrier}} reporta que o pedido foi extraviado',
                    RETURNED: '{{carrier}} retornou o pedido ao armazém',
                    DELIVERED: '{{carrier}} entregou o pedido',
                    NON_REFUNDABLE_DAMAGED: '{{carrier}} reporta que o pedido foi avariado',
                    REFUNDABLE: 'Não será possível entregar este pedido. Entre em contato com {{store}}.',
                    CHANGE_ADDRESS: 'Endereço atualizado compartilhado com {{carrier}}'
                },
            }
        }
    },
    proofOfDelivery: {
        title: "Comprovante de entrega",
        received_by: "Recebido por",
        unavailable: "El comprovante de entrega não está disponível",
        proof: "Comprovante",
    },
    orderDetails: {
        shipment: "Envio",
        shipping_number: "Número de rastreio",
        customer_data: "Endereço de entrega",
        order_products: "Produtos do pedido",
        previous_address: "Endereço anterior",
        updated_address: "Endereço atualizado",
        updated_reference: "Referência atualizada",
        pieces : "{{quantity}} peça{{plural}}",
        shipping : {
            address: "Endereço: ",
            state: "Estado: ",
            city: "Cidade: ",
            number: "Número: ",
            neighborhood: "Bairro: ",
            zip_code: "CEP: ",
        }
    },
    findOrder : {
        find_my_order: "Rastrear meu pedido",
        help_with_order: "Precisa de ajuda com seu pedido?",
        form: {
            shipping_number: "Número de rastreio",
            email: "Email do comprador",
            errors : {
                blank: "Insira um número de rastreio e um email válido.",
                INVALID_EMAIL: "O email não corresponde ao número de rastreio.",
                ERR_NETWORK: "Não foi possível conectar ao servidor.",
                INVALID_SHIPPING_NUMBER: 'Número de rastreio não encontrado.'
            }
        }
    },
    helpOrder : {
        created_by: 'Reportado por {{user}}',
        you: 'você',
        store_partner: 'parceiro logístico',
        specialized_attention: 'Parece que o seu pedido encontrou um problema',
        specialized_attention_description_1: 'Por favor, escreva para nós em ',
        specialized_attention_description_2: ' para receber assistência especializada de um de nossos agentes',
        shipping_incident: {
            status_label: 'Estado',
            status : {
                PENDING: "Aguardando revisão de {{carrier}}",
                IN_REVIEW: "Sob revisão de {{carrier}}",
                RESOLVED: "Resolvido por {{carrier}}",
            },
            category : {
                CANCELED: "Entrega cancelada",
                DELAYED: "Entrega atrasada",
                DAMAGED: 'Pedido danificado',
                MISSING_PRODUCT: 'Faltaram produtos',
                FALSE_DELIVERY: 'Entrega incorreta',
                MISSING_REFERENCES: 'Completar endereço',
                RESCHEDULE: 'Reagendar data de entrega',
                MORE_HELP: 'Políticas de devolução e cancelamento',
                CHANGE_ADDRESS: 'Alterar endereço de entrega'
            },
            resolution: {
                STOLEN: '{{carrier}} reporta que o pedido foi roubado',
                LOST: '{{carrier}} reporta que o pedido foi extraviado',
                RETURNED: '{{carrier}} retornou o pedido ao armazém',
                DELIVERED: '{{carrier}} entregou o pedido',
                NON_REFUNDABLE_DAMAGED: '{{carrier}} reporta que o pedido foi avariado',
                REFUNDABLE: 'Não será possível entregar este pedido. Entre em contato com {{store}}.'
            },
            options : {
                CHANGE_ADDRESS: 'Quero mudar o endereço de entrega',
                CHANGE_ADDRESS_CUBBO: 'Mudar o endereço de entrega',
                DELAYED: "Meu pedido está atrasado",
                DELAYED_CUBBO: "Meu pedido está atrasado",
                DAMAGED: 'Os produtos chegaram danificados',
                DAMAGED_CUBBO: 'Os produtos chegaram danificados',
                MISSING_PRODUCT: 'Produtos em falta no meu pedido',
                MISSING_PRODUCT_CUBBO: 'Produtos em falta no meu pedido',
                FALSE_DELIVERY: 'Não recebi meu pedido e aparece como entregue',
                FALSE_DELIVERY_CUBBO: 'Não recebi meu pedido e aparece como entregue',
                WITHOUT_MOVEMENT: 'Meu pedido não tem movimento',
                WITHOUT_MOVEMENT_CUBBO: 'Meu pedido não tem movimento',
                MISSING_REFERENCES: 'Adicionar referências para a entrega',
                MISSING_REFERENCES_CUBBO: 'Adicionar referências para a entrega',
                EXPIRED: 'O produto chegou vencido ou não funciona',
                EXPIRED_CUBBO: 'O produto chegou vencido ou não funciona',
                WRONG_PRODUCT: 'Recebi um pedido incorreto, nenhum dos produtos recebidos foi solicitado',
                WRONG_PRODUCT_CUBBO: 'Recebi um pedido incorreto, nenhum dos produtos recebidos foi solicitado',
                EXTRA_PRODUCT: 'Recebi produtos adicionais que não solicitei',
                EXTRA_PRODUCT_CUBBO: 'Recebi produtos adicionais que não solicitei',
                RESCHEDULE: 'Reagendar data de entrega',
                RESCHEDULE_CUBBO: 'Reagendar data de entrega',
                MORE_HELP: 'Políticas de devolução e cancelamento',
                MORE_HELP_CUBBO: 'Políticas de devolução e cancelamento',
                RATE_CARRIER: 'Deixe-nos as suas sugestões',
                RATE_CARRIER_CUBBO: 'Deixe-nos as suas sugestões'
            },
            errors : {
                default: "Este incidente já foi relatado a transportadora. Se depois de 6 dias úteis ainda não tiver sido resolvido, entre em contato com a {{store}}.",
                MISSING_REFERENCES: 'Você já complementou seu endereço anteriormente.',
                CHANGE_ADDRESS: 'Você já alterou seu endereço anteriormente.',
                RESCHEDULE: 'Você já reagendou a entrega anteriormente.',
                NO_ETA: 'Não é possível reprogramar seu pedido pois ainda não possui data de entrega estimada',
                FIRST_DELIVERY_ATTEMPT: 'Já houve uma tentativa de entrega, portanto não é possível reprogramar seu pedido'
            }
        },
        need_help : 'Precisa de ajuda com algo mais?',
        need_help_description: 'Para solicitar troca, devolução ou cancelamento do pedido, consulte as políticas no site da {{store}}',
        need_help_description_email: 'Caso ainda precise de ajuda, entre em contato com ',
    },
    reportIncident : {
        create: "Enviar",
        form: {
            labels: {
                description: 'Comentarios adicionais',
                package_images: 'Fotos dos 6 lados do pacote',
                label_images: 'Foto legível da nota fiscal',
                damaged_product_images: 'Foto de cada produto danificado',
                damaged_zone_images: 'Foto detalhada da zona danificada ou violada',
                sorry_for_incovenience: 'Desculpe-nos pelo transtorno.',
                dont_worry: 'Não se preocupe, ainda faltam {{days}} dias para que o pedido seja entregue no prazo previsto.',
                dont_worry_time: 'Não se preocupe, o seu pedido está no prazo previsto para ser entregue.',
                tracking_website: 'Caso queira saber mais detalhes sobre o envio, consulte a página de rastreio da {{carrier}} ',
                EXTRA_PRODUCT: 'Por favor, entre em contato com a {{store}} para organizar o retorno dos produtos enviados por engano.',
                thanks_for_reporting: 'Obrigado por colaborar!',
                here: 'aqui',
                MISSING_REFERENCES : {
                    intro: 'Considere que não é possível alterar o endereço de entrega porque seu pedido já está em movimento. Caso precise alterar o endereço atual, entre em contato com ',
                    intro_cubbo: 'Para facilitar a entrega, compartilhe detalhes como número do apartamento, descrição da fachada, telefone do destinatário, etc...',
                    current_address: 'Endereço de entrega atual',
                    additional_address: 'Dados adicionais de entrega',
                    additional_address_cubbo: 'Referências para a entrega',
                    change_address: 'Seu endereço está incorreto? Corrija-o '
                },
                CHANGE_ADDRESS: {
                    order_in_movement: 'Seu pedido já está em movimento, portanto não será possível alterar o endereço de entrega.',
                    order_in_movement_cubbo: 'Seu pedido já está em trânsito, então alguns dados não podem ser modificados.',
                    complement_address: 'Você pode complementar seu endereço sem modificar os dados atuais ',
                    contact_email: 'Se o endereço fornecido estiver incorreto, entre em contato com ',
                    update_address: 'Atualizar endereço',
                    state: "Estado",
                    city: "Cidade",
                    zipcode: "CEP",
                    address: "Rua e número",
                    references: "Bairro"
                },
                EXPIRED: {
                    contact: 'Entre em contato com ',
                    solve_problem: ' para encontrar uma solução a este problema.'
                },
                WRONG_PRODUCT : {
                    reason: 'Provavelmente ocorreu um erro no empacotamento e você recebeu o pedido de outra pessoa.',
                    contact: 'Entre em contato com ',
                    solve_problem: ' para que você possa receber seu pedido o mais rápido possível.'
                },
                RESCHEDULE: {
                    your_order: 'Seu pedido será entregue em ',
                    timezone_range: 'em um horário estimado das 8h30 às 21h',
                    reschedule: 'Se você remarcar a entrega, sua nova data de entrega será: ',
                    button: 'Remarcar para o ',
                    scheduled_delivery: 'Entrega estimada',
                    reschedule_delivery: 'Reagendar data de entrega',
                    delivery_here: 'Será entregue aqui: ',
                    incorrect_address: 'Endereço incorreto ',
                    desire: 'Deseja',
                    confirm: 'confirmar',
                    order_reschedule: 'o reagendamento do seu pedido para o ',
                    confirm_request: 'Confirmar',
                    cancel_request: 'Cancelar'
                },
                check_policies: 'Consulte as políticas de trocas e devoluções no site da {{store}}'

            },
            errors : {
                UNRESOLVED_INCIDENT: 'Este pedido já tem um incidente não resolvido.',
                ERROR_ORDER_ALREADY_RETURNED: 'Este pedido já foi retornado.',
                ERROR_ORDER_NOT_SHIPPED: "Este pedido ainda não foi enviado.",
                ERROR_INVALID_CATEGORY_FOR_ORDER_STATUS: "Não é possível criar este tipo de incidente devido ao estado deste pedido.",
                ERROR_MAX_IMAGES: 'O primeiro e o terceiro campo estão limitados a 6 imagens, o segundo campo está limitado a 2 imagens. Cada imagem não pode exceder 5MB.',
                ERROR_ZIP_CODE_DIGITS: 'Código postal inválido, deve conter 5 dígitos',
                ERROR_ZIP_CODE_COVERAGE: 'A transportadora responsável pelo seu pedido não cobre este novo CEP. Insira outro CEP ou volte ao menu anterior para descartar a alteração'
            },
            add_image: "Adicionar imagem"
        }
    },
    incidentCreated : {
        go_home: 'Voltar a página inicial',
        send_email: 'Enviar email',
        intro: 'Compartilhamos sua solicitação de',
        sucess: 'com a transportadora.',
        description: 'A transportadora deve atender a esta solicitação em até 72 horas'
    },
    formatted_relative_time: {
        today: "Hoje",
        yesterday: "Ontem",
        tomorrow: "Amanhã",
        date: "{{day}} de {{month}}",
        range: "Entre {{initial}} {{initial_month}} - {{final}} {{final_month}}",
        from_now: "Há {{days}} dias"
    },
    fileInputField: {
        not_pdf: "O arquivo não é um PDF",
        max_file: "O arquivo é muito grande. Máximo",
        mb: "MB",
        min_file: "O arquivo é muito pequeno. Mínimo ",
        max_images: "Só é possível adicionar até",
        max_images_text: "imagens"
    },

    imageInputField: {
        not_an_image: "O arquivo não é uma imagem .PNG/JPG/JPEG",
        max_image: "A imagem é muito grande. Máximo",
        mb: "MB",
        min_image: "A imagem é muito pequena. Mínimo",
        max_images: "Só é possível adicionar até",
        max_images_text: "imagens"
    },
    awaiting_order: {
        title: "Obrigado pela sua compra!",
        description_start: "Em alguns minutos você poderá monitorar o movimento do seu ",
        description_order: "pedido #{{order_number}} ",
        description_end: "desta página"
    },
    edit_address: {
        title_description: "Alterar o endereço de entrega",
        submit_address_button: "Enviar alterações",
        no_coverage: "Não há cobertura para o código postal inserido",
        submit_editing_order: "Atualizando o endereço de entrega...",
        errors: {
            ERROR_EXACT_LENGTH: {
                shipping_zip_code: "O código postal deve ter [EXACT_QUANTITY] dígitos",
            },
            ERROR_MIN_LENGTH: {
                shipping_zip_code_international: "O código postal é obrigatório",
                shipping_zip_code: "O código postal deve ter pelo menos [MIN_QUANTITY] dígitos",
                shipping_state: "O estado é obrigatório",
                shipping_city: "A cidade é obrigatória",
                shipping_address_1: "O endereço é obrigatório",
                shipping_address_2: "O endereço é obrigatório",
                shipping_neighborhood: "O bairro é obrigatório",
                shipping_number: "O número é obrigatório"
            },
            ERROR_MAX_LENGTH: {
                shipping_zip_code: "O código postal deve ter no máximo [MAX_QUANTITY] dígitos",
                shipping_state: "O estado deve ter no máximo [MAX_QUANTITY] caracteres",
                shipping_city: "A cidade deve ter no máximo [MAX_QUANTITY] caracteres",
                shipping_address_1: "O endereço deve ter no máximo [MAX_QUANTITY] caracteres",
                shipping_address_2: "O endereço deve ter no máximo [MAX_QUANTITY] caracteres",
                shipping_neighborhood: "O bairro deve ter no máximo [MAX_QUANTITY] caracteres",
                shipping_number: "O número deve ter no máximo [MAX_QUANTITY] caracteres"
            },
            ERROR_INVALID_CONTENT: {
                shipping_zip_code: "Insira um código postal válido",
                shipping_state: "Insira um estado válido",
                shipping_city: "Insira uma cidade válida",
                shipping_address_1: "Insira um endereço válido",
                shipping_address_2: "Insira um endereço válido",
                shipping_neighborhood: "Insira um bairro válido",
                shipping_number: "Insira um número válido"
            }
        },
        edit_authorization: {
            title: "Pergunta de segurança",
            description: "Para alterar o endereço de entrega deste pedido, responda as seguintes informações",
            fields: {
                email: "E-mail",
                tax_id: "CPF"
            },
            errors: {
                ERROR_MIN_LENGTH: {
                    email: "O e-mail é obrigatório",
                    tax_id: "O CPF é obrigatório"
                },
                ERROR_INVALID_CONTENT: {
                    email: "Insira um e-mail válido",
                    tax_id: "Formato de CPF incorreto"
                }
            },
            submit_button: "Continuar",
            validating_submit: "Validando as informações...",
            edit_unauthorized: "Informações incorretas, verifique as informações fornecidas"
        },
        fields: {
            shipping_address_1: "Rua e número",
            shipping_address_2: "Bairro",
            shipping_state: "Estado",
            shipping_city: "Cidade",
            shipping_zip_code: "CEP",
            shipping_neighborhood: "Bairro",
            shipping_number: "Número"
        }
    }
}