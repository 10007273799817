import './App.css';
import './translations/index';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from "react-query"
import { FindOrderContainer } from './pages/FindOrderContainer';
import { ShowOrderContainer } from './pages/ShowOrderContainer';
import { Layout } from './components/Layout';
import { PATH_CONFIRMATION, PATH_HELP_ORDER, PATH_HOME, PATH_INCIDENT_CREATED, PATH_ORDER_DETAILS, PATH_REPORT_INCIDENT, PATH_SHOW_MISSING_REFERENCES_SHIPPING_DETAILS, PATH_SHOW_ORDER, PATH_SHOW_PROOF_OF_DELIVERY, PATH_SHOW_SHIPPING_DETAILS, PATH_AWAITING_ORDER, PATH_EDIT_ADDRESS, PATH_SHOW_EDITED_ADDRESS_DETAILS } from './navigation/RouterPaths';
import { HelpOrderContainer } from './pages/HelpOrderContainer';
import { ReportIncidentContainer } from './pages/ReportIncidentContainer';
import { IncidentCreatedContainer } from './pages/IncidentCreatedContainer';
import { OrderDetailsContainer } from './pages/OrderDetailsContainer';
import { ProofOfDeliveryContainer } from './pages/ProofOfDeliveryContainer';
import { ShippingDetailsContainer } from './pages/ShippingDetailsContainer';
import { MissingReferencesShippingDetailsContainer } from './pages/MissingReferencesShippingDetailsContainer';
import { ConfirmationContainer } from './pages/ConfirmationContainer';
import { AwaitingOrderContainer } from './pages/AwaitingOrderContainer';
import { EditAddressContainer } from './pages/EditAddressContainer';
import { EditedAddressDetailsContainer } from './pages/EditedAddressDetailsContainer';

const queryClient = new QueryClient()

function App() {
  const { i18n } = useTranslation()

  // update html tag lang attribute when translation changes
  useEffect(() => {
    document.documentElement.lang = i18n.language   
  }, [i18n.language])

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path={PATH_HOME} element={<FindOrderContainer/>}/>
            <Route path={PATH_SHOW_ORDER} element={<ShowOrderContainer/>}/>
            <Route path={PATH_ORDER_DETAILS} element={<OrderDetailsContainer/>}/>
            <Route path={PATH_HELP_ORDER} element={<HelpOrderContainer/>}/>
            <Route path={PATH_REPORT_INCIDENT} element={<ReportIncidentContainer/>}/>
            <Route path={PATH_INCIDENT_CREATED} element={<IncidentCreatedContainer/>}/>
            <Route path={PATH_SHOW_PROOF_OF_DELIVERY} element={<ProofOfDeliveryContainer/>}/>
            <Route path={PATH_SHOW_SHIPPING_DETAILS} element={<ShippingDetailsContainer/>}/>
            <Route path={PATH_SHOW_MISSING_REFERENCES_SHIPPING_DETAILS} element={<MissingReferencesShippingDetailsContainer/>}/>
            <Route path={PATH_CONFIRMATION} element={<ConfirmationContainer/>}/>
            <Route path={PATH_AWAITING_ORDER} element={<AwaitingOrderContainer />}/>
            <Route path={PATH_EDIT_ADDRESS} element={<EditAddressContainer />}/>
            <Route path={PATH_SHOW_EDITED_ADDRESS_DETAILS} element={<EditedAddressDetailsContainer />}/>
            {/* Redirect to home if don't match any route */}
            <Route path="*" element={<Navigate to={PATH_HOME} replace />}/>
          </Routes>
        </Layout>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
