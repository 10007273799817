import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { useQuery } from "react-query"
import { redirectToOrderTracking } from "../services/orderServices"
import { LogoHeader } from "../components/LogoHeader"
import { Loader } from "../components/Loader"

const DEFAULT_QUERY_STALE_TIME = 1000 * 60 * 1 // 1 minute

export const AwaitingOrderContainer = () => {
    const { i18n } = useTranslation()
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const channel = params.get("channel")
    const id = params.get("id")
    const name = params.get("name")
    const number = params.get("number")

    const {
        isLoading,
        data,
    } = useQuery(['track_order', channel, id, name, number], () => redirectToOrderTracking(channel, {id: id, name: name, order_number: number, tracking_app: true}), {
        refetchOnWindowFocus: true,
        staleTime: DEFAULT_QUERY_STALE_TIME
    })

    useEffect(() => {
        if(data?.tracking_link){
            window.location.replace(data.tracking_link)
        }
    }, [data])

    return (
        <div className="h-full flex flex-col">
            {!isLoading && (
                <div className="bg-gray-50 py-5 px-10 sm:max-w-[480px]">
                    <LogoHeader src={data?.logo_file?.path ? process.env.REACT_APP_PLATFORM_URL + data?.logo_file?.path : null} />
                </div>
            )}

            <div className="flex flex-col justify-center px-10 h-full bg-gray-50 gap-1">
                <div className="text-xl font-semibold">
                    {i18n.t('awaiting_order.title')}
                </div>

                <div className="text-sm mt-2">
                    {i18n.t('awaiting_order.description_start')}

                    <span className="font-semibold">
                        {i18n.t('awaiting_order.description_order', {order_number: data?.order_number})}
                    </span>

                    {i18n.t('awaiting_order.description_end')}
                </div>
            </div>

            <Loader className="bg-gray-50" show={isLoading} />
        </div>
    )
}
