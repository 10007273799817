import { useEffect, useState } from "react";

export const TextareaField = ({
    label,
    description,
    name,
    rows=4,
    maxLength=200,
    disabled=false,
    onChange = () => {},
    onKeyDown = () => {},
    className = "",
    initialValue = ""
}) => {

    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    const onChangeHandler = (event) => {
        setValue(event.target.value)
        if (onChange!==null) onChange(event.target.value)
    }

    return (
        <>
            <label className="rounded ml-1">{label}</label>
            {description &&  <label className="text-sm ml-1">{description}</label>}
            <textarea className={`rounded p-2 shadow border-gray-300 border rounded-lg mt-2 ${className ? className : ""}`} 
                name={name}
                rows={rows}
                maxLength={maxLength}
                onChange={onChangeHandler} 
                onKeyDown={onKeyDown}
                disabled={disabled}
                value={value}
                />
        </>
    )
}