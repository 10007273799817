export const SelectOptions = ({
    fieldsData,
    errors = {},
    onSelect = () => {}
}) => {
    return (
        <div className="flex flex-col space-y-4">
            {fieldsData.map(item => 
            <div className="flex flex-col w-ful" key={item.key}>
                <button className={`text-sm p-2 border border-gray-300 shadow-sm rounded-lg bg-white hover:bg-gray-50 focus:ring-offset-2 focus:ring-blue-600
                ${item.disabled ? 'text-gray-400' : 'text-gray-800'}`} 
                    key={item.key}
                    onClick={() => onSelect(item.key, item.disabled)}>
                    {item.label}
                </button>
                {errors[item.key] && <span className="ml-1 mt-1 text-red-500 text-sm">{errors[item.key]}</span>}
            </div>)}
        </div>
    )
}