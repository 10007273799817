import { ChevronLeftIcon } from "./ChevronLeftIcon"
import { LabelNumberIcon } from "./LabelNumberIcon"
import { OrderShippingStatusIcon } from "./OrderShippingStatusIcon"
import { CustomerDataIcon } from "./CustomerDataIcon"
import { ShippingIncidentIcon } from "./ShippingIncidentIcon"
import { ShippingMethodIcon } from "./ShippingMethodIcon"
import { ShoppingBagIcon } from "./ShoppingBagIcon"


const ICON_CODES = {
    customer_data: CustomerDataIcon,
    left_arrow: ChevronLeftIcon,
    order_shipping_status: OrderShippingStatusIcon,
    shipping_incident: ShippingIncidentIcon,
    shipping_label_number: LabelNumberIcon,
    shipping_method: ShippingMethodIcon,
    shopping_bag: ShoppingBagIcon
}

export const Icon = ({ iconCode, ...otherProps }) => {
    return ICON_CODES[iconCode] ? ICON_CODES[iconCode](otherProps) : <></>
}
