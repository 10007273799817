import { ImageUploadSection } from "./ImageUploadSection";
import { Button } from "./Button";
import { TextField } from "./TextField";
import { TextareaField } from "./TextareaField";

export const Form = ({
    fieldsData,
    submitData,
    onSubmit = () => {},
    handleInputUpdate = () => {},
    inputs,
    errors,
    disableSubmit
}) => {

    return (
        <div className="flex flex-col items-center pb-5">
            {fieldsData.map(item =>
                <div className="flex flex-col pt-5 w-full" key={item.key}>
                    {item.type === 'text' ? 
                    <TextField
                        label={item.label}
                        name={item.key}
                        onChange={input => handleInputUpdate(item.key, input)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") onSubmit()
                        }}
                    />
                    : item.type === 'textarea' ? 
                    <TextareaField
                        label={item.label}
                        description={item.description}
                        name={item.key}
                        onChange={input => handleInputUpdate(item.key, input)}
                        maxLength={item.maxLength !== null && item.maxLength !== undefined ? item.maxLength : 200}
                        className={`${item.resizeEnabled === false ? "resize-none" : ""}`}
                    />
                    : item.type === 'image' ?
                        <ImageUploadSection                    
                            label={item.label}
                            placeholder={item.placeholder}
                            images={inputs && inputs[item.key]}
                            onChange={input => handleInputUpdate(item.key, input)}
                            maxFiles={item.maxFiles}
                            required
                        />
                    : item.type === 'paragraph' ? 
                    <>
                        <label className="rounded ml-1">{item.label}</label>
                        {item.description &&  <label className="text-sm ml-1">{item.description}</label>}
                    </>:
                    item.type === 'inputfield' ? 
                    <div className="flex gap-7">
                        <label className="rounded ml-1 mt-4 w-1/3">{item.label}</label>
                        <div className="w-full">
                            <TextareaField
                                rows={1}
                                name={item.key}
                                onChange={input => handleInputUpdate(item.key, input)}
                                initialValue={item.initialValue}
                                disabled={item.disabledTextField}
                                className={`w-full min-h-fit ${item.disabledTextField ? 'text-gray-400' : ''} ${item.isInvalidData ? "border-red-500" : ""} ${item.resizeEnabled === false ? "resize-none" : ""}`}
                                maxLength={item.maxLength !== null && item.maxLength !== undefined ? item.maxLength : 200}
                            />
                            {item.isInvalidData && <p className="text-red-500">{item.invalidDataMessage}</p>}
                        </div>
                    </div>:
                    <></>}
                </div>
            )}
            {errors.serverError && <div className="self-start ml-1 my-2 text-red-500 text-sm">{errors.serverError}</div>}
            <Button
                label={submitData.label}
                disabled={disableSubmit}
                onClick={onSubmit}/>
        </div>
    )
}