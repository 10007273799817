import { useTranslation } from "react-i18next"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { Button } from "../components/Button"
import { LogoHeader } from "../components/LogoHeader"
import { PATH_HOME } from "../navigation/RouterPaths"
import { CARRIER_NAME_CUBBO } from "../constants/shippingMethods"

export const IncidentCreatedContainer = () => {
    const { i18n } = useTranslation()
    const navigate = useNavigate()
    const { order_id } = useParams();
    const location = useLocation();
    const category = location?.state?.category;
    const data = location?.state?.data;

    const handleGoHome = () => {
        navigate(`/orders/${order_id}`, {state: {data: data}})
    }

    return (
        <>
        {data === undefined ? (
            <Navigate to={PATH_HOME} replace />
        ) : (
        <>
        <div className="bg-gray-50 py-5 px-10 sm:max-w-[480px]">
            <LogoHeader src={data?.order.store.logo_file?.path && process.env.REACT_APP_PLATFORM_URL + data?.order.store.logo_file?.path}/>
        </div>
        <div className="flex flex-col items-center justify-center space-y-5 px-10 py-10 h-full bg-gray-50">
            <div className="self-start">
                <div>
                    {i18n.t('incidentCreated.intro')}
                </div>
                <div className="font-semibold">
                    {i18n.t(`helpOrder.shipping_incident.category.${category}`)}
                </div>
                <div>
                    {i18n.t('incidentCreated.sucess')}
                </div>
                {
                    data?.order.shipping_labels[0]?.shipping_method !== CARRIER_NAME_CUBBO &&
                    <div className="text-gray-600 text-sm mt-3">{i18n.t('incidentCreated.description')}</div>
                }
            </div>
            <Button
                label={i18n.t('incidentCreated.go_home')}
                onClick={handleGoHome}/>
        </div>
        </>)}
        </>
    )
}