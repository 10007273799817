import { matchRoutes, useLocation } from "react-router-dom"
import { LogoHeader } from "./LogoHeader";
import poweredByCubbo from "../assets/powered_by_cubbo.png"
import { PATH_HOME, PATH_AWAITING_ORDER } from "../navigation/RouterPaths";

export const Layout = ({
    children
}) => {
    const showHeaderPaths = [{path: PATH_HOME}]
    const showAwaitingOrderPaths = [{path: PATH_AWAITING_ORDER}]
    const currentLocation = useLocation();
    const showHeader = matchRoutes(showHeaderPaths, currentLocation);
    const isAwaitingOrder = matchRoutes(showAwaitingOrderPaths, currentLocation);

    return (
        <div className="flex justify-center absolute inset-0 sm:static sm:min-h-screen">
            <div className="flex flex-col justify-between relative w-screen sm:max-w-[480px]
                border rounded-md bg-gray-50">
                {showHeader &&
                <div className="bg-gray-50 py-5 px-10 sm:max-w-[480px]">
                    <LogoHeader/>
                </div>}
                <div className={`sm:max-w-[480px] ${isAwaitingOrder ? "h-full" : ""}`}>
                    {children}
                </div>
                <div className="bg-gray-50 p-5 sm:max-w-[480px]">
                    <a href='https://cubbo.com/'
                        rel='noreferrer'
                        target="_blank">
                        <img className="h-10 m-auto" src={poweredByCubbo} alt=""/>
                    </a>
                </div>
            </div>
        </div>
    )
}