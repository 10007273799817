import { useTranslation } from "react-i18next";
import { ClipIcon } from "./Icons/ClipIcon";
import { useMemo, useState } from "react";
import { FilePopover } from "./FilePopover";
import { use } from "i18next";
export const ProofOfDelivery = ({order, proofs = [], className = "", innerClassName = ""}) => {
    const { i18n} = useTranslation()
    const [showPopover, setShowPopover] = useState(false)
    const [currentPopoverIndex, setCurrentPopoverIndex] = useState(0)

    const togglePopover = (index) => {
        setShowPopover(!showPopover)
        setCurrentPopoverIndex(index)
    }

    const getFullUrl = (relativePath) => `${process.env.REACT_APP_PLATFORM_URL}${relativePath}`

    const getRecipient = () => {
        const { recipient_name, recipient_relationship } = proofs || {}
        if (!recipient_name) return null;
        const relationship = recipient_relationship ? `, ${recipient_relationship}.` : ''
        return (
            <div className="my-4">
            {`${i18n.t("proofOfDelivery.received_by")}`}: {`${recipient_name}${relationship}`}
            </div>
        )
    }

    const filesToRender = useMemo(() => {
        return (proofs || []).flatMap((proof) => (proof?.files || []).map((item) => ({
            ...item,
            path: getFullUrl(item.path),
            format: item.path.split('.').pop(),
            is_image: item.content_type.includes('image')
        }))).sort((a, b) => a.is_image ? -1 : 1)
    }, [proofs])

    const handleFileClick = (index) => {
        console.log("POD file clicked")
        togglePopover(index)
        /*window.analytics.track('Orders - Clicks Proof of Delivery', {
            id: order.id,
            pod_index: index
        })*/
    }
      
    return (
        <div className={className}>
            {!proofs.some(proof => proof?.files) && !proofs.some(proof => proof?.recipient_name)
            ? `${i18n.t("proofOfDelivery.unavailable")} `
            : (
                <div className={`text-base font-medium text-gray-500 ${innerClassName}`}>
                    {getRecipient()}
                    <div className="my-2 space-x-4">
                        {filesToRender && filesToRender.map((item, index) => (
                            item.is_image 
                            ?  <a key={index} onClick={() => handleFileClick(index)}>
                                <div 
                                    className="w-full aspect-square bg-center bg-no-repeat bg-cover rounded-lg"
                                    style={{ backgroundImage: `url(${item.path})` }}
                                    alt={i18n.t("proofOfDelivery.proof")}
                                />
                            </a>
                            : <a href={item.is_image ? '' : item.path} target="_blank" rel="noreferrer" key={index} >
                                <div className="w-full aspect-square flex flex-col text-gray-600 justify-center items-center rounded-lg bg-gray-100 text-sm">
                                    <ClipIcon className="w-6 h-6" />
                                    {i18n.t("proofOfDelivery.proof")}
                                </div>
                            </a>
                        ))}
                    </div>
                    {showPopover && (
                        <FilePopover
                            files={filesToRender.filter((item) => item.is_image)}
                            onClose={togglePopover}
                            startIndex={currentPopoverIndex}
                        />
                    )}
                </div>
            )
            }
        </div>
    )
}
