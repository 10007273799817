export const EmailSentIcon = ({ className = null }) => {
    return (
        <svg 
            viewBox="0 0 101 63" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            stroke="currentColor" >
            <path d="M78.44 12.0317V51.0077C78.44 53.2228 77.5375 55.3471 75.931 56.9134C74.3246 58.4797 72.1457 59.3597 69.8739 59.3597H12.7662C10.4943 59.3597 8.31545 58.4797 6.70898 56.9134C5.10251 55.3471 4.20001 53.2228 4.20001 51.0077V12.0317M78.44 12.0317C78.44 9.8166 77.5375 7.69224 75.931 6.12593C74.3246 4.55963 72.1457 3.67969 69.8739 3.67969H12.7662C10.4943 3.67969 8.31545 4.55963 6.70898 6.12593C5.10251 7.69224 4.20001 9.8166 4.20001 12.0317M78.44 12.0317V12.9337C78.4402 14.3597 78.0659 15.7619 77.3528 17.0069C76.6397 18.2518 75.6116 19.2981 74.3663 20.0459L45.8125 37.1768C44.4617 37.988 42.9064 38.4175 41.32 38.4175C39.7336 38.4175 38.1783 37.988 36.8275 37.1768L8.27369 20.0496C7.02845 19.3018 6.00031 18.2555 5.28722 17.0106C4.57414 15.7656 4.19985 14.3634 4.20001 12.9374V12.0317" fill="white"/>
            <path d="M78.44 12.0317V51.0077C78.44 53.2228 77.5375 55.3471 75.931 56.9134C74.3246 58.4797 72.1457 59.3597 69.8739 59.3597H12.7662C10.4943 59.3597 8.31545 58.4797 6.70898 56.9134C5.10251 55.3471 4.20001 53.2228 4.20001 51.0077V12.0317M78.44 12.0317C78.44 9.8166 77.5375 7.69224 75.931 6.12593C74.3246 4.55963 72.1457 3.67969 69.8739 3.67969H12.7662C10.4943 3.67969 8.31545 4.55963 6.70898 6.12593C5.10251 7.69224 4.20001 9.8166 4.20001 12.0317M78.44 12.0317V12.9337C78.4402 14.3597 78.0659 15.7619 77.3528 17.0069C76.6397 18.2518 75.6116 19.2981 74.3663 20.0459L45.8125 37.1768C44.4617 37.988 42.9064 38.4175 41.32 38.4175C39.7336 38.4175 38.1783 37.988 36.8275 37.1768L8.27369 20.0496C7.02845 19.3018 6.00031 18.2555 5.28722 17.0106C4.57414 15.7656 4.19985 14.3634 4.20001 12.9374V12.0317" stroke="#D1D5DB" strokeWidth="6.96" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M85.4 31.5195L97 45.4395ZM97 45.4395L85.4 59.3595ZM97 45.4395H55.24Z" fill="white"/>
            <path d="M85.4 31.5195L97 45.4395M97 45.4395L85.4 59.3595M97 45.4395H55.24" strokeWidth="6.96" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}