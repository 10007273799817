export const STATUS_INITIAL = 'initial'
export const STATUS_PROCESSING = 'processing'
export const STATUS_SHIPPED = 'shipped'
export const STATUS_CANCELED = 'canceled'
export const STATUS_INTERRUPTED = 'interrupted'
export const STATUS_RETURNED = 'returned'
export const STATUS_RETURN_ARRIVED = 'return_arrived'
export const STATUS_RETURNING = 'returning'
export const STATUS_WAITING_PICK_UP =  'waiting_pick_up'
export const STATUS_ERROR = 'error'
export const STATUS_HOLD = 'holded'
export const STATUS_BACKORDER = 'backorder'
export const STATUS_UNPAID = 'unpaid'
export const STATUS_PENDING = 'pending'
export const STATUS_ENTIRELY_DROPSHIPPING = "entirely_dropshipping"
export const STATUS_EMPTY = "empty"

export const STATUS_SHIPMENT_DELIVERED = 'shipment_delivered'
export const STATUS_SHIPMENT_RETURNED = 'shipment_returned'
export const STATUS_SHIPMENT_IN_TRANSIT = 'shipment_in_transit'
export const STATUS_SHIPMENT_COLLECTED = 'shipment_collected'
export const STATUS_SHIPMENT_OUT_FOR_DELIVERY = 'shipment_out_for_delivery'
export const STATUS_SHIPMENT_WITH_INCIDENT = 'shipment_with_incident'
export const STATUS_SHIPMENT_CANCELED = 'shipment_canceled'
export const STATUS_SHIPMENT_TO_BE_SELF_COLLECTED = 'shipment_to_be_self_collected'

export const TRACKING_DESCRIPTIONS = new Set([
    'missing_references',
    'not_at_home',
    'refused_payment',
    'refused_package',
    'stolen',
    'lost',
    'damaged',
    'second_try',
    'third_try'
])

export const WILL_NOT_DELIVER_STATUSES = [
    STATUS_CANCELED,
    STATUS_INTERRUPTED,
    STATUS_RETURNED,
    STATUS_RETURN_ARRIVED,
    STATUS_RETURNING
]

export const WILL_NOT_DELIVER_SHIPMENT_STATUSES = [
    STATUS_SHIPMENT_CANCELED,
    STATUS_SHIPMENT_RETURNED
]
