import { useTranslation } from "react-i18next"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { Form } from "../components/Form"
import { DAMAGED, DELAYED, FALSE_DELIVERY, MISSING_PRODUCT, MISSING_REFERENCES, EXPIRED, WRONG_PRODUCT, WITHOUT_MOVEMENT, CHANGE_ADDRESS, RESCHEDULE, MORE_HELP } from "../constants/shippingIncidents"
import { createIncident, editOrderAddress, validateCoverage } from "../services/orderServices"
import { useEffect, useState } from "react"
import { Loader } from "../components/Loader"
import { NavBar } from "../components/NavBar"
import { Button } from "../components/Button"
import { LogoHeader } from "../components/LogoHeader"
import { PATH_HOME } from "../navigation/RouterPaths"
import moment, { invalid } from "moment-timezone";
import "moment/locale/es";
import "moment/locale/pt";
import { CARRIER_NAME_CORREIOS, CARRIER_NAME_CUBBO } from "../constants/shippingMethods"
import { joinWords } from "../utils/StringUtils"
import { FormattedRelativeTime } from "../components/FormattedRelativeTime"
import { useQuery } from "react-query"

export const requiredFieldsByIncident = {
    DELAYED: {
        text_fields: [],
        file_fields: []
    },
    FALSE_DELIVERY: {
        text_fields: [],
        file_fields: []
    },
    MISSING_REFERENCES: {
        text_fields: ['description'],
        file_fields: []
    },
    MISSING_PRODUCT: {
        text_fields: ['description'],
        file_fields: ['package_images', 'label_images', 'product_images']
    },
    DAMAGED: {
        text_fields: ['description'],
        file_fields: ['package_images', 'label_images', 'product_images']
    },
    EXPIRED: {
        text_fields: [],
        file_fields: []
    },
    WRONG_PRODUCT: {
        text_fields: [],
        file_fields: []
    },
    RESCHEDULE: {
        text_fields: [],
        file_fields: []
    },
    CHANGE_ADDRESS: {
        text_fields: [],
        file_fields: []
    }
}

export const addDaysToETA = (order) => {
    const daysMilliseconds = 24 * 60 * 60 * 1000
    const date = order?.estimated_time_arrival
    const newDate = new Date(date)
    newDate.setTime(newDate.getTime() + daysMilliseconds) 
    return newDate
}

export const adjustDateForWeekend = (date) => {
    const newDate = new Date(date)
    while (isWeekend(newDate)) {
        newDate.setDate(newDate.getDate() + 1)
    }
    return newDate
}

export const isWeekend = (date) => {
    const day = date.getDay()
    return day === 0 || day === 6
}

export const ReportIncidentContainer = () => {
    const { i18n } = useTranslation()
    const { order_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const data = location?.state?.data;
    const category = location?.state?.category;
    const [errors, setErrors] = useState({});
    const [inputs, setInputs] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [invalidZipCode, setInvalidZipCode] = useState(false)
    const [invalidCoverage, setInvalidCoverage] = useState(false)

    const submitData = {
        label: i18n.t('reportIncident.create'),
    }    

    let fieldsData = null


    if ([DELAYED, FALSE_DELIVERY].includes(category)) {
        fieldsData = [
            {
                key: "description",
                label: i18n.t('reportIncident.form.labels.description'),
                category: category,
                type: 'textarea'
            }
        ]
    }
    else if (category === DAMAGED) {
        fieldsData = [
            {
                key: "package_images",
                label: i18n.t('reportIncident.form.labels.package_images'),
                placeholder: i18n.t('reportIncident.form.add_image'),
                category: category,
                type: 'image',
                maxFiles: 6,
            },
            {
                key: "label_images",
                label: i18n.t('reportIncident.form.labels.label_images'),
                placeholder: i18n.t('reportIncident.form.add_image'),
                category: category,
                type: 'image',
                maxFiles: 2,
            },
            {
                key: "product_images",
                label: i18n.t('reportIncident.form.labels.damaged_product_images'),
                placeholder: i18n.t('reportIncident.form.add_image'),
                category: category,
                type: 'image',
                maxFiles: 6,
            },
            {
                key: "description",
                label: i18n.t('reportIncident.form.labels.description'),
                category: category,
                type: 'textarea'
            },
        ]
    }
    else if (category === MISSING_PRODUCT) {
        fieldsData = [
            {
                key: "package_images",
                label: i18n.t('reportIncident.form.labels.package_images'),
                placeholder: i18n.t('reportIncident.form.add_image'),
                category: category,
                type: 'image',
                maxFiles: 6,
            },
            {
                key: "label_images",
                label: i18n.t('reportIncident.form.labels.label_images'),
                placeholder: i18n.t('reportIncident.form.add_image'),
                category: category,
                type: 'image',
                maxFiles: 2,
            },
            {
                key: "product_images",
                label: i18n.t('reportIncident.form.labels.damaged_zone_images'),
                placeholder: i18n.t('reportIncident.form.add_image'),
                category: category,
                type: 'image',
                maxFiles: 6,
            },
            {
                key: "description",
                label: i18n.t('reportIncident.form.labels.description'),
                category: category,
                type: 'textarea'
            },
        ]
    }
    else if (category === MISSING_REFERENCES) {
        if (data?.order.shipping_labels[0]?.shipping_method === CARRIER_NAME_CUBBO && data?.order?.shipping?.country?.toUpperCase() !== "BR") {
            const isChangeAddressIncidentPresent = (data?.order?.shipping_incidents.filter(incident => incident.category === CHANGE_ADDRESS))
            let changeAddressIncident = null
            if(isChangeAddressIncidentPresent.length) changeAddressIncident = JSON.parse(isChangeAddressIncidentPresent[0]?.description)
            if (isChangeAddressIncidentPresent.length) {
                fieldsData = [
                {
                    key: "current_address",
                    label: <div className="font-medium">
                        {i18n.t(`reportIncident.form.labels.${category}.current_address`)}
                    </div>,
                    description:
                        <div>
                            <p className="text-gray-600">
                                <span className="text-gray-800 font-medium">
                                    {i18n.t("orderDetails.shipping.address")}</span>
                                {joinWords(changeAddressIncident?.shipping_address_1, changeAddressIncident?.shipping_address_2)}</p>
                            {data?.order.shipping?.country?.toUpperCase() === "BR" &&
                                <>
                                    <p className="text-gray-600">
                                        <span className="text-gray-800 font-medium">
                                            {i18n.t("orderDetails.shipping.number")}</span>{(data?.order.shipping?.number || "")}</p>
                                    <p className="text-gray-600">
                                        <span className="text-gray-800 font-medium">
                                            {i18n.t("orderDetails.shipping.neighborhood")}</span>{(data?.order.shipping?.neighborhood || "")}</p>
                                </>}
                            <p className="text-gray-600">
                                <span className="text-gray-800 font-medium">
                                    {i18n.t("orderDetails.shipping.state")}</span>{(changeAddressIncident?.state || "")}</p>
                            <p className="text-gray-600">
                                <span className="text-gray-800 font-medium">
                                    {i18n.t("orderDetails.shipping.city")}</span>{(changeAddressIncident?.city || "")}</p>
                            <p className="text-gray-600">
                                <span className="text-gray-800 font-medium">
                                    {i18n.t("orderDetails.shipping.zip_code")}</span>{(changeAddressIncident?.zip_code || "")}</p>
                        </div>,
                    category: category,
                    type: 'paragraph',
                },
            ]
            }else { fieldsData = [
                {
                    key: "intro_cubbo",
                    description: <div className="">
                        {i18n.t(`reportIncident.form.labels.${category}.intro_cubbo`)}
                    </div>,
                    category: category,
                    type: 'paragraph',
                },
                {
                    key: "current_address",
                    label: <div className="font-medium">
                        {i18n.t(`reportIncident.form.labels.${category}.current_address`)}
                    </div>,
                    description:
                        <div>
                            <p className="text-gray-600">
                                <span className="text-gray-800 font-medium">
                                    {i18n.t("orderDetails.shipping.address")}</span>
                                {joinWords(data?.order.shipping.address_1, data?.order.shipping.address_2)}</p>
                            {data?.order.shipping?.country?.toUpperCase() === "BR" &&
                                <>
                                    <p className="text-gray-600">
                                        <span className="text-gray-800 font-medium">
                                            {i18n.t("orderDetails.shipping.number")}</span>{(data?.order.shipping?.number || "")}</p>
                                    <p className="text-gray-600">
                                        <span className="text-gray-800 font-medium">
                                            {i18n.t("orderDetails.shipping.neighborhood")}</span>{(data?.order.shipping?.neighborhood || "")}</p>
                                </>}
                            <p className="text-gray-600">
                                <span className="text-gray-800 font-medium">
                                    {i18n.t("orderDetails.shipping.state")}</span>{(data?.order.shipping?.state || "")}</p>
                            <p className="text-gray-600">
                                <span className="text-gray-800 font-medium">
                                    {i18n.t("orderDetails.shipping.city")}</span>{(data?.order.shipping?.city || "")}</p>
                            <p className="text-gray-600">
                                <span className="text-gray-800 font-medium">
                                    {i18n.t("orderDetails.shipping.zip_code")}</span>{(data?.order.shipping?.zip_code || "")}</p>
                        </div>,
                    category: category,
                    type: 'paragraph',
                },
                /*
                {
                    key: "change_address",
                    description:
                        <div>
                            <p className="text-gray-500">
                                <span>
                                    {i18n.t(`reportIncident.form.labels.${category}.change_addres`)}
                                </span>
                                <a className="text-blue-500 hover:underline hover:text-blue-400"
                                    //href={data?.order.shipping_labels[0]?.tracking_url} //Correct path here
                                    //href={navigate(`/orders/${order_id}`, {state: {data: data}})}
                                    rel='noreferrer'
                                    //target="_blank"
                                    >
                                    {i18n.t(`reportIncident.form.labels.here`)}
                                </a>
                            </p>
                        </div>,
                    category: category,
                    type: 'paragraph',
                },
                */
            ]
            }

            fieldsData.push(
                {
                    key: "description",
                    label: <div className="font-medium">
                        {i18n.t(`reportIncident.form.labels.${category}.additional_address_cubbo`)}
                    </div>,
                    category: category,
                    type: 'textarea',
                }
            )
        } else {
            fieldsData = [
                {
                    key: "intro",
                    description: <div className="">
                        {i18n.t(`reportIncident.form.labels.${category}.intro`)}
                        {data?.order.store.contact_email ?
                            <a className="text-blue-500 hover:underline hover:text-blue-400"
                                href={`mailto: ${data?.order.store.contact_email}`}>
                                {data?.order.store.contact_email}
                            </a>
                            : <>{data?.order.store.name}</>}
                        {'.'}
                    </div>,
                    category: category,
                    type: 'paragraph',
                },
                {
                    key: "current_address",
                    label: <div className="font-medium">
                        {i18n.t(`reportIncident.form.labels.${category}.current_address`)}
                    </div>,
                    description:
                        <div>
                            <p className="text-gray-600">
                                <span className="text-gray-800 font-medium">
                                    {i18n.t("orderDetails.shipping.address")}</span>
                                {joinWords(data?.order.shipping.address_1, data?.order.shipping.address_2)}</p>
                            {data?.order.shipping?.country?.toUpperCase() === "BR" &&
                                <>
                                    <p className="text-gray-600">
                                        <span className="text-gray-800 font-medium">
                                            {i18n.t("orderDetails.shipping.number")}</span>{(data?.order.shipping?.number || "")}</p>
                                    <p className="text-gray-600">
                                        <span className="text-gray-800 font-medium">
                                            {i18n.t("orderDetails.shipping.neighborhood")}</span>{(data?.order.shipping?.neighborhood || "")}</p>
                                </>}
                            <p className="text-gray-600">
                                <span className="text-gray-800 font-medium">
                                    {i18n.t("orderDetails.shipping.state")}</span>{(data?.order.shipping?.state || "")}</p>
                            <p className="text-gray-600">
                                <span className="text-gray-800 font-medium">
                                    {i18n.t("orderDetails.shipping.city")}</span>{(data?.order.shipping?.city || "")}</p>
                            <p className="text-gray-600">
                                <span className="text-gray-800 font-medium">
                                    {i18n.t("orderDetails.shipping.zip_code")}</span>{(data?.order.shipping?.zip_code || "")}</p>
                        </div>,
                    category: category,
                    type: 'paragraph',
                },
                {
                    key: "description",
                    label: <div className="font-medium">
                        {i18n.t(`reportIncident.form.labels.${category}.additional_address`)}
                    </div>,
                    category: category,
                    type: 'textarea',
                }
            ]
        }
    } else if (category == RESCHEDULE) {
        submitData.label = <>
                                {i18n.t(`reportIncident.form.labels.${category}.button`) }
                                <FormattedRelativeTime 
                                    forceDays={true} 
                                    etaOffset={data?.order.shipping_coverage.eta_margin_of_error_in_hours}
                                    date={adjustDateForWeekend(addDaysToETA(data?.order))}
                                    time_zone={data?.order.store.warehouses[0]?.time_zone}
                                />
                            </>
        fieldsData = [
            {
                key: "current_shipping_date",
                description:
                <div className="text-gray-600">
                <span>{i18n.t(`reportIncident.form.labels.${category}.your_order`)}</span>
                <span>
                    <FormattedRelativeTime 
                        forceDays={true} 
                        etaOffset={data?.order.shipping_coverage.eta_margin_of_error_in_hours}
                        date={data?.order.estimated_time_arrival}
                        time_zone={data?.order.store.warehouses[0]?.time_zone}
                    />
                </span>
                <span>&nbsp;{i18n.t(`reportIncident.form.labels.${category}.timezone_range`)}</span>
            </div>,
                category: category,
                type: 'paragraph',
            }
        ]
    } else if (category === MORE_HELP) {
        fieldsData = [
            {
                key: "current_shipping_date",
                label:
                    <div className="py-3">
                        <div className="text-sm mb-3">{i18n.t('helpOrder.need_help_description', { store: data?.order.store.name })}</div>
                        {data?.order.store.contact_email && (
                            <div className="text-sm">
                                {i18n.t('helpOrder.need_help_description_email')}
                                <a className="text-blue-500 hover:underline hover:text-blue-400" href={`mailto: ${data?.order.store.contact_email}`}>
                                    {data?.order.store.contact_email}
                                </a>
                            </div>
                        )}
                    </div>,
                category: category,
                type: 'label'
            }
        ]
    } else if ([EXPIRED].includes(category)) {
        fieldsData = [
            {
                key: "sorry_for_incovenience",
                label: i18n.t('reportIncident.form.labels.sorry_for_incovenience'),
                category: category,
                type: 'label',
            },
            {
                key: "contact_email",
                label: <div>
                    {i18n.t(`reportIncident.form.labels.${category}.contact`)}
                    {data?.order.store.contact_email ?
                        <a className="text-blue-500 hover:underline hover:text-blue-400"
                            href={`mailto: ${data?.order.store.contact_email}`}>
                            {data?.order.store.contact_email}
                        </a>
                        : <>{data?.order.store.name}</>}
                    {i18n.t(`reportIncident.form.labels.${category}.solve_problem`)}
                </div>,
                category: category,
                type: 'label',
            }
        ]
    }
    else if ([WRONG_PRODUCT].includes(category)) {
        fieldsData = [
            {
                key: "sorry_for_incovenience",
                label: i18n.t('reportIncident.form.labels.sorry_for_incovenience'),
                category: category,
                type: 'label',
            },
            {
                key: "reason",
                label: i18n.t(`reportIncident.form.labels.${category}.reason`),
                category: category,
                type: 'label',
            },
            {
                key: "contact_email",
                label: <div>
                    {i18n.t(`reportIncident.form.labels.${category}.contact`)}
                    {data?.order.store.contact_email ?
                        <a className="text-blue-500 hover:underline hover:text-blue-400"
                            href={`mailto: ${data?.order.store.contact_email}`}>
                            {data?.order.store.contact_email}
                        </a>
                        : <>{data?.order.store.name}</>}
                    {i18n.t(`reportIncident.form.labels.${category}.solve_problem`)}
                </div>,
                category: category,
                type: 'label',
            }
        ]
    }
    // else if (category === EXTRA_PRODUCT) {
    //     fieldsData = [
    //         {
    //             key: EXTRA_PRODUCT,
    //             label: i18n.t('reportIncident.form.labels.EXTRA_PRODUCT', {store: data?.order.store.name}),
    //             category: category,
    //             type: 'label',
    //         },
    //         {
    //             key: "thanks_for_reporting",
    //             label: i18n.t('reportIncident.form.labels.thanks_for_reporting'),
    //             category: category,
    //             type: 'label',
    //         }
    //     ]
    // }

    else if ([WITHOUT_MOVEMENT].includes(category)) {
        moment.locale(i18n.language)

        const eta = data?.order.estimated_time_arrival
        const time_zone = data?.order.store.warehouses[0]?.time_zone

        const now = time_zone ? moment().tz(time_zone) : moment()
        const relativeDate = time_zone ? moment(eta).tz(time_zone) : moment(eta)
        const daysLeft = relativeDate.startOf('day').diff(now.startOf('day'), 'days', false)
    
        fieldsData = [
            {
                key: "dont_worry",
                label: daysLeft > 0 ? i18n.t('reportIncident.form.labels.dont_worry', {days: daysLeft})
                        : i18n.t('reportIncident.form.labels.dont_worry_time'),
                category: category,
                type: 'label',
            },
        ]
        if (data?.order?.shipping_labels?.length > 0 && data?.order.shipping_labels[0]?.shipping_method !== CARRIER_NAME_CUBBO) {
            fieldsData.push({
                key: "tracking_website",
                label: <div>
                    {i18n.t('reportIncident.form.labels.tracking_website', { carrier: data?.order.shipping_labels[0]?.shipping_method })}
                    <a className="text-blue-500 hover:underline hover:text-blue-400"
                        href={data?.order.shipping_labels[0]?.tracking_url}
                        rel='noreferrer'
                        target="_blank">
                        {i18n.t(`reportIncident.form.labels.here`)}
                    </a>
                    {'.'}
                </div>,
                category: category,
                type: 'label',
            })
        }
    }

    else if ([CHANGE_ADDRESS].includes(category)) {
        if (data?.order.shipping_labels[0]?.shipping_method === CARRIER_NAME_CUBBO && data?.order.shipping?.country?.toUpperCase() !== "BR") {
            fieldsData = [
                {

                    key: "order_in_movement",
                    label: <div className="text-gray-600">
                        {i18n.t(`reportIncident.form.labels.${category}.order_in_movement_cubbo`)}
                    </div>,
                    category: category,
                    type: 'paragraph'
                },
                {

                    key: "update_address",
                    label: <div className="font-medium">
                        {i18n.t(`reportIncident.form.labels.${category}.update_address`)}
                    </div>,
                    category: category,
                    type: 'paragraph'
                },
                {

                    key: "state",
                    label: <div className="font-medium">
                        {i18n.t(`reportIncident.form.labels.${category}.state`)}
                    </div>,
                    category: category,
                    type: 'inputfield',
                    disabledTextField: true,
                    initialValue: data?.order?.shipping.state
                },
                {

                    key: "city",
                    label: <div className="font-medium">
                        {i18n.t(`reportIncident.form.labels.${category}.city`)}
                    </div>,
                    category: category,
                    type: 'inputfield',
                    disabledTextField: true,
                    initialValue: data?.order?.shipping.city
                },
                {

                    key: "zipcode",
                    label: <div className="font-medium">
                        {i18n.t(`reportIncident.form.labels.${category}.zipcode`)}
                    </div>,
                    category: category,
                    type: 'inputfield',
                    initialValue: data?.order?.shipping.zip_code,
                    isInvalidData: invalidZipCode || invalidCoverage,
                    invalidDataMessage: invalidCoverage ? i18n.t(`reportIncident.form.errors.ERROR_ZIP_CODE_COVERAGE`) : i18n.t(`reportIncident.form.errors.ERROR_ZIP_CODE_DIGITS`)

                },
                {

                    key: "address1",
                    label: <div className="font-medium">
                        {i18n.t(`reportIncident.form.labels.${category}.address`)}
                    </div>,
                    category: category,
                    type: 'inputfield',
                    initialValue: data?.order?.shipping.address_1
                },
                {

                    key: "address2",
                    label: <div className="font-medium">
                        {i18n.t(`reportIncident.form.labels.${category}.references`)}
                    </div>,
                    category: category,
                    type: 'inputfield',
                    initialValue: data?.order?.shipping.address_2
                }
            ]
        } else {
            fieldsData = [
                {
                    key: "order_in_movement",
                    label: i18n.t(`reportIncident.form.labels.${category}.order_in_movement`),
                    category: category,
                    type: 'label',
                },
                {
                    key: "contact_email",
                    label: <div>
                        {i18n.t(`reportIncident.form.labels.${category}.contact_email`)}
                        {data?.order.store.contact_email ?
                            <a className="text-blue-500 hover:underline hover:text-blue-400"
                                href={`mailto: ${data?.order.store.contact_email}`}>
                                {data?.order.store.contact_email}
                            </a>
                            : <>{data?.order.store.name}</>}
                        {'.'}
                    </div>,
                    category: category,
                    type: 'label',
                }
            ]

            // Complement address is currently disabled
            /*
            if (data?.order.shipping_labels[0]?.shipping_method !== CARRIER_NAME_CORREIOS) {
                fieldsData.splice(1, 0, {
                    key: "complement_address",
                    label: <div>
                        {i18n.t(`reportIncident.form.labels.${category}.complement_address`)}
                        <div className="inline text-blue-500 hover:underline hover:text-blue-400 cursor-pointer"
                            onClick={() => navigate(`/orders/${order_id}/report-incident`,
                                { state: { data: data, category: MISSING_REFERENCES } })}>
                            {i18n.t(`reportIncident.form.labels.here`)}
                        </div>
                        {'.'}
                    </div>,
                    category: category,
                    type: 'label',
                })
            }
            */
        }
    }

    const disableSubmit = () => {
        if (!requiredFieldsByIncident.hasOwnProperty(category)) return false

        const allTextFieldsPresent = requiredFieldsByIncident[category]['text_fields'].every(field => inputs?.[field])
        const allFileFieldsPresent = requiredFieldsByIncident[category]['file_fields'].every(field => inputs?.[field]?.length)

        return !(allTextFieldsPresent && allFileFieldsPresent && !invalidZipCode && !invalidCoverage)
    }

    const buildParameters = () => {

        const formData = new FormData()
        formData.append("category", category)
        formData.append("order_id", order_id)

        requiredFieldsByIncident[category]['file_fields'].forEach(field => {
            inputs[field].forEach((file) => formData.append(field + "[]", file))
        })

        requiredFieldsByIncident[category]['text_fields'].forEach(field => {
            formData.append(field, inputs[field])
        })

        return formData
    }

    const [newZipCode, setNewZipCode] = useState(data?.order.shipping?.zip_code)

    console.log(data)

    const { isError, isPreviousData, isFetching, data: coverageValidity } = useQuery(
        [`new_zip_code`, order_id, newZipCode],
        () => validateCoverage(order_id, data?.order?.shipping_method?.id, newZipCode)
    )

    useEffect(() => {
        if(data?.order.shipping_labels[0]?.shipping_method !== CARRIER_NAME_CUBBO || data?.order.shipping?.country?.toUpperCase() === "BR") return
        if(data?.order.shipping?.country?.toUpperCase() === "MX") {
            if(!coverageValidity || newZipCode.length != 5){
                setInvalidCoverage(false)
                return
            }

        }else if (data?.order.shipping?.country?.toUpperCase() === "BR") {
            if(!coverageValidity || newZipCode.length != 7){
                setInvalidCoverage(false)
                return
            }
        }

        if(!coverageValidity.exists) setInvalidCoverage(true)
        else setInvalidCoverage(false)
    }, [coverageValidity, newZipCode])

    const validateCubboZipCode = (value) => {
        if(data?.order.shipping_labels[0]?.shipping_method !== CARRIER_NAME_CUBBO || data?.order.shipping?.country?.toUpperCase() === "BR") return
        if(data?.order.shipping?.country?.toUpperCase() === "MX")
            if (value.length != 5) setInvalidZipCode(true)
            else setInvalidZipCode(false)
        else if(data?.order.shipping?.country?.toUpperCase() === "BR"){
            if (value.length != 7) setInvalidZipCode(true)
            else setInvalidZipCode(false)
        }
        else setInvalidZipCode(false)
    }

    const handleInputUpdate = (item, value) => {
        if (item === 'zipcode') {
            setNewZipCode(value)
            validateCubboZipCode(value)
        }
        setInputs(values => ({ ...values, [item]: value }))

    }

    const handleCreateIncident = () => {
        const params = buildParameters(category)
        setIsLoading(true)
        createIncident(params).then((res) => {
            setIsLoading(false)
            navigate(`/orders/${order_id}/incident-created`, { state: { data: data, category: category } })
        }).catch((error) => {
            setIsLoading(false)
            setErrors(values => ({
                ...values, serverError: error.code === 'ERR_NETWORK' ?
                    i18n.t('findOrder.form.errors.ERR_NETWORK')
                    : i18n.t(`reportIncident.form.errors.${error.response?.data?.code}`)
            }))
        })
    }

    const handleEditOrderAddress = async () => {
        setIsLoading(true)
        
        try {
            await editOrderAddress(order_id, {
                shipping_address_1: inputs['address1'],
                shipping_address_2: inputs['address2'],
                shipping_zip_code: inputs['zipcode']
            })
            setIsLoading(false)
            navigate(`/orders/${order_id}/incident-created`, { state: { data: data, category: category } })
        } catch (error) {
            setIsLoading(false)
            setErrors(values => ({
                ...values,
                serverError: error.code === 'ERR_NETWORK' ?
                    i18n.t('findOrder.form.errors.ERR_NETWORK')
                    : i18n.t(`reportIncident.form.errors.${error.response?.data?.code}`)
            }))
        }
    }

    const handleGoHome = () => {
        navigate(`/orders/${order_id}`, { state: { data: data } })
    }

    const handleConfirmRescheduling = () => {
        navigate(`/orders/${order_id}/confirm`, { state: { data: data, category: category }})
    }

    return (
        <>
            {isLoading ? (
                <><Loader className="bg-gray-50" show={true}></Loader></>
            ) : data === undefined ? (
                <Navigate to={PATH_HOME} replace />
            ) : (
                <>
                    <div className="bg-gray-50 py-5 px-10 sm:max-w-[480px]">
                        <LogoHeader src={data?.order.store.logo_file?.path && process.env.REACT_APP_PLATFORM_URL + data?.order.store.logo_file?.path} />
                    </div>
                    <div className="flex flex-col h-full bg-gray-50">
                        <NavBar className='mt-5'
                            title={i18n.t('showOrder.help_with_order')}
                            description={(data?.order.shipping_labels[0]?.shipping_method === CARRIER_NAME_CUBBO && data?.order?.shipping?.country?.toUpperCase() !== "BR") ? i18n.t(`helpOrder.shipping_incident.options.${category}_CUBBO`) : i18n.t(`helpOrder.shipping_incident.options.${category}`)}
                            onClick={() => navigate(-1)} />
                        <div className="px-10">
                            {
                                data?.order.shipping_labels[0]?.shipping_method === CARRIER_NAME_CUBBO && data?.order.shipping?.country?.toUpperCase() !== "BR"?
                                    ([EXPIRED, WRONG_PRODUCT, WITHOUT_MOVEMENT, MORE_HELP].includes(category) ? (
                                        <div className="flex flex-col items-center pb-5 text-sm">
                                            {fieldsData?.map(item => {


                                                return (
                                                    <div className="flex flex-col pt-5 w-full" key={item.key}>
                                                        {item.label}
                                                    </div>
                                                )
                                            }
                                            )}
                                            <Button
                                                className={'mt-10'}
                                                label={i18n.t('incidentCreated.go_home')}
                                                onClick={handleGoHome} />
                                        </div>)
                                        :
                                        [CHANGE_ADDRESS].includes(category) ? 
                                        (
                                            <Form fieldsData={fieldsData} submitData={submitData} onSubmit={handleEditOrderAddress}
                                                errors={errors}
                                                handleInputUpdate={handleInputUpdate}
                                                disableSubmit={disableSubmit(category) || data?.order?.first_delivery_attempt_at !== null}
                                                inputs={inputs} />
                                        )
                                        :
                                        [RESCHEDULE].includes(category) ? 
                                        (
                                            <Form fieldsData={fieldsData} submitData={submitData} onSubmit={handleConfirmRescheduling}
                                                errors={errors}
                                                handleInputUpdate={handleInputUpdate}
                                                disableSubmit={disableSubmit(category)|| data?.order?.first_delivery_attempt_at !== null || data?.order?.estimated_time_arrival === null}
                                                inputs={inputs} />
                                        )
                                        :
                                        (
                                            <Form fieldsData={fieldsData} submitData={submitData} onSubmit={handleCreateIncident}
                                                errors={errors}
                                                handleInputUpdate={handleInputUpdate}
                                                disableSubmit={disableSubmit(category)}
                                                inputs={inputs} />
                                        )
                                    )
                                    :
                                    ([CHANGE_ADDRESS, EXPIRED, WRONG_PRODUCT, WITHOUT_MOVEMENT, MORE_HELP].includes(category) ?
                                        <div className="flex flex-col items-center pb-5 text-sm">
                                            {fieldsData.map(item =>
                                                <div className="flex flex-col pt-5 w-full" key={item.key}>
                                                    {item.label}
                                                </div>
                                            )}
                                            <Button
                                                className={'mt-10'}
                                                label={i18n.t('incidentCreated.go_home')}
                                                onClick={handleGoHome} />
                                        </div>
                                        :
                                        <Form fieldsData={fieldsData} submitData={submitData} onSubmit={handleCreateIncident}
                                            errors={errors}
                                            handleInputUpdate={handleInputUpdate}
                                            disableSubmit={disableSubmit(category)}
                                            inputs={inputs} />
                                    )
                            }

                        </div>
                    </div>
                </>)}
        </>
    )
}