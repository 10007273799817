import { useState } from "react";
import { Form } from "../components/Form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchOrderByShippingNumber } from "../services/orderServices";
import { Loader } from "../components/Loader";

export const FindOrderContainer = () => {
    const { i18n } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const error = location?.state?.error
    const [inputs, setInputs] = useState({})
    const [errors, setErrors] = useState(error ? {serverError: i18n.t(`findOrder.form.errors.${error}`)} : {})
    const [isLoading, setIsLoading] = useState(false)

    const fieldsData = [
        {
            key: "shipping_number",
            label: i18n.t('findOrder.form.shipping_number'),
            type: 'text',
        }
    ]

    const submitData = {
        label: i18n.t('findOrder.find_my_order'),
    }
    
    const handleFindOrder = () => {
        const shipping_number = inputs.shipping_number?.trim()
        if (shipping_number) {
            setIsLoading(true)
            fetchOrderByShippingNumber(shipping_number).then((order_id)=>{
                setIsLoading(false)
                navigate({
                    pathname: `/orders/${order_id}`
                })
            }).catch((error)=>{
                setIsLoading(false)
                setErrors(values => (
                    {...values, serverError: error.code === 'ERR_NETWORK' ? 
                    i18n.t('findOrder.form.errors.ERR_NETWORK')
                    : i18n.t(`findOrder.form.errors.${error.response?.data?.code}`)}))
            })
        }
        else setErrors(values => ({...values, serverError: i18n.t('findOrder.form.errors.blank')}))
    }

    const handleInputUpdate = (item, value) => {
        if (value)  setErrors(prev => ({...prev, serverError: null}))
        setInputs(values => ({...values, [item]: value}))
    }

    return (
        <>
        {isLoading ? (
            <><Loader className="bg-gray-50" show={true}></Loader></>
        ) : (
        <div className="flex flex-col justify-center px-10 h-full bg-gray-50">
            <div className="ml-1 mt-3 text-lg font-semibold">{i18n.t('findOrder.help_with_order')}</div>

            <Form
                fieldsData={fieldsData} 
                submitData={submitData}
                handleInputUpdate={handleInputUpdate}
                onSubmit={handleFindOrder}
                errors={errors}
            />
        </div>)}
        </>
    )
}