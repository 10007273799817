import { useTranslation } from "react-i18next"
import { FormattedDate } from "../FormattedDate"
import { ArrowRightIcon } from "../Icons/ArrowRightIcon"
import { CheckIcon } from "../Icons/CheckIcon"
import { EmailReceivedIcon } from "../Icons/EmailReceivedIcon"
import { EmailSentIcon } from "../Icons/EmailSentIcon"
import { TimelineItemHeader } from "./TimelineItemHeader"

export const TIMELINE_STATUS_COMPLETE = 'complete'
export const TIMELINE_STATUS_CURRENT = 'current'
export const TIMELINE_STATUS_UPCOMING = 'upcoming'
export const TIMELINE_STATUS_INCIDENT_CREATED = 'incident_created'
export const TIMELINE_STATUS_INCIDENT_RESOLVED = 'incident_resolved'
export const TIMELINE_STATUS_ADDRESS_CHANGED_FROM_TRACKING_APP = 'address_changed_from_tracking_app'

const currentStatusTitleColor = {
  'green': 'text-green-500',
  'red': 'text-red-500',
  'yellow': 'text-yellow-500'
}

const currentStatusBorderColor = {
  'green': 'border-green-500',
  'red': 'border-red-500',
  'yellow': 'border-yellow-500'
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Timeline({steps = null}) {
  const { i18n } = useTranslation()
  const currentStepIndex = steps?.findIndex(({status}) => status === TIMELINE_STATUS_CURRENT)

  return (
    <nav aria-label="Progress">
      <ol className="overflow-hidden">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative')}>
            {step.status === TIMELINE_STATUS_COMPLETE ? (
              <>
                {/* Line */}
                {stepIdx !== steps.length - 1 ? (
                  <div className="absolute left-[90px] top-4 -ml-px mt-0.5 h-full w-0.5 bg-zinc-300" aria-hidden="true" />
                ) : null}
                <a href={step.href} className="relative flex items-start">
                  <div className="mt-1 w-20 text-sm text-gray-500 leading-tight shrink-0">
                    <FormattedDate date={step.reference_timestamp} shortDate={true}/>
                  </div>
                  <span className="mt-1 relative z-10 shrink-0 flex h-5 w-5 items-center justify-center rounded-full bg-zinc-300">
                    <CheckIcon className="h-3 w-3 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <TimelineItemHeader title={step.title} description={step.description} titleClassName={'font-semibold'} />
                  </span>
                </a>
              </>
            ) : step.status === TIMELINE_STATUS_CURRENT ? (
              <>
                {/* Dashed Line if future status is NOT an incident */}
                {stepIdx !== steps.length - 1 ? (
                  <div className={
                    classNames([TIMELINE_STATUS_INCIDENT_CREATED, TIMELINE_STATUS_INCIDENT_RESOLVED].includes(steps[stepIdx+1].status) ? 
                    'bg-zinc-300' : 'border-r-2 border-dashed', 'absolute left-[90px] top-4 -ml-px mt-0.5 h-full w-0.5')} aria-hidden="true" />
                ) : null}
                <a href={step.href} className="relative flex items-start" aria-current="step">
                  <div className="mt-1 w-20 text-sm text-gray-500 leading-tight shrink-0">
                  {step.reference_timestamp && 
                    <FormattedDate date={step.reference_timestamp} shortDate={true}/>}
                  </div>
                    <span className={`mt-1 shrink-0 flex h-5 w-5 items-center justify-center rounded-full bg-white border-[1.5px] ${currentStatusBorderColor[step.currentStatusColor]}`} >
                      <ArrowRightIcon className={`h-3 w-3 ${currentStatusTitleColor[step.currentStatusColor]}`} aria-hidden="true" />
                    </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <TimelineItemHeader title={step.title} description={step.description} titleClassName={`font-semibold ${currentStatusTitleColor[step.currentStatusColor]}`}/>
                  </span>
                </a>
              </>
            ) : step.status === TIMELINE_STATUS_UPCOMING ? (
              <>
                {/* Dashed Line */}
                {stepIdx !== steps.length - 1 ? (
                  <div className="absolute left-[90px] top-4 -ml-px mt-0.5 h-full w-0.5 border-r-2 border-dashed" aria-hidden="true" />
                ) : null}
                <a href={step.href} className="relative flex items-center">
                  <div className="mt-1 w-20 text-sm text-gray-500 shrink-0"></div>
                  <span className="flex min-w-0 flex-row"> 
                    <span className="mt-1 shrink-0 flex h-5 w-5 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                      <span className="h-2.5 w-2.5 rounded-full bg-transparent" />
                    </span>
                    <div className="ml-5">
                      <TimelineItemHeader title={step.title} description={step.description} titleClassName={`${step?.isAction ? "text-sm text-gray-500" : " text-sm font-light text-gray-300"}`}/>
                      {step.isAction && (
                              <div>{step.action}</div>
                          )}
                      </div>
                  </span>
                </a>
              </>
            ) : (step.status === TIMELINE_STATUS_INCIDENT_CREATED || step.status === TIMELINE_STATUS_ADDRESS_CHANGED_FROM_TRACKING_APP) ? (
              <>
                {/* Dashed Line if future status is NOT an incident */}
                {stepIdx !== steps.length - 1 ? (
                  <div className={
                    classNames([TIMELINE_STATUS_INCIDENT_CREATED, TIMELINE_STATUS_INCIDENT_RESOLVED, TIMELINE_STATUS_COMPLETE, TIMELINE_STATUS_CURRENT].includes(steps[stepIdx+1].status) || (
                      step.status === TIMELINE_STATUS_ADDRESS_CHANGED_FROM_TRACKING_APP && stepIdx < currentStepIndex
                    ) ? 
                    'bg-zinc-300' : 'border-r-2 border-dashed', 'absolute left-[90px] top-4 -ml-px mt-0.5 h-full w-0.5')} aria-hidden="true" />
                ) : null}
                <a href={step.href} className="relative flex items-start">
                  <div className="mt-1 w-20 text-sm text-gray-500 leading-tight shrink-0">
                    <FormattedDate date={step.reference_timestamp} shortDate={true}/>
                  </div>
                  <span className="mt-0.5 h-6 w-6">
                    <EmailSentIcon className="h-6 w-6 text-gray-300" aria-hidden="true" />
                  </span>
                  <div>
                  <span className="ml-4 flex min-w-0 flex-col">
                      <div>{step.title}</div>
                      <div className="mt-0.5 text-sm text-gray-500">{step.description}</div>
                  </span>
                  {step.isAction ? (
                      <div className="mt-1 ml-4 hover:underline cursor-pointer text-blue-500 text-sm" onClick={step.action}>{i18n.t('showOrder.watch')}</div>
                  ) : null}
                 </div>
                </a>
              </>
            ) : step.status === TIMELINE_STATUS_INCIDENT_RESOLVED ? (
              <>
                {/* Dashed Line if future status is NOT an incident */}
                {stepIdx !== steps.length - 1 ? (
                  <div className={
                    classNames([TIMELINE_STATUS_INCIDENT_CREATED, TIMELINE_STATUS_INCIDENT_RESOLVED, TIMELINE_STATUS_COMPLETE, TIMELINE_STATUS_CURRENT].includes(steps[stepIdx+1].status) ? 
                    'bg-zinc-300' : 'border-r-2 border-dashed', 'absolute left-[90px] top-4 -ml-px mt-0.5 h-full w-0.5')} aria-hidden="true" />
                ) : null}
                <a href={step.href} className="relative flex items-start">
                  <div className="mt-1 w-20 text-sm text-gray-500 leading-tight shrink-0">
                    <FormattedDate date={step.reference_timestamp} shortDate={true}/>
                  </div>
                  <span className="mt-0.5 -ml-1 h-6 w-6">
                    <EmailReceivedIcon className="h-6 w-6 text-gray-300" aria-hidden="true" />
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <div className="mt-0.5 text-sm text-gray-500">{step.description}</div>
                  </span>
                </a>
              </>
            ) : ''}
          </li>
        ))}
      </ol>
    </nav>
  )
}
