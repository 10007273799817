import { useTranslation } from "react-i18next"
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { OrderHeader } from "../components/OrderHeader"
import { useQuery } from "react-query"
import { fetchOrder } from "../services/orderServices"
import { Loader } from "../components/Loader"
import { PATH_HOME } from "../navigation/RouterPaths"
import { FormattedRelativeTime } from "../components/FormattedRelativeTime"
import { STATUS_SHIPMENT_DELIVERED, STATUS_SHIPMENT_WITH_INCIDENT, STATUS_SHIPMENT_TO_BE_SELF_COLLECTED ,TRACKING_DESCRIPTIONS, STATUS_SHIPPED, STATUS_SHIPMENT_CANCELED, STATUS_INITIAL, STATUS_SHIPMENT_IN_TRANSIT, STATUS_SHIPMENT_COLLECTED, STATUS_SHIPMENT_RETURNED, STATUS_RETURNING, STATUS_SHIPMENT_OUT_FOR_DELIVERY, STATUS_PROCESSING, STATUS_ERROR, STATUS_HOLD, STATUS_BACKORDER, STATUS_UNPAID, STATUS_RETURNED, STATUS_RETURN_ARRIVED, WILL_NOT_DELIVER_STATUSES, WILL_NOT_DELIVER_SHIPMENT_STATUSES, STATUS_PENDING, STATUS_CANCELED, STATUS_INTERRUPTED, STATUS_ENTIRELY_DROPSHIPPING } from "../constants/orderStatuses"
import { CANCELED, CHANGE_ADDRESS, MISSING_REFERENCES, REFUNDABLE, RESCHEDULE } from "../constants/shippingIncidents"
import { LogoHeader } from "../components/LogoHeader"
import { CARRIER_NAME_CUBBO, DHL } from "../constants/shippingMethods"
import Timeline, { TIMELINE_STATUS_COMPLETE, TIMELINE_STATUS_CURRENT, TIMELINE_STATUS_INCIDENT_CREATED, TIMELINE_STATUS_INCIDENT_RESOLVED, TIMELINE_STATUS_UPCOMING, TIMELINE_STATUS_ADDRESS_CHANGED_FROM_TRACKING_APP } from "../components/Timeline/Timeline"
import { useEffect, useMemo, useState } from "react"
import { joinWords } from "../utils/StringUtils"
import { sendAnalytics } from "../utils/analyticsUtils"

// Statuses that are being shown at home page
const timelineStatusesToShow = [STATUS_INITIAL, STATUS_PROCESSING, STATUS_SHIPPED, STATUS_RETURNING, STATUS_SHIPMENT_IN_TRANSIT, 
    STATUS_SHIPMENT_WITH_INCIDENT, STATUS_SHIPMENT_OUT_FOR_DELIVERY, STATUS_SHIPMENT_TO_BE_SELF_COLLECTED, 
    STATUS_SHIPMENT_DELIVERED, STATUS_SHIPMENT_CANCELED, STATUS_SHIPMENT_RETURNED, STATUS_SHIPMENT_COLLECTED, STATUS_HOLD, STATUS_BACKORDER, STATUS_UNPAID, STATUS_PENDING, STATUS_CANCELED, STATUS_INTERRUPTED, STATUS_ENTIRELY_DROPSHIPPING]

// Title color when status is current by status
const currentStatusColor = {
    [STATUS_INITIAL] : 'green',
    [STATUS_PROCESSING] : 'green',
    [STATUS_SHIPPED] : 'green',
    [STATUS_SHIPMENT_IN_TRANSIT + '_delayed'] : 'yellow',
    [STATUS_SHIPMENT_IN_TRANSIT] : 'green', 
    [STATUS_SHIPMENT_OUT_FOR_DELIVERY] : 'green',
    [STATUS_SHIPMENT_WITH_INCIDENT]: 'red',
    [STATUS_SHIPMENT_DELIVERED] : 'green',
    [STATUS_SHIPMENT_TO_BE_SELF_COLLECTED]: 'green',
    [STATUS_RETURNING]: 'green',
    [STATUS_SHIPMENT_CANCELED] : 'red',
    [STATUS_SHIPMENT_RETURNED] : 'red',
    [STATUS_SHIPMENT_COLLECTED] : 'green',
    [STATUS_ERROR] : 'red',
    [STATUS_ERROR + '_delayed'] : 'yellow',
    [STATUS_HOLD] : 'yellow',
    [STATUS_BACKORDER] : 'yellow',
    [STATUS_UNPAID] : 'red',
    [STATUS_RETURNED]: 'green',
    [STATUS_RETURN_ARRIVED]: 'green',
    [STATUS_PENDING] : 'green',
    [STATUS_CANCELED] : 'red',
    [STATUS_INTERRUPTED] : 'red',
    [STATUS_ENTIRELY_DROPSHIPPING]: 'yellow',
}

// Order history actions. Note: cancel action doesn't exist (we should use new_status 'canceled')
const actions = {
    CREATE: "create",
    DUPLICATE: "duplicate",
    ORDER_STATUS_UPDATE: "order_status_update",
    TRACKING_UPDATE: "tracking_update",
    RESET_PENDING: "reset_pending",
    EDIT: "edit",
    UNHOLD: "unhold"
}

const origins = {
    tracking_app: "tracking_app"
}

const timelineActionsToHide = [actions.RESET_PENDING]

export const DELIVERY_ATTEMPT_DESCRIPTIONS = {
    MISSING_REFERENCES: 'missing_references',
    NOT_AT_HOME: 'not_at_home',
    REFUSED_PAYMENT: 'refused_payment',
    REFUSED_PACKAGE: 'refused_package',
    SECOND_TRY: 'second_try',
    THIRD_TRY: 'third_try'
}

export const FINAL_SHIPPING_INCIDENTS_STATUSES = {
    STOLEN: 'stolen',
    LOST: 'lost',
    DAMAGED: 'damaged'
}

export const FINAL_DELIVERY_ATTEMPT_DESCRIPTIONS = {
    REFUSED_PAYMENT: 'refused_payment',
    REFUSED_PACKAGE: 'refused_package'
}

export const ShowOrderContainer = () => {
    const { i18n } = useTranslation()
    const { order_id } = useParams()
    const [ searchParams ] = useSearchParams()
    const navigate = useNavigate()
    const location = useLocation()
    const [isViewTrackingEventSent, setIsViewTrackingEventSent] = useState(false)

    const {
        isLoading,
        isError,
        error,  
        data,
        isPreviousData
    } = useQuery(['order', order_id], () => fetchOrder(order_id), { 
        retry: 1
    })

    const shippingCountry = data?.order?.shipping.country?.toUpperCase()
    const storeWarehouseCountry = data?.order?.store?.warehouses[0].country

    useEffect(() => {
        if(!isViewTrackingEventSent && data?.order?.order_histories?.length > 0){
            sendAnalytics('Tracking - View tracking', data)
            setIsViewTrackingEventSent(true)
        }
    }, [data, isViewTrackingEventSent])

    const handleOrderHelp = () => {
        navigate(`/orders/${order_id}/help`, {state: {data: data}})
    }

    const handleIncidentReschedule = () => {
        navigate(`/orders/${order_id}/report-incident`, {state: {data: data, category: RESCHEDULE}})
    }

    const handleOrderDetails = () => {
        navigate(`/orders/${order_id}/details`, {state: {data: data}})
    }

    const redirectToViewShippingDetails = () => {
        navigate( `/orders/${order_id}/shipping-details`, { state: { data: data } })
    }

    const redirectToViewMissingReferencesShippingDetails = () => {
        navigate( `/orders/${order_id}/missing-references-shipping-details`, { state: { data: data } })
    }

    const buildOrderHistoryTitle = (new_status, metadata, isError, isAddressError) => {
        let title = null

        if (new_status === STATUS_SHIPMENT_WITH_INCIDENT && Object.values(DELIVERY_ATTEMPT_DESCRIPTIONS).includes(metadata?.description)) {
            title = i18n.t(`showOrder.timeline.shipping_status.delivery_attempt_failed`)
        }
        else if ([STATUS_HOLD, STATUS_BACKORDER].includes(new_status)){
            title = i18n.t(`showOrder.timeline.shipping_status.processing_delayed`)
        }
        else if (isError && (!isAddressError || !data?.order?.is_editable)){
            title = i18n.t(`showOrder.timeline.shipping_status.processing_delayed`)
        }
        else if (new_status === STATUS_SHIPPED && data?.order.shipping_method.delivery_type === "Pickup"){
            title = i18n.t(`showOrder.timeline.shipping_status.shipped_pickup`)
        }
        else {
            title = i18n.t(`showOrder.timeline.shipping_status.${new_status}`)
        }
        return title
    }

    const buildOrderHistoryDescription = (order, new_status, metadata, stepStatus, action, shippingAddress, isError, isAddressError) => {
        if(action === actions.EDIT){
            return i18n.t("showOrder.timeline.shipping_description.edit")
        }
        else if (isError && isAddressError && order?.is_editable){
            return(
                <div>
                    <span>{i18n.t("showOrder.timeline.shipping_description.error")}</span>
                    <div
                        className="text-blue-500 hover:underline hover:text-blue-400 cursor-pointer"
                        onClick={() => {navigate(`/orders/${order_id}/edit-address`, {state: {data: data, category: CHANGE_ADDRESS}})}}
                    >
                        {i18n.t("showOrder.edit_address")}
                    </div>
                </div>
            )
        }
        else if (action === actions.DUPLICATE) {
            const replacementOrder = order.replacement_orders.find(({id}) => metadata?.id === id)

            return(
                <div>
                    <span>{i18n.t("showOrder.timeline.shipping_description.replacement_order")}</span>
                    <a
                        className="text-blue-500 hover:underline hover:text-blue-400"
                        href={replacementOrder.cubbo_tracking_url}
                        rel='noreferrer'
                        target="_blank"
                    >
                        {i18n.t("showOrder.replacement_order_link")}
                    </a>
                </div>
            )
        }
        else if ([STATUS_HOLD, STATUS_BACKORDER].includes(new_status) || (isError && !isAddressError) || (isError && !order?.is_editable)){
            return i18n.t('showOrder.timeline.shipping_description.processing_delayed')
        }
        else if (new_status === STATUS_SHIPPED && order.shipping_method.delivery_type === "Pickup") {
            return i18n.t('showOrder.timeline.shipping_description.shipped_pickup')
        }
        else if (new_status === STATUS_SHIPPED) {
            return (<>
                {i18n.t(`showOrder.timeline.shipping_description.${new_status}`, 
                    {shipping_number: order.shipping_labels[0]?.shipping_number})}
                {order.shipping_labels[0]?.shipping_method === CARRIER_NAME_CUBBO && order?.shipping?.country?.toUpperCase() !== "BR"?
                    <div className="mt-0.5">{i18n.t('showOrder.view_tracking_cubbo')}</div>
                    :<div className="mt-0.5">
                        <a className="text-blue-500 hover:underline hover:text-blue-400"
                            href={order.shipping_labels[0]?.tracking_url}
                            rel='noreferrer' target="_blank">
                            {i18n.t('showOrder.view_tracking', {carrier_name: order.shipping_labels[0]?.shipping_method })}
                        </a> 
                    </div>
                }
            </>)
        }
        else if (new_status === STATUS_SHIPMENT_WITH_INCIDENT) {
            return (
                <div>{i18n.t(`showOrder.timeline.shipment_with_incident_descriptions.${TRACKING_DESCRIPTIONS.has(metadata?.description) ? metadata?.description : null || 'default'}`, 
                    {store: order.store.contact_email || order.store.name })}
                    {['not_at_home'].includes(metadata?.description) && stepStatus === TIMELINE_STATUS_CURRENT &&
                    <div className="mt-3">
                        {i18n.t(`showOrder.timeline.shipment_with_incident_descriptions.default`)}
                    </div>}
                    {['refused_payment', 'refused_package'].includes(metadata?.description) &&
                    <div className="mt-3">
                        {''}
                    </div>}
                    {['stolen', 'lost', 'damaged'].includes(metadata?.description) &&
                    <div className="mt-3">
                        {i18n.t(`showOrder.timeline.shipment_with_incident_descriptions.resolve_incident`, {store: order.store.contact_email || order.store.name })}
                    </div>}
                    {/* Complement address is currently disabled */}
                    {/* metadata?.description === 'missing_references' &&
                    <div className="mt-0.5 text-blue-500 hover:underline hover:text-blue-400 cursor-pointer"
                        onClick={ () => navigate(`/orders/${order_id}/report-incident`, 
                            {state: {data: data, category: MISSING_REFERENCES}})}>
                        {i18n.t(`showOrder.timeline.shipping_description.complement_address`)}
                    </div> */}
                    {proofOfDeliveryLink()}
                </div>
            )
        }
        else if (order.shipping_status === STATUS_SHIPMENT_TO_BE_SELF_COLLECTED && order.shipping_labels[0]?.shipping_method === DHL) {
            return i18n.t(`showOrder.timeline.shipping_description.${order.shipping_status}_carrier`, {carrier: order.shipping_labels[0]?.shipping_method})
        }
        else if (new_status === STATUS_SHIPMENT_IN_TRANSIT && order.is_delayed) {
            return i18n.t(`showOrder.timeline.shipping_description.${new_status}_delayed`)
        }
        else if (new_status == STATUS_PROCESSING) {
            return null
        }
        else if (new_status === STATUS_INITIAL && stepStatus === TIMELINE_STATUS_CURRENT && data?.order?.is_editable){
            return (
                <div className="flex flex-col gap-2">
                    <div>{i18n.t(`showOrder.timeline.shipping_description.${new_status}`, {order_number: order.order_number})}</div>
                    <div>{i18n.t("showOrder.check_address")}</div>

                    <div>
                        <div className="font-bold">{i18n.t("orderDetails.customer_data")}</div>
                        <div>{shippingAddress}</div>
                        <div
                            className="text-blue-500 hover:underline hover:text-blue-400 cursor-pointer mt-1"
                            onClick={() => {navigate(`/orders/${order_id}/edit-address`, {state: {data: data, category: CHANGE_ADDRESS}})}}
                        >
                            {i18n.t("showOrder.edit_address")}
                        </div>
                    </div>
                </div>
            )
        }
        else return <>
            {i18n.t(`showOrder.timeline.shipping_description.${new_status}`, {store: order.store.contact_email || order.store.name, order_number: order.order_number})}
            {new_status === STATUS_SHIPMENT_DELIVERED && proofOfDeliveryLink()}
        </>
    }

    const proofOfDeliveryLink = () => {
        if(data?.order?.proof_of_delivery) {
            return (
                <div className="mt-1.5 text-blue-500 hover:underline hover:text-blue-400 cursor-pointer"
                    onClick={ () => navigate(`/orders/${order_id}/proof-of-delivery`, 
                        {state: {data: data}})}>
                    {i18n.t('showOrder.view_proof_of_delivery')}
                </div>
            )
        }
    }

    // Filter and sort order history
    const orderHistory = useMemo(() => {
        const order = data?.order
        if(order === null || order === undefined) return null

        let shippingAddress = joinWords(order.shipping.address_1, order.shipping.address_2)

        if(order.shipping.state){ shippingAddress = `${shippingAddress}, ${order.shipping.state}` }
        if(order.shipping.city){ shippingAddress = `${shippingAddress}, ${order.shipping.city}` }
        if(order.shipping.zip_code){ shippingAddress = `${shippingAddress}, ${order.shipping.zip_code}` }

        if(storeWarehouseCountry === "BR" && shippingCountry === "BR"){
            if(order.shipping.neighborhood){ shippingAddress = `${shippingAddress}, ${order.shipping.neighborhood}` }
            if(order.shipping.number){ shippingAddress = `${shippingAddress}, ${order.shipping.number}` }
        }

        const checkItemIsError = (action, new_status) => {
            return action === actions.ORDER_STATUS_UPDATE && new_status === STATUS_ERROR ? true : false
        }

        const checkItemIsAddressError = (action, new_status, metadata) => {
            let isAddressError = false

            if(action === actions.ORDER_STATUS_UPDATE && new_status === STATUS_ERROR){
                isAddressError = metadata?.some(metadataError => (
                    order.shipping_address_error_codes?.some(errorCode => errorCode === metadataError?.code)
                ))
            }

            if(isAddressError && data?.order?.has_channel === false && data?.order?.shipping_method?.is_active === false){
                isAddressError = false
            }

            return isAddressError
        }

        const checkIsPendingDelayed = (action, new_status, metadata) => {
            let isPendingDelayed = false
            const pendingDelayedStatuses = [STATUS_HOLD, STATUS_BACKORDER]
            const isError = checkItemIsError(action, new_status)
            const isAddressError = checkItemIsAddressError(action, new_status, metadata)

            if(isError && !isAddressError){
                isPendingDelayed = true
            } else if(!isError && action === actions.ORDER_STATUS_UPDATE && pendingDelayedStatuses.includes(new_status)){
                isPendingDelayed = true
            }

            return isPendingDelayed
        }

        const checkIsReturnedOrder = (action, new_status) => {
            return action === actions.ORDER_STATUS_UPDATE && (new_status === STATUS_RETURNED || new_status === STATUS_RETURN_ARRIVED) ? true : false
        }

        // Sort order histories by reference_timestamp
        const sortedOrderHistory = order.order_histories?.sort((a, b) => new Date(a.reference_timestamp) - new Date(b.reference_timestamp))

        let previousItem = null

        // Filter order histories
        const filteredOrderHistory = sortedOrderHistory.filter((currentItem, index, items) => {
            let replacementOrder = null

            if(currentItem.action === actions.UNHOLD){
                return false
            }

            if(currentItem.action === actions.DUPLICATE){
                replacementOrder = order.replacement_orders?.find(({id}) => currentItem?.metadata?.id === id)
            }

            const isErrorItem = checkItemIsError(currentItem.action, currentItem.new_status)
            const isAddressError = checkItemIsAddressError(currentItem.action, currentItem.new_status, currentItem.metadata)
            const isPendingDelayed = checkIsPendingDelayed(currentItem.action, currentItem.new_status, currentItem.metadata)
            const isReturnedOrderItem = checkIsReturnedOrder(currentItem.action, currentItem.new_status)

            // Ignore address error items if the order is not in error
            if(order.status !== STATUS_ERROR && isAddressError){
                return false
            }
            // Ignore address error items if there is a newer error item
            if(order.status === STATUS_ERROR && isAddressError){
                const orderHasNewerError = items.find((checkingHistory, checkingHistoryIndex) => (
                    checkingHistoryIndex <= index ? false : checkItemIsError(checkingHistory.action, checkingHistory.new_status)
                ))
                return orderHasNewerError ? false : true
            }
            // Display the first consecutive pending delayed item
            if(isErrorItem || isPendingDelayed){
                const isPreviousError = previousItem === null ? false : checkItemIsError(previousItem?.action, previousItem?.new_status)
                const isPreviousDelayed = previousItem === null ? false : checkIsPendingDelayed(previousItem?.action, previousItem?.new_status, previousItem?.metadata)

                if(currentItem.action !== actions.EDIT){
                    previousItem = currentItem
                }

                return isPreviousError || isPreviousDelayed ? false : true
            }
            // Return the first returned order item
            if(isReturnedOrderItem){
                const hasPreviousReturnedOrderItem = items.some((compareItem, compareItemIndex) => (
                    compareItemIndex < index ? checkIsReturnedOrder(compareItem.action, compareItem.new_status) : false
                ))
                return hasPreviousReturnedOrderItem ? false : true
            }

            // Show only edit items with tracking app origin
            if(currentItem.action === actions.EDIT) {
                return currentItem.origin === origins.tracking_app ? true : false
            }
            // Remove unwanted actions
            if(timelineActionsToHide.includes(currentItem.action)) return false
            // Remove unwanted statuses
            if (currentItem.action !== actions.DUPLICATE && !timelineStatusesToShow.includes(currentItem.new_status)) return false
            if (!replacementOrder && currentItem.action === actions.DUPLICATE) return false
            // Only actions that are NOT TRACKING_UPDATE, ORDER_STATUS_UPDATE and REPLACEMENT ORDER can be consecutively repeated
            if(currentItem.action !== actions.TRACKING_UPDATE && currentItem.action !== actions.ORDER_STATUS_UPDATE && !replacementOrder) return true
            if(index === 0) return true

            // Show only pending status if previous status was unpaid
            if(currentItem.previous_status !== STATUS_UNPAID && currentItem.new_status === STATUS_PENDING){
                return false
            }

            // Filter unique items
            if(!replacementOrder){
                switch(currentItem.new_status) {
                    // Remove repeated SHIPMENT_COLLECTED statuses
                    case STATUS_SHIPMENT_COLLECTED:
                        return index === items.findIndex(compareItem => (
                            compareItem.new_status === currentItem.new_status && compareItem.action === currentItem.action
                        ))

                    // Allow SHIPMENT_WITH_INCIDENT status to be repeated if reference timestamp is distinct
                    case STATUS_SHIPMENT_WITH_INCIDENT:
                        return currentItem.action === actions.TRACKING_UPDATE && index === items.findIndex(compareItem => (
                            compareItem.new_status === currentItem.new_status && compareItem.reference_timestamp === currentItem.reference_timestamp
                        ))
                    default: break
                }
            }

            // Add currentItem if is diferent from previous one
            return currentItem.new_status !== items[index - 1].new_status || replacementOrder ? true : false
        })

        const lastStepHistory = filteredOrderHistory.findLast(({action}) => action !== actions.EDIT)

        const orderHistories = filteredOrderHistory.map(({new_status, action, reference_timestamp, metadata}, index) => {
            let stepStatus = TIMELINE_STATUS_COMPLETE
            let itemIsAction = false
            let itemAction = null

            if(action === actions.EDIT){
                itemIsAction = true
                itemAction = () => {
                    navigate( `/orders/${order_id}/edited-address-details`, { state: {
                        orderHistoryMetadata: metadata,
                        orderHistoryReferenceTimestamp: reference_timestamp,
                        orderData: data
                    } })
                }
                stepStatus = TIMELINE_STATUS_ADDRESS_CHANGED_FROM_TRACKING_APP
            } else if(reference_timestamp === lastStepHistory.reference_timestamp && action === lastStepHistory.action){
                stepStatus = TIMELINE_STATUS_CURRENT
            }

            let isError = false
            let isAddressError = false

            if(action === actions.ORDER_STATUS_UPDATE && new_status === STATUS_ERROR){
                isError = true
                isAddressError = checkItemIsAddressError(action, new_status, metadata)
            }

            const itemTitle = action === actions.EDIT ? null :
                action === actions.DUPLICATE ? i18n.t(`showOrder.timeline.shipping_status.replacement_order`) :
                buildOrderHistoryTitle(new_status, metadata, isError, isAddressError)

            const statusColor = action === actions.DUPLICATE ? 'green' :
                new_status === STATUS_SHIPMENT_IN_TRANSIT && order.is_delayed ? currentStatusColor[new_status + '_delayed'] :
                isError && (!isAddressError || !order?.is_editable) ? currentStatusColor[new_status + '_delayed'] :
                currentStatusColor[new_status]

            return {
                isAction: itemIsAction,
                action: itemAction,
                name: action === actions.DUPLICATE || action === actions.EDIT ? `${action}-${index}` : new_status,
                title: itemTitle,
                description: buildOrderHistoryDescription(order, new_status, metadata, stepStatus, action, shippingAddress, isError, isAddressError),
                reference_timestamp: reference_timestamp,
                status: stepStatus,
                currentStatusColor: statusColor 
            }
        })
        
        const orderHistory = orderHistories?.sort((a, b) => new Date(a.reference_timestamp) - new Date(b.reference_timestamp))

        // Add when order was created if it wasn't on order history
        // if (orderHistory.at(0)?.name !== STATUS_INITIAL) {
        //     orderHistory.unshift({
        //         action: actions.CREATE,
        //         name: STATUS_INITIAL,
        //         title: i18n.t(`showOrder.timeline.shipping_status.${STATUS_INITIAL}`),
        //         description: i18n.t(`showOrder.timeline.shipping_description.${STATUS_INITIAL}`, {order_number: order.order_number}),
        //         status: orderHistoriesLength === 0 ? TIMELINE_STATUS_CURRENT : TIMELINE_STATUS_COMPLETE,
        //         reference_timestamp: order.order_date,
        //         currentStatusColor: currentStatusColor[STATUS_INITIAL]
        //     })
        // }
        if (![STATUS_SHIPMENT_DELIVERED, STATUS_SHIPMENT_TO_BE_SELF_COLLECTED, STATUS_SHIPMENT_CANCELED, STATUS_RETURNING, STATUS_SHIPMENT_RETURNED].includes(orderHistory.at(-1)?.name) 
            || (orderHistory.at(-1)?.name === STATUS_SHIPMENT_WITH_INCIDENT 
                && !['refused_payment', 'refused_package', 'stolen', 'lost', 'damaged'].includes(orderHistory.at(-1)?.metadata?.description) )) {
            // Add delivered upcoming status if it wasn't delivered yet
            if (order.shipping_status !== STATUS_SHIPMENT_DELIVERED) {
                const addUpcomingProcessingEvent = orderHistory.find(({name}) => name === STATUS_PROCESSING) ? false : true
                const addUpcomingShippedEvent = orderHistory.find(({name}) => name === STATUS_SHIPPED) ? false : true
                const addUpcomingInTransitEvent = orderHistory.find(({name}) => (
                    [STATUS_SHIPMENT_IN_TRANSIT, STATUS_SHIPMENT_OUT_FOR_DELIVERY, STATUS_SHIPMENT_WITH_INCIDENT].includes(name)
                )) ? false : true

                let referenceUpcomingTimestamp = Date.now()

                const hasFinalShippingIncident = order.shipping_status === STATUS_SHIPMENT_WITH_INCIDENT && Object.values(FINAL_SHIPPING_INCIDENTS_STATUSES).includes(lastStepHistory?.metadata?.description) ? true : false

                const hasFinalDeliveryAttemptDescription = order.shipping_status === STATUS_SHIPMENT_WITH_INCIDENT && Object.values(FINAL_DELIVERY_ATTEMPT_DESCRIPTIONS).includes(lastStepHistory?.metadata?.description) ? true : false

                const willNotDeliverOrder = [...WILL_NOT_DELIVER_STATUSES, ...WILL_NOT_DELIVER_SHIPMENT_STATUSES].includes(lastStepHistory?.new_status) || hasFinalShippingIncident || hasFinalDeliveryAttemptDescription ? true : false

                const isPickup = order?.shipping_method?.delivery_type === "Pickup" ? true : false
                const isEntirelyDropshipping = order?.status === STATUS_ENTIRELY_DROPSHIPPING ? true : false

                const isCurrentLastDeliveryAttempt = order.has_reached_max_delivery_attempts && order.shipping_status === STATUS_SHIPMENT_WITH_INCIDENT && Object.values(DELIVERY_ATTEMPT_DESCRIPTIONS).includes(lastStepHistory?.metadata?.description)

                if (addUpcomingProcessingEvent && !willNotDeliverOrder && !isEntirelyDropshipping){
                    referenceUpcomingTimestamp ++

                    orderHistory.push({
                        name: STATUS_PROCESSING,
                        title: i18n.t(`showOrder.timeline.shipping_status.${STATUS_PROCESSING}`),
                        description: "",
                        status: TIMELINE_STATUS_UPCOMING,
                        reference_timestamp: referenceUpcomingTimestamp
                    })
                }
                if (addUpcomingShippedEvent && !willNotDeliverOrder && !isEntirelyDropshipping){
                    referenceUpcomingTimestamp ++

                    orderHistory.push({
                        name: STATUS_SHIPPED,
                        title: i18n.t(`showOrder.timeline.shipping_status.${STATUS_SHIPPED}`),
                        description: "",
                        status: TIMELINE_STATUS_UPCOMING,
                        reference_timestamp: referenceUpcomingTimestamp
                    })
                }
                if (addUpcomingInTransitEvent && !willNotDeliverOrder && !isPickup && !isEntirelyDropshipping) {
                    referenceUpcomingTimestamp ++

                    orderHistory.push({
                        name: STATUS_SHIPMENT_IN_TRANSIT,
                        title: i18n.t(`showOrder.timeline.shipping_status.${STATUS_SHIPMENT_IN_TRANSIT}`),
                        description: "",
                        status: TIMELINE_STATUS_UPCOMING,
                        reference_timestamp: referenceUpcomingTimestamp
                    })
                }

                if (isCurrentLastDeliveryAttempt && !willNotDeliverOrder && !isPickup && !isEntirelyDropshipping){
                    referenceUpcomingTimestamp ++
                    const contactEmail = data?.order.store.contact_email ? data?.order.store.contact_email : data?.order.store.name

                    orderHistory.push({
                        name: STATUS_SHIPMENT_IN_TRANSIT,
                        title: (
                            <div>
                                <span>{i18n.t('showOrder.canceled')}</span>
                            </div>
                        ),
                        description: (
                            <div className="flex flex-col gap-2">
                                <span>{i18n.t('showOrder.last_delivery_attempt_description')}</span>
                                <span>{i18n.t('showOrder.last_delivery_attempt_help', {store: contactEmail})}</span>
                            </div>
                        ),
                        status: TIMELINE_STATUS_UPCOMING,
                        reference_timestamp: referenceUpcomingTimestamp
                    })
                }

                if(!willNotDeliverOrder && !isPickup && !isEntirelyDropshipping && !isCurrentLastDeliveryAttempt){
                    referenceUpcomingTimestamp ++

                    const upcomingDeliveredItem = {
                        name: STATUS_SHIPMENT_DELIVERED,
                        status: TIMELINE_STATUS_UPCOMING,
                        reference_timestamp: referenceUpcomingTimestamp,
                        title: data?.order.estimated_time_arrival ? (
                            <div>
                                <span>{`${i18n.t('showOrder.estimated_delivery')} `}</span>
                                <FormattedRelativeTime
                                    forceDays={true}
                                    etaOffset={data?.order.shipping_coverage.eta_margin_of_error_in_hours}
                                    date={data?.order.estimated_time_arrival}
                                    time_zone={data?.order.store.warehouses[0]?.time_zone}
                                    className="lowercase"
                                />
                            </div>
                        ) : i18n.t(`showOrder.timeline.shipping_status.${STATUS_SHIPMENT_DELIVERED}`)
                    }

                    const changeAddressIncident = order?.shipping_incidents.find(incident => incident.category === CHANGE_ADDRESS)
                    const changeAddressIncidentDescription = changeAddressIncident ? JSON.parse(changeAddressIncident?.description) : null

                    const isCubboCarrierMX = order?.shipping_labels?.length > 0 && order.shipping_labels[0]?.shipping_method === CARRIER_NAME_CUBBO && shippingCountry === "MX" && storeWarehouseCountry === "MX"

                    const isRescheduleActive = (
                        (order?.shipping_coverage?.eta_margin_of_error_in_hours === 0 || order?.shipping_coverage?.eta_margin_of_error_in_hours === null) &&
                        !order?.shipping_incidents.some(incident => incident.category === RESCHEDULE) &&
                        isCubboCarrierMX &&
                        order?.status === "shipped" &&
                        order?.estimated_time_arrival &&
                        !order?.first_delivery_attempt_at &&
                        !order?.is_delayed
                    ) ? true : false;

                    //const isCreateAddressIncidentActive = isCubboCarrierMX && !changeAddressIncidentDescription && !order?.first_delivery_attempt_at ? true : false
                    const isCreateAddressIncidentActive = false

                    const isEditAddressActive = order?.is_editable && !(order.shipping_labels?.length > 0) && lastStepHistory?.new_status !== STATUS_INITIAL ? true : false

                    upcomingDeliveredItem.description = (
                        <div>
                            {isRescheduleActive && (
                                <div
                                    className="text-blue-500 hover:underline hover:text-blue-400 cursor-pointer mt-1"
                                    onClick={handleIncidentReschedule}
                                >
                                    {i18n.t("reportIncident.form.labels.RESCHEDULE.reschedule_delivery")}
                                </div>
                            )}

                            {(![STATUS_ENTIRELY_DROPSHIPPING].includes(order?.status) && filteredOrderHistory?.length > 1 && !data?.order?.is_click_and_collect) && (
                                <>
                                    <div className="font-bold mt-3">{i18n.t("orderDetails.customer_data")}</div>
                                    <div>
                                        {changeAddressIncidentDescription ?
                                            joinWords(changeAddressIncidentDescription?.shipping_address_1, changeAddressIncidentDescription?.shipping_address_2) : shippingAddress
                                        }
                                    </div>
                                </>
                            )}

                            {(isCreateAddressIncidentActive || isEditAddressActive) && (
                                <div
                                    className="text-blue-500 hover:underline hover:text-blue-400 cursor-pointer mt-1"
                                    onClick={() => {
                                        if(isCreateAddressIncidentActive){
                                            navigate(`/orders/${order_id}/report-incident`, {state: {data: data, category: CHANGE_ADDRESS}})
                                        }
                                        else if(isEditAddressActive){
                                            navigate(`/orders/${order_id}/edit-address`, {state: {data: data, category: CHANGE_ADDRESS}})
                                        }
                                    }}
                                >
                                    {i18n.t("showOrder.edit_address")}
                                </div>
                            )}
                        </div>
                    )

                    orderHistory.push(upcomingDeliveredItem)
                }
            }
            // // Add delivered complete status if it was delivered but it wasn't on order history
            // else {
            //     orderHistory.push({
            //         action: actions.ORDER_STATUS_UPDATE,
            //         name: STATUS_SHIPMENT_DELIVERED,
            //         title: i18n.t(`showOrder.timeline.shipping_status.${STATUS_SHIPMENT_DELIVERED}`),
            //         description: i18n.t(`showOrder.timeline.shipping_description.${STATUS_SHIPMENT_DELIVERED}`),
            //         status: TIMELINE_STATUS_CURRENT,
            //         reference_timestamp: Date.now(),
            //         currentStatusColor: currentStatusColor[STATUS_SHIPMENT_DELIVERED],
            //         hideDate: true
            //     })
            // }
        }

        // Convert shipping incidents into timeline format
        const orderShippingIncidents = []
        order.shipping_incidents.forEach((item) => {
            if (item.category !== CANCELED) {
                
                let data = {
                    name: item.category,
                    description: i18n.t(`showOrder.timeline.shipping_incident.description.${item.category}`, 
                        {carrier: order.shipping_labels[0]?.shipping_method, store: order.store.contact_email || order.store.name }),
                    reference_timestamp: item.created_at,
                    status: TIMELINE_STATUS_INCIDENT_CREATED
                }

                if(item.category === CHANGE_ADDRESS){
                    data.isAction = true
                    data.action = redirectToViewShippingDetails
                }

                if(item.category === MISSING_REFERENCES){
                    data.isAction = true
                    data.action = redirectToViewMissingReferencesShippingDetails
                }


                orderShippingIncidents.push(data)
            }
            // Add resolved incidents also (if exist)
            if (item.resolution && item.resolution !== REFUNDABLE && item.resolved_at) {
                orderShippingIncidents.push({
                    name: item.category,
                    description: i18n.t(`showOrder.timeline.shipping_incident.description.${item.resolution}`, 
                        {carrier: order.shipping_labels[0]?.shipping_method, store: order.store.contact_email || order.store.name }),
                    reference_timestamp: item.resolved_at,
                    status: TIMELINE_STATUS_INCIDENT_RESOLVED
                })
            }
        })

        const completeOrderHistory = [...orderHistories, ...orderShippingIncidents]
        const sortedCompleteOrderHistory = completeOrderHistory?.sort((a, b) => new Date(a.reference_timestamp) - new Date(b.reference_timestamp))
        
        return sortedCompleteOrderHistory

    }, [data, i18n.language])

    return (
        <>
        {!isPreviousData && isLoading ? (
            <><Loader className="bg-gray-50" show={true}></Loader></>
        ) : isError ? (
            <Navigate to={PATH_HOME} state={{ error: error.response?.data?.code}} replace={true} />
        ) : (
        <>  
            <div className="bg-gray-50 py-5 px-10 sm:max-w-[480px]">
                <LogoHeader src={data?.order.store.logo_file?.path && process.env.REACT_APP_PLATFORM_URL + data?.order.store.logo_file?.path}/>
            </div>
            <OrderHeader 
                order_details={i18n.t('showOrder.view_order_details')}
                estimated_delivery_label=
                    {
                        (data?.order?.shipping_incidents?.filter(incident => incident.category === RESCHEDULE).length) ?
                            i18n.t('showOrder.rescheduled_delivery')
                            :
                            data?.order.shipping_status !== STATUS_SHIPMENT_DELIVERED &&
                                i18n.t('showOrder.estimated_delivery')
                    }
                estimated_delivery={
                    <>
                    {data?.order.shipping_status === STATUS_SHIPMENT_DELIVERED ?
                        i18n.t('showOrder.delivered')
                    : ([STATUS_SHIPMENT_WITH_INCIDENT].includes(data?.order.shipping_status) && 
                        ['stolen', 'lost', 'damaged'].includes(data?.order.last_tracking_event?.metadata?.description)) || 
                      [STATUS_SHIPMENT_CANCELED, STATUS_SHIPMENT_RETURNED].includes(data?.order.shipping_status) ?
                        i18n.t('showOrder.canceled')
                    : data?.order.estimated_time_arrival  ?
                        <FormattedRelativeTime 
                            forceDays={true}
                            etaOffset={data?.order.shipping_coverage.eta_margin_of_error_in_hours}
                            date={data?.order.estimated_time_arrival}
                            time_zone={data?.order.store.warehouses[0]?.time_zone}
                        /> 
                    : data?.order?.shipping_method?.delivery_type === "Pickup" || [STATUS_ENTIRELY_DROPSHIPPING].includes(data?.order?.status) ?
                        i18n.t('showOrder.eta_not_available')
                    : i18n.t('showOrder.estimated_delivery_undefined')}
                    {data?.order.shipping_coverage.extended_zone &&
                        <span className="text-gray-700 font-normal text-sm inline-block mt-1.5">{i18n.t("showOrder.extended_zone")}
                        </span>
                    }

                    {data?.order?.is_click_and_collect === true && (
                        <div>
                            <div className="font-normal text-sm text-gray-700">
                                {i18n.t('showOrder.order_to_be_picked')}
                            </div>

                            {(data?.order?.click_and_collect_metadata?.description?.length > 0 || data?.order?.click_and_collect_metadata?.address?.length > 0) && (
                                <div className="my-4 flex flex-col gap-2 font-normal text-sm">

                                    {data?.order?.click_and_collect_metadata?.description?.length > 0 && (
                                        <div className="text-gray-500">
                                            <div className="text-gray-800 text-base">
                                                {i18n.t('showOrder.pick_up_location_name')}
                                            </div>
                                            {data?.order?.click_and_collect_metadata?.description}
                                        </div>
                                    )}

                                    {data?.order?.click_and_collect_metadata?.address?.length > 0 && (
                                        <div className="text-gray-500">
                                            <div className="text-gray-800 text-base">
                                                {i18n.t('showOrder.pick_up_location_address')}
                                            </div>
                                            {data?.order?.click_and_collect_metadata?.address}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </>}
                handleOrderDetails={handleOrderDetails}
            />
            <div className="flex flex-col space-y-5 py-4 px-10 bg-white">
                <Timeline 
                    steps={orderHistory}
                />
                <div className="mt-10 cursor-pointer text-blue-500 hover:text-blue-400" onClick={handleOrderHelp}>
                    {i18n.t('showOrder.help_with_order')}
                </div>
            </div>
        </>)}
        </>
    )
}