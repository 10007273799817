export const ChevronLeftIcon = ({ className = null }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className={className}>
        <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            d="M15.75 19.5L8.25 12l7.5-7.5" 
            strokeWidth="1.5" 
            stroke="currentColor"/>
        </svg>
    )
}
