export const DAMAGED = 'DAMAGED'
export const DELAYED = 'DELAYED'
export const CHANGE_ADDRESS = 'CHANGE_ADDRESS'
export const MISSING_REFERENCES = 'MISSING_REFERENCES'
export const MISSING_PRODUCT = 'MISSING_PRODUCT'
export const FALSE_DELIVERY = 'FALSE_DELIVERY'
export const EXPIRED = 'EXPIRED'
export const CANCELED = 'CANCELED'
export const WITHOUT_MOVEMENT = 'WITHOUT_MOVEMENT'
export const WRONG_PRODUCT = 'WRONG_PRODUCT'
export const EXTRA_PRODUCT = 'EXTRA_PRODUCT'
export const ORIGIN_RECEPIENT = 'RECIPIENT'
export const INCIDENT_RESOLVED = 'RESOLVED'
export const REFUNDABLE = 'REFUNDABLE'
export const RESCHEDULE = 'RESCHEDULE'
export const MORE_HELP = 'MORE_HELP'
export const RATE_CARRIER = 'RATE_CARRIER'