import { TrashIcon } from "@heroicons/react/24/outline";
import { ImageInputField } from "./ImageInputField";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const ImageUploadSection =  (
    {
        maxFiles,
        required,
        label,
        placeholder,
        images = [],
        onChange
    }) => {
    const [imageError, setImageError] = useState(null)
    const { i18n } = useTranslation()

    const filesChangeHandler = (files) => {
        if (files.length > 0 && files.length <= maxFiles - images.length) {
            setImageError(null)
            onChange([...images, ...files])
        }
        else if (files.length + images.length > maxFiles) {
            setImageError(`${i18n.t("imageInputField.max_images")} ${maxFiles} ${i18n.t("imageInputField.max_images_text")}`)
        }
    }

    const removeImage = (id) => {
        onChange(images.filter((img) => img.id !== id))
    }

    return (
        <div>
            <ImageInputField
                maxFiles={maxFiles}
                required={required}
                label={label}
                placeholder={placeholder}
                onChange={(files) => filesChangeHandler(files)}
                onError={(error, file, message) => setImageError(message)}
            />
            {imageError && (
                <div className="text-red-500 text-center italic mt-2">
                    {imageError}
                </div>
            )}
            <div className="flex flex-wrap justify-center">
                {images.map((image) => (
                    <div
                        className="w-32 h-32 m-2 bg-cover bg-no-repeat bg-center rounded shadow relative"
                        style={{
                            backgroundImage: `url('${image.preview?.url}')`,
                        }}
                        key={String(image.id)}
                    >
                        <div
                            onClick={() => removeImage(image.id)}
                            className="absolute -right-1 -top-1 rounded-full bg-white border border-gray-600 p-1 cursor-pointer"
                        >
                            <TrashIcon className="h-4 w-4 text-gray-600" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}