export const OrderHeader = ({
    order_details,
    estimated_delivery_label,
    estimated_delivery,
    handleOrderDetails = () => {}
}) => {
    return (
        <div className="flex flex-col px-10 py-5 space-y-3 bg-gray-50">
            <div>
                {estimated_delivery_label}
                <div className="text-xl font-bold">
                    {estimated_delivery}
                </div>
            </div>
            <div className="cursor-pointer text-blue-500 hover:text-blue-400"
                onClick={handleOrderDetails}> 
                {order_details}
            </div>
        </div>
    )
}