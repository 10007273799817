import { useTranslation } from "react-i18next"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { OrderSection } from "../components/OrderSection"
import { NavBar } from "../components/NavBar"
import { OrderLineCard } from "../components/OrderLineCard"
import { joinWords } from "../utils/StringUtils"
import { LogoHeader } from "../components/LogoHeader"
import { CHANGE_ADDRESS, INCIDENT_RESOLVED, MISSING_REFERENCES } from "../constants/shippingIncidents"
import { STATUS_SHIPMENT_DELIVERED, STATUS_SHIPMENT_RETURNED } from "../constants/orderStatuses"
import { PATH_HOME } from "../navigation/RouterPaths"

export const OrderDetailsContainer = () => {
    const { i18n } = useTranslation()
    const location = useLocation()
    const { order_id } = useParams();
    const navigate = useNavigate()
    const data = location?.state?.data;

    const shippingIncidentsDisabled = data?.order.shipping_incidents.reduce((acc, incident) => 
        incident.status !== INCIDENT_RESOLVED ? 
        {...acc, [incident.category]: true} : acc , {})

    const isChangeAddressIncidentPresent= (data?.order?.shipping_incidents.filter(incident => incident.category === CHANGE_ADDRESS))
    let changeAddressIncident = null
    if(isChangeAddressIncidentPresent.length) changeAddressIncident = JSON.parse(isChangeAddressIncidentPresent[0]?.description)

    const customerData = [
        isChangeAddressIncidentPresent.length ? 
            {
                key: "customer",
                description: <div>
                    <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                            {i18n.t("orderDetails.shipping.address")}</span>
                            {joinWords(changeAddressIncident?.shipping_address_1, changeAddressIncident?.shipping_address_2)}</p>
                    {data?.order.shipping?.country?.toUpperCase() === "BR" &&
                    <>
                    <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                            {i18n.t("orderDetails.shipping.number")}</span>{(data?.order.shipping?.number || "")}</p>
                    <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                            {i18n.t("orderDetails.shipping.neighborhood")}</span>{(data?.order.shipping?.neighborhood || "")}</p>
                    </>}
                    <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                            {i18n.t("orderDetails.shipping.state")}</span>{(changeAddressIncident?.state || "")}</p>
                    <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                            {i18n.t("orderDetails.shipping.city")}</span>{(changeAddressIncident?.city || "")}</p>
                    <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                            {i18n.t("orderDetails.shipping.zip_code")}</span>{(changeAddressIncident?.zip_code || "")}</p>
                    {/* ![STATUS_SHIPMENT_DELIVERED, STATUS_SHIPMENT_RETURNED].includes(data?.order.shipping_status)
                     && !shippingIncidentsDisabled?.MISSING_REFERENCES && <div className="mt-1 cursor-pointer w-fit text-blue-500 hover:text-blue-400"
                        onClick={ () => navigate(`/orders/${order_id}/report-incident`, {state: {data: data, category: MISSING_REFERENCES}})}>
                            {i18n.t(`helpOrder.shipping_incident.category.${MISSING_REFERENCES}`)}
            </div>*/}
                    </div>
            }
        :
            {
                key: "customer",
                description: <div>
                    <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                            {i18n.t("orderDetails.shipping.address")}</span>
                            {joinWords(data?.order.shipping.address_1, data?.order.shipping.address_2)}</p>
                    {data?.order.shipping?.country?.toUpperCase() === "BR" &&
                    <>
                    <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                            {i18n.t("orderDetails.shipping.number")}</span>{(data?.order.shipping?.number || "")}</p>
                    <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                            {i18n.t("orderDetails.shipping.neighborhood")}</span>{(data?.order.shipping?.neighborhood || "")}</p>
                    </>}
                    <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                            {i18n.t("orderDetails.shipping.state")}</span>{(data?.order.shipping?.state || "")}</p>
                    <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                            {i18n.t("orderDetails.shipping.city")}</span>{(data?.order.shipping?.city || "")}</p>
                    <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                            {i18n.t("orderDetails.shipping.zip_code")}</span>{(data?.order.shipping?.zip_code || "")}</p>
                    {/* ![STATUS_SHIPMENT_DELIVERED, STATUS_SHIPMENT_RETURNED].includes(data?.order.shipping_status)
                    && !shippingIncidentsDisabled?.MISSING_REFERENCES && <div className="mt-1 cursor-pointer w-fit text-blue-500 hover:text-blue-400"
                        onClick={ () => navigate(`/orders/${order_id}/report-incident`, {state: {data: data, category: MISSING_REFERENCES}})}>
                            {i18n.t(`helpOrder.shipping_incident.category.${MISSING_REFERENCES}`)}
            </div>*/}
                    </div>
            }
    ]

    const productsData = []
    data?.order.order_lines?.forEach((order_line) => {
        if (order_line.product.is_scannable) {
            productsData.push({
                key: order_line.id,
                description: 
                <OrderLineCard
                    thumbnail_path={order_line.product.product_image_path?.thumbnail_path}
                    img_path={order_line.product.product_image_path?.path} 
                    title={order_line.product.name} 
                    description={i18n.t('orderDetails.pieces', {quantity: order_line.quantity, plural: order_line.quantity > 1 ? 's' : ''})}/>
            })
        }
    })
    
    return (
        <>
        {data === undefined ? (
            <Navigate to={PATH_HOME} replace />
        ) : (
        <>
        <div className="bg-gray-50 py-5 px-10 sm:max-w-[480px]">
            <LogoHeader src={data?.order.store.logo_file?.path && process.env.REACT_APP_PLATFORM_URL + data?.order.store.logo_file?.path}/>
        </div>
        <div className="flex flex-col space-y-5 bg-gray-50">
            <NavBar title={i18n.t('showOrder.view_order_details')} className='mt-5'
                onClick={() => navigate(-1)}/>
            <div className="flex flex-col space-y-10 mx-5 px-5 py-5 bg-white rounded-xl ">
                <OrderSection icon={'customer_data'} title={isChangeAddressIncidentPresent ? i18n.t('orderDetails.updated_address') : i18n.t('orderDetails.customer_data')} fieldsData={customerData}/>
                <OrderSection icon={'shopping_bag'} title={i18n.t('orderDetails.order_products')} fieldsData={productsData}/>
            </div>
        </div>
        </>)}
        </>
    )
}