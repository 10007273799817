import { Icon } from "./Icons/Icon"

export const OrderSection = ({
    icon,
    customIcon,
    title,
    boldtitle=true,
    addMargin=true,
    addVerticalMargin=true,
    card = false,
    fieldsData
}) => {
    return (
        <div className="flex flex-col">
            <div className="font-semibold flex flex-row items-center"> 
                <Icon iconCode={icon} className="flex-shrink-0 h-5 w-5 text-gray-800 mr-2" />
                {customIcon ? customIcon : null}
                <div className={`${boldtitle ? 'font-semibold' : 'font-light'}`}>{title}</div>
            </div>
            {card ? 
                fieldsData.map(item => 
                <div key={item.key} 
                    className={`mt-2 bg-gray-100 p-2 rounded-md max-w-xs ${addMargin ? 'ml-7' : ''}`}>
                    {item.header}
                    <div className="text-sm text-gray-500"> 
                        {item.description && <div className="inline mr-1">{item.description}</div>}
                        {item.date && <div className="inline mr-1">{item.date}</div>}
                        {item.resolution && <div className="inline">{item.resolution}</div>}
                    </div>
                    <div className="text-sm text-gray-500">
                        {item.created_by}
                    </div>
                </div>)
            : fieldsData.map(item => 
                <div key={item.key} className={`${addVerticalMargin ? 'mt-2' : ''}  ${addMargin ? 'ml-7' : ''}`}>
                    {item.header}
                    <div className="mt-0.5 text-sm text-gray-500"> 
                        {item.description}
                    </div>
                </div>
            )}
        </div>
    )
}