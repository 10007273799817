export const TimelineItemHeader = ({
    title,
    description,
    titleClassName = null
}) => {
    return (
        <>
        <span className={`text-base ${titleClassName ? titleClassName : ''}`}>{title}</span>
        {description && <span className="text-sm text-gray-500">{description}</span>}
        </>
    )
}