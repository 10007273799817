export const PATH_HOME = '/'
export const PATH_SHOW_ORDER = '/orders/:order_id'
export const PATH_HELP_ORDER = '/orders/:order_id/help'
export const PATH_ORDER_DETAILS = '/orders/:order_id/details'
export const PATH_REPORT_INCIDENT = '/orders/:order_id/report-incident'
export const PATH_INCIDENT_CREATED = '/orders/:order_id/incident-created'
export const PATH_SHOW_PROOF_OF_DELIVERY = '/orders/:order_id/proof-of-delivery'
export const PATH_SHOW_SHIPPING_DETAILS = '/orders/:order_id/shipping-details'
export const PATH_SHOW_MISSING_REFERENCES_SHIPPING_DETAILS = '/orders/:order_id/missing-references-shipping-details'
export const PATH_CHANGE_ADDRESS = '/orders/:order_id/change-address'
export const PATH_CONFIRMATION = '/orders/:order_id/confirm'
export const PATH_AWAITING_ORDER = '/awaiting-order'
export const PATH_EDIT_ADDRESS = '/orders/:order_id/edit-address'
export const PATH_SHOW_EDITED_ADDRESS_DETAILS = '/orders/:order_id/edited-address-details'
