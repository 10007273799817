import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { Form } from "../components/Form"
import { CHANGE_ADDRESS } from "../constants/shippingIncidents"
import { NavBar } from "../components/NavBar"
import { LogoHeader } from "../components/LogoHeader"
import { editOrderSecurityValidation, editOrder } from "../services/orderServices"
import { sendAnalytics } from "../utils/analyticsUtils"

export const EditAddressContainer = () => {
    const { i18n } = useTranslation()
    const location = useLocation()
    const { order_id } = useParams()
    const [addressFieldValues, setAddressFieldValues] = useState({
        shipping_zip_code: "",
        shipping_state: "",
        shipping_city: "",
        shipping_address_1: "",
        shipping_address_2: "",
        shipping_neighborhood: "",
        shipping_number: ""
    })
    const [securityValidationFieldValues, setSecurityValidationFieldValues] = useState({email: "", tax_id: ""})
    const [editAuthorizationResult, setEditAuthorizationResult] = useState(null)
    const [editResult, setEditResult] = useState(null)
    const [waitingSecurityValidationResult, setWaitingSecurityValidationResult] = useState(false)
    const [waitingEditResult, setWaitingEditResult] = useState(false)
    const data = location?.state?.data
    const shippingCountry = data?.order?.shipping.country?.toUpperCase()
    const storeWarehouseCountry = data?.order?.store?.warehouses[0].country
    const navigate = useNavigate()

    const FIELD_ERROR_TYPES = {
        ERROR_MIN_LENGTH: "ERROR_MIN_LENGTH",
        ERROR_MAX_LENGTH: "ERROR_MAX_LENGTH",
        ERROR_INVALID_CONTENT: "ERROR_INVALID_CONTENT",
        ERROR_EXACT_LENGTH: "ERROR_EXACT_LENGTH"
    }

    const ALL_ADDRESS_FIELDS = {
        SHIPPING_STATE: "shipping_state",
        SHIPPING_CITY: "shipping_city",
        SHIPPING_ZIP_CODE: "shipping_zip_code",
        SHIPPING_ADDRESS_1: "shipping_address_1",
        SHIPPING_ADDRESS_2: "shipping_address_2",
        SHIPPING_NEIGHBORHOOD: "shipping_neighborhood",
        SHIPPING_NUMBER: "shipping_number"
    }

    const GENERAL_ADDRESS_FIELDS = {
        SHIPPING_STATE: "shipping_state",
        SHIPPING_CITY: "shipping_city",
        SHIPPING_ZIP_CODE: "shipping_zip_code",
        SHIPPING_ADDRESS_1: "shipping_address_1",
        SHIPPING_ADDRESS_2: "shipping_address_2"
    }

    const BR_ADDRESS_FIELDS = {
        SHIPPING_STATE: "shipping_state",
        SHIPPING_CITY: "shipping_city",
        SHIPPING_ZIP_CODE: "shipping_zip_code",
        SHIPPING_ADDRESS_1: "shipping_address_1",
        SHIPPING_ADDRESS_2: "shipping_address_2",
        SHIPPING_NEIGHBORHOOD: "shipping_neighborhood",
        SHIPPING_NUMBER: "shipping_number"
    }

    const ALL_SECURITY_VALIDATION_FIELDS = {
        EMAIL: "email",
        TAX_ID: "tax_id"
    }

    const GENERAL_SECURITY_VALIDATION_FIELDS = {
        EMAIL: "email"
    }

    const BR_SECURITY_VALIDATION_FIELDS = {
        EMAIL: "email",
        TAX_ID: "tax_id"
    }

    useEffect(() => {
        setAddressFieldValues({
            shipping_zip_code: data?.order?.shipping.zip_code,
            shipping_state: data?.order?.shipping.state,
            shipping_city: data?.order?.shipping.city,
            shipping_address_1: data?.order?.shipping.address_1,
            shipping_address_2: data?.order?.shipping.address_2,
            shipping_neighborhood: data?.order?.shipping.neighborhood,
            shipping_number: data?.order?.shipping.number,
        })
    }, [data])

    const getAddressFieldError = (addressFieldKey, addressFieldValue, minLength, maxLength) => {
        let addressFieldError = null
        let validationRegex = null
        let validatedAddressFieldValue = addressFieldValue

        switch(addressFieldKey){
            case ALL_ADDRESS_FIELDS.SHIPPING_ZIP_CODE:
                {
                    if(shippingCountry === "MX" || shippingCountry === "BR"){
                        validationRegex = new RegExp(`^[0-9]{${minLength},${maxLength}}$`)
                    } else {
                        validationRegex = new RegExp(`^[0-9]{1,${maxLength}}$`)
                    }

                    if(shippingCountry === "BR"){
                        validatedAddressFieldValue = addressFieldValue.replace("-", "")
                    }
                    break
                }
            case ALL_ADDRESS_FIELDS.SHIPPING_STATE:
                validationRegex = new RegExp(`^.{${minLength},${maxLength}}$`)
                break
            case ALL_ADDRESS_FIELDS.SHIPPING_CITY:
                validationRegex = new RegExp(`^.{${minLength},${maxLength}}$`)
                    break
            case ALL_ADDRESS_FIELDS.SHIPPING_ADDRESS_1:
                validationRegex = new RegExp(`^.{${minLength},${maxLength}}$`)
                break
            case ALL_ADDRESS_FIELDS.SHIPPING_ADDRESS_2:
                validationRegex = new RegExp(`^.{${minLength},${maxLength}}$`)
                break
            case ALL_ADDRESS_FIELDS.SHIPPING_NEIGHBORHOOD:
                validationRegex = new RegExp(`^.{${minLength},${maxLength}}$`)
                break
            case ALL_ADDRESS_FIELDS.SHIPPING_NUMBER:
                validationRegex = new RegExp(`^.{${minLength},${maxLength}}$`)
                break
            default:
                return null
        }

        if(minLength === maxLength && validatedAddressFieldValue.length !== minLength){
            addressFieldError = FIELD_ERROR_TYPES.ERROR_EXACT_LENGTH
        }
        else if((!validatedAddressFieldValue && minLength > 0) || validatedAddressFieldValue?.length < minLength){
            addressFieldError = FIELD_ERROR_TYPES.ERROR_MIN_LENGTH
        }
        else if(validatedAddressFieldValue?.length > maxLength){
            addressFieldError = FIELD_ERROR_TYPES.ERROR_MAX_LENGTH
        }
        else if(!validationRegex.test(validatedAddressFieldValue)){
            addressFieldError = FIELD_ERROR_TYPES.ERROR_INVALID_CONTENT
        }

        return addressFieldError
    }

    const getSecurityValidationFieldError = (fieldKey, fieldValue, minLength, maxLength) => {
        let validationFieldError = null
        let validationRegex = null

        switch(fieldKey){
            case ALL_SECURITY_VALIDATION_FIELDS.EMAIL:
                validationRegex = new RegExp(`^(.+@\\w+[.]{1}\\w{2}){1,${maxLength}}`)
                break
            case ALL_SECURITY_VALIDATION_FIELDS.TAX_ID:
                validationRegex = new RegExp(`^\\w{1,${maxLength}}`)
                break
            default:
                return null
        }

        if(!fieldValue || fieldValue.length < minLength){
            validationFieldError = FIELD_ERROR_TYPES.ERROR_MIN_LENGTH
        }
        else if(fieldValue?.length > maxLength){
            validationFieldError = FIELD_ERROR_TYPES.ERROR_MAX_LENGTH
        }
        else if(!validationRegex.test(fieldValue)){
            validationFieldError = FIELD_ERROR_TYPES.ERROR_INVALID_CONTENT
        }

        return validationFieldError
    }

    const buildAddressFieldsData = () => {
        const generatedFieldsData = [{
            key: "update_address",
            label: <div className="font-medium">{i18n.t(`reportIncident.form.labels.CHANGE_ADDRESS.update_address`)}</div>,
            type: 'paragraph'
        }]

        const addressFields = storeWarehouseCountry === "BR" && shippingCountry === "BR" ? BR_ADDRESS_FIELDS : GENERAL_ADDRESS_FIELDS

        Object.values(addressFields).forEach(addressField => {
            switch(addressField){
                case ALL_ADDRESS_FIELDS.SHIPPING_STATE:
                    {
                        const minLength = 1
                        const maxLength = 40
                        const errorCode = getAddressFieldError(ALL_ADDRESS_FIELDS.SHIPPING_STATE, addressFieldValues.shipping_state, minLength, maxLength)

                        generatedFieldsData.push({
                            key: ALL_ADDRESS_FIELDS.SHIPPING_STATE,
                            label: <div className="font-medium">{i18n.t("edit_address.fields.shipping_state")}</div>,
                            type: 'inputfield',
                            initialValue: addressFieldValues.shipping_state,
                            isInvalidData: errorCode ? true : false,
                            invalidDataMessage: i18n.t(`edit_address.errors.${errorCode}.shipping_state`).replace("[MAX_QUANTITY]", maxLength),
                            maxLength: maxLength,
                            minLength: minLength
                        })
                        break
                    }
                case ALL_ADDRESS_FIELDS.SHIPPING_CITY:
                    {
                        const minLength = 1
                        const maxLength = 40
                        const errorCode = getAddressFieldError(ALL_ADDRESS_FIELDS.SHIPPING_CITY, addressFieldValues.shipping_city, minLength, maxLength)

                        generatedFieldsData.push({
                            key: ALL_ADDRESS_FIELDS.SHIPPING_CITY,
                            label: <div className="font-medium">{i18n.t("edit_address.fields.shipping_city")}</div>,
                            type: 'inputfield',
                            initialValue: addressFieldValues.shipping_city,
                            isInvalidData: errorCode ? true : false,
                            invalidDataMessage: i18n.t(`edit_address.errors.${errorCode}.shipping_city`).replace("[MAX_QUANTITY]", maxLength),
                            maxLength: maxLength,
                            minLength: minLength
                        })
                        break
                    }
                case ALL_ADDRESS_FIELDS.SHIPPING_ZIP_CODE:
                    {
                        const minLength = shippingCountry === "MX" ? 4 : shippingCountry === "BR" ? 7 : 1
                        const maxLength = shippingCountry === "MX" ? 5 : shippingCountry === "BR" ? 8 : 10

                        const errorCode = getAddressFieldError(ALL_ADDRESS_FIELDS.SHIPPING_ZIP_CODE, addressFieldValues.shipping_zip_code, minLength, maxLength)

                        let invalidDataMessage = i18n.t(`edit_address.errors.${errorCode}.shipping_zip_code`).replace("[EXACT_QUANTITY]", minLength).replace("[MAX_QUANTITY]", maxLength).replace("[MIN_QUANTITY]", minLength)

                        if(errorCode === FIELD_ERROR_TYPES.ERROR_MIN_LENGTH && shippingCountry !== storeWarehouseCountry){
                            invalidDataMessage = i18n.t(`edit_address.errors.${errorCode}.shipping_zip_code_international`)
                        }

                        generatedFieldsData.push({
                            key: ALL_ADDRESS_FIELDS.SHIPPING_ZIP_CODE,
                            label: <div className="font-medium">{i18n.t("edit_address.fields.shipping_zip_code")}</div>,
                            type: 'inputfield',
                            initialValue: addressFieldValues.shipping_zip_code,
                            isInvalidData: errorCode ? true : false,
                            maxLength: shippingCountry === "BR" ? (maxLength + 1) : maxLength,
                            minLength: minLength,
                            invalidDataMessage: invalidDataMessage,
                            resizeEnabled: false
                        })
                        break
                    }
                case ALL_ADDRESS_FIELDS.SHIPPING_ADDRESS_1:
                    {
                        const minLength = 1
                        const maxLength = 90
                        const errorCode = getAddressFieldError(ALL_ADDRESS_FIELDS.SHIPPING_ADDRESS_1, addressFieldValues.shipping_address_1, minLength, maxLength)

                        generatedFieldsData.push({
                            key: ALL_ADDRESS_FIELDS.SHIPPING_ADDRESS_1,
                            label: <div className="font-medium">{i18n.t("edit_address.fields.shipping_address_1")}</div>,
                            category: CHANGE_ADDRESS,
                            type: 'inputfield',
                            initialValue: addressFieldValues.shipping_address_1,
                            isInvalidData: errorCode ? true : false,
                            invalidDataMessage: i18n.t(`edit_address.errors.${errorCode}.shipping_address_1`).replace("[MAX_QUANTITY]", maxLength),
                            maxLength: maxLength,
                            minLength: minLength
                        })
                        break
                    }
                case ALL_ADDRESS_FIELDS.SHIPPING_ADDRESS_2:
                    {
                        const minLength = 0
                        const maxLength = 50
                        const errorCode = getAddressFieldError(ALL_ADDRESS_FIELDS.SHIPPING_ADDRESS_2, addressFieldValues.shipping_address_2, minLength, maxLength)

                        generatedFieldsData.push({
                            key: ALL_ADDRESS_FIELDS.SHIPPING_ADDRESS_2,
                            label: <div className="font-medium">{i18n.t("edit_address.fields.shipping_address_2")}</div>,
                            category: CHANGE_ADDRESS,
                            type: 'inputfield',
                            initialValue: addressFieldValues.shipping_address_2,
                            isInvalidData: errorCode ? true : false,
                            invalidDataMessage: i18n.t(`edit_address.errors.${errorCode}.shipping_address_2`).replace("[MAX_QUANTITY]", maxLength),
                            maxLength: maxLength,
                            minLength: minLength
                        })
                        break
                    }
                case ALL_ADDRESS_FIELDS.SHIPPING_NEIGHBORHOOD:
                    {
                        const minLength = 1
                        const maxLength = 30
                        const errorCode = getAddressFieldError(ALL_ADDRESS_FIELDS.SHIPPING_NEIGHBORHOOD, addressFieldValues.shipping_neighborhood, minLength, maxLength)

                        generatedFieldsData.push({
                            key: ALL_ADDRESS_FIELDS.SHIPPING_NEIGHBORHOOD,
                            label: <div className="font-medium">{i18n.t("edit_address.fields.shipping_neighborhood")}</div>,
                            category: CHANGE_ADDRESS,
                            type: 'inputfield',
                            initialValue: addressFieldValues.shipping_neighborhood,
                            isInvalidData: errorCode ? true : false,
                            invalidDataMessage: i18n.t(`edit_address.errors.${errorCode}.shipping_neighborhood`).replace("[MAX_QUANTITY]", maxLength),
                            maxLength: maxLength,
                            minLength: minLength
                        })
                        break
                    }
                case ALL_ADDRESS_FIELDS.SHIPPING_NUMBER:
                    {
                        const minLength = 1
                        const maxLength = 30
                        const errorCode = getAddressFieldError(ALL_ADDRESS_FIELDS.SHIPPING_NUMBER, addressFieldValues.shipping_number, minLength, maxLength)

                        generatedFieldsData.push({
                            key: ALL_ADDRESS_FIELDS.SHIPPING_NUMBER,
                            label: <div className="font-medium">{i18n.t("edit_address.fields.shipping_number")}</div>,
                            category: CHANGE_ADDRESS,
                            type: 'inputfield',
                            initialValue: addressFieldValues.shipping_number,
                            isInvalidData: errorCode ? true : false,
                            invalidDataMessage: i18n.t(`edit_address.errors.${errorCode}.shipping_number`).replace("[MAX_QUANTITY]", maxLength),
                            maxLength: maxLength,
                            minLength: minLength
                        })
                        break
                    }
                default:
                    break
            }

        })

        return generatedFieldsData
    }

    const buildSecurityValidationFieldsData = () => {
        const generatedFieldsData = []
        const validationFields = storeWarehouseCountry === "BR" && shippingCountry === "BR" ? BR_SECURITY_VALIDATION_FIELDS : GENERAL_SECURITY_VALIDATION_FIELDS

        Object.values(validationFields).forEach(addressField => {
            switch(addressField){
                case ALL_SECURITY_VALIDATION_FIELDS.EMAIL:
                {
                    const minLength = 1
                    const maxLength = 50
                    const errorCode = getSecurityValidationFieldError(ALL_SECURITY_VALIDATION_FIELDS.EMAIL, securityValidationFieldValues.email, minLength, maxLength)

                    generatedFieldsData.push({
                        key: ALL_SECURITY_VALIDATION_FIELDS.EMAIL,
                        label: <div className="font-medium">{i18n.t("edit_address.edit_authorization.fields.email")}</div>,
                        type: 'inputfield',
                        initialValue: securityValidationFieldValues.email,
                        isInvalidData: errorCode ? true : false,
                        invalidDataMessage: i18n.t(`edit_address.edit_authorization.errors.${errorCode}.email`),
                        resizeEnabled: false,
                        maxLength: maxLength,
                        minLength: minLength
                    })
                    break
                }
                case ALL_SECURITY_VALIDATION_FIELDS.TAX_ID:
                {
                    const minLength = 1
                    const maxLength = 50
                    const errorCode = getSecurityValidationFieldError(ALL_SECURITY_VALIDATION_FIELDS.TAX_ID, securityValidationFieldValues.tax_id, minLength, maxLength)

                    generatedFieldsData.push({
                        key: ALL_SECURITY_VALIDATION_FIELDS.TAX_ID,
                        label: <div className="font-medium">{i18n.t("edit_address.edit_authorization.fields.tax_id")}</div>,
                        type: 'inputfield',
                        initialValue: securityValidationFieldValues.tax_id,
                        isInvalidData: errorCode ? true : false,
                        invalidDataMessage: i18n.t(`edit_address.edit_authorization.errors.${errorCode}.tax_id`),
                        resizeEnabled: false,
                        maxLength: maxLength,
                        minLength: minLength
                    })
                    break
                }
                default:
                    break
            }
        })

        return generatedFieldsData
    }

    const handleOnSubmitAddress = async () => {
        setWaitingEditResult(true)
        setEditResult(null)

        sendAnalytics('Tracking - Change address', data)

        let redirectToPreviousPage = false

        const dataContent = {
            shipping_zip_code: addressFieldValues.shipping_zip_code,
            shipping_state: addressFieldValues.shipping_state,
            shipping_city: addressFieldValues.shipping_city,
            shipping_address_1: addressFieldValues.shipping_address_1,
            shipping_address_2: addressFieldValues.shipping_address_2,
            shipping_neighborhood: addressFieldValues.shipping_neighborhood,
            shipping_number: addressFieldValues.shipping_number,
            dry_run: true,
            security_validation: {
                shipping_email_response: securityValidationFieldValues.email
            }
        }

        if(storeWarehouseCountry === "BR" && shippingCountry === "BR"){
            dataContent.security_validation.billing_tax_id_response = securityValidationFieldValues.tax_id
        }

        try {
            // Run as dry run
            dataContent.dry_run = true
            await editOrder(order_id, dataContent)

            // Run as edit
            dataContent.dry_run = false
            await editOrder(order_id, dataContent)

            redirectToPreviousPage = true
            setEditResult(true)
        }
        catch(error){
            redirectToPreviousPage = false
            setEditResult(false)
        }

        setWaitingEditResult(false)

        if(redirectToPreviousPage){
            navigate(-1)
        }
    }

    const handleOnAddressChange = (addressFieldKey, addressFieldNewValue) => {
        if(addressFieldValues.hasOwnProperty(addressFieldKey)){
            const newAddressFieldValues = {...addressFieldValues}
            newAddressFieldValues[addressFieldKey] = addressFieldNewValue
            setAddressFieldValues(newAddressFieldValues)
        }
    }

    const handleOnSubmitSecurityValidation = async () => {
        setWaitingSecurityValidationResult(true)
        setEditAuthorizationResult(null)

        const dataContent = {shipping_email_response: securityValidationFieldValues.email}

        if(storeWarehouseCountry === "BR" && shippingCountry === "BR"){
            dataContent.billing_tax_id_response = securityValidationFieldValues.tax_id
        }

        let validationResult = false

        try {
            const res = await editOrderSecurityValidation(order_id, dataContent)
            validationResult = res.validation_result
        }
        catch(error){
            validationResult = false
        }

        setEditAuthorizationResult(validationResult)
        setWaitingSecurityValidationResult(false)
    }

    const handleOnSecurityValidationChange = (validationFieldKey, validationFieldNewValue) => {
        if(securityValidationFieldValues.hasOwnProperty(validationFieldKey)){
            const newSecurityValidationFieldValues = {...securityValidationFieldValues}
            newSecurityValidationFieldValues[validationFieldKey] = validationFieldNewValue
            setSecurityValidationFieldValues(newSecurityValidationFieldValues)
        }
    }

    const addressFieldsData = buildAddressFieldsData()
    const securityValidationFieldsData = buildSecurityValidationFieldsData()

    return (
        <div>
            <div className="bg-gray-50 py-5 px-10 sm:max-w-[480px]">
                <LogoHeader src={data?.order.store.logo_file?.path && process.env.REACT_APP_PLATFORM_URL + data?.order.store.logo_file?.path} />
            </div>

            <NavBar className='mt-5'
                title={i18n.t('showOrder.help_with_order')}
                description={i18n.t('edit_address.title_description')}
                onClick={() => navigate(-1)}
            />

            <div className="px-10">
                {
                    editAuthorizationResult !== true ? (
                        <div className="flex flex-col gap-2 mt-5">
                            <div className="flex flex-col gap-1">
                                <div className="font-bold">
                                    {i18n.t("edit_address.edit_authorization.title")}
                                </div>
                                <div>
                                    {i18n.t("edit_address.edit_authorization.description")}
                                </div>
                            </div>

                            <Form
                                fieldsData={securityValidationFieldsData}
                                submitData={{label: waitingSecurityValidationResult ? i18n.t('edit_address.edit_authorization.validating_submit') : i18n.t('edit_address.edit_authorization.submit_button')}}
                                onSubmit={handleOnSubmitSecurityValidation}
                                errors={{serverError: editAuthorizationResult === false ? i18n.t('edit_address.edit_authorization.edit_unauthorized') : null}}
                                handleInputUpdate={handleOnSecurityValidationChange}
                                disableSubmit={waitingSecurityValidationResult || securityValidationFieldsData.some(securityValidationFieldData => (
                                    securityValidationFieldData.isInvalidData === true
                                ))}
                                inputs={null}
                            />
                        </div>
                    ) : (
                        <Form
                            fieldsData={addressFieldsData}
                            submitData={{label: waitingEditResult ? i18n.t('edit_address.submit_editing_order') : i18n.t('edit_address.submit_address_button')}}
                            onSubmit={handleOnSubmitAddress}
                            errors={{serverError: editResult === false ? i18n.t('edit_address.no_coverage') : null }}
                            handleInputUpdate={handleOnAddressChange}
                            disableSubmit={waitingEditResult || addressFieldsData.some(addressFieldData => addressFieldData.isInvalidData === true)}
                            inputs={null}
                        />
                    )
                }
            </div>
        </div>
    )
}
