export const es = {
    showOrder : {
        title: "¡Hola!",
        order_number : "Pedido #{{order_number}} de {{store}}",
        estimated_delivery: "Entrega estimada:",
        rescheduled_delivery: "Reprogramaste la entrega para el:",
        estimated_delivery_undefined: "Se mostrará pronto",
        eta_not_available: "No disponible",
        extended_zone: "El pedido tiene un tiempo de entrega mayor a lo habitual ya que tiene como destino una zona remota",
        delivered: "¡Ha sido entregado!",
        canceled: "No se entregará",
        view_order_details: "Ver información del pedido",
        shipping_method_label : "Paquetería",
        shipping_status_label: "Estado del envío",
        view_tracking: "Rastrear desde {{carrier_name}}",
        view_tracking_cubbo: "Paquetería: Cubbo SD",
        shipping_incident_label : "Eventos del pedido",
        help_with_order: "Necesito ayuda con mi pedido",
        view_proof_of_delivery: "Ver evidencias de entrega",
        address_references: "Complemento de dirección",
        address_update: "Cambio en dirección de entrega",
        watch: "Ver cambios",
        replacement_order_link: "aquí",
        edit_address: "Cambiar dirección",
        check_address: "Revisa que la dirección esté completa y sea correcta",
        order_to_be_picked: "Este pedido se entregará en un punto físico para su recolección",
        pick_up_location_name: "Nombre del establecimiento",
        pick_up_location_address: "Dirección",
        last_delivery_attempt_description: "Tras intentos de entrega fallidos, el paquete retornará al almacén.",
        last_delivery_attempt_help: "Si requieres alguna aclaración escribe a {{store}}",
        timeline: {
            shipping_status : {
                initial: "Compra realizada",
                processing: "Empacando tu pedido",
                shipped: "Empacado, esperando recolección",
                shipment_in_transit: "En tránsito",
                shipment_delivered: "Entregado",
                // waiting_pick_up: "Empacado, esperando recolección",
                // shipment_created: "Empacado, esperando recolección",
                shipment_collected: 'Recolectado por paquetería',
                shipment_with_incident: 'Incidente en la entrega',
                shipment_canceled: 'Entrega Cancelada',
                shipment_returned: 'Retornando al almacén',
                // returned: 'Entrega Cancelada',
                // returned_by_client: 'Devolución solicitada',
                returning: 'Retornando al almacén',
                shipment_to_be_self_collected: 'Recolectar en oficina',
                shipment_estimated_arrival: 'Entrega estimada',
                shipment_out_for_delivery: 'En camino a ser entregado',
                delivery_attempt_failed: "Intento de entrega fallido",
                replacement_order: "Pedido reenviado",
                error: "Falta información",
                processing_delayed: "Preparando tu pedido",
                unpaid: "Pendiente de pago",
                returned: "Retornó al almacén",
                return_arrived: "Retornó al almacén",
                shipped_pickup: "Enviado con paquetería externa",
                pending: "Pago recibido",
                canceled: "Pedido cancelado",
                interrupted: "Pedido cancelado",
                entirely_dropshipping: "Fulfilleado Externamente"
            },
            shipping_description : {
                initial: "Noº de pedido: {{order_number}}",
                shipped: "Noº de rastreo: {{shipping_number}}",
                shipment_in_transit: 'Trasladándose del almacén al lugar de entrega',
                shipment_in_transit_delayed: '',
                shipment_delivered: '¡Disfruta de tu compra!',
                // waiting_pick_up: "La paquetería lo recolectará en las próximas horas",
                // shipment_created: "La paquetería lo recolectará en las próximas horas",
                shipment_collected: '',
                shipment_out_for_delivery: 'El repartidor está próximo a entregar',
                shipment_with_incident: 'Ha pasado un incidente durante la entrega',
                shipment_canceled: 'Para más información escribe a {{store}}',
                shipment_returned: 'Tras intentos de entrega fallidos',
                // returned: 'El pedido será retornado al almacén',
                returning: 'Por devolución solicitada',
                shipment_to_be_self_collected: 'Ubica la oficina asignada dando click en el numero de rastreo',
                shipment_to_be_self_collected_carrier: 'Comunícate con {{carrier}} para localizar la oficina asignada para la recolección',
                complement_address: 'Complementa tu dirección',
                replacement_order: "Dale seguimiento al nuevo pedido desde ",
                error: "La dirección de entrega está incompleta",
                edit: "Dirección actualizada",
                processing_delayed: "Es posible que el pedido tarde un poco más de lo habitual en ser procesado",
                unpaid: "Es necesario el pago pendiente para procesar el pedido",
                returned: "",
                return_arrived: "",
                shipped_pickup: "Consulta con la paquetería el seguimiento de tu pedido",
                pending: "Se empacará pronto",
                canceled: "En caso de requerir una aclaración escribe directo a {{store}}",
                interrupted: "En caso de requerir una aclaración escribe directo a {{store}}",
                entirely_dropshipping: "Tu pedido será enviado por otro medio, en caso de requerir una aclaración escribe directo a {{store}}"
            },
            shipment_with_incident_descriptions: {
                default: '',
                missing_references: 'Por falta de referencias',
                not_at_home: 'Por comprador ausente',
                refused_payment: 'El comprador se rehusó a pagar',
                refused_package: 'El comprador rechazó el paquete',
                stolen: 'Por robo al repartidor, no se podrá entregar en la fecha estimada',
                lost: 'Por extravío del pedido, no se podrá entregar en la fecha estimada',
                damaged: 'Por daño severo al pedido, no se podrá entregar en la fecha estimada',
                //returned_by_carrier: 'Tras intentos de entrega no exitosos, el pedido retornará al almacen',
                //returned_by_client: 'El pedido retornará al almacén por una devolución solicitada',
                resolve_incident: 'Escribe a {{store}} para solucionar este incidente',
                second_try: "",
                third_try: ""
            },
            shipping_incident : {
                description: {
                    CANCELED: "Entrega cancelada",
                    DELAYED: "Se solicitó apoyo a {{carrier}} para apresurar la entrega",
                    DAMAGED: 'Comienza investigación por productos dañados. Escribe a {{store}} para conciliar una solución al caso',
                    MISSING_PRODUCT: 'Comienza investigación por productos faltantes.  Escribe a {{store}} para conciliar una solución al caso',
                    FALSE_DELIVERY: 'Comienza investigación por entrega incorrecta. Escribe a {{store}} para conciliar una solución al caso',
                    MISSING_REFERENCES: 'Complemento de dirección compartido con {{carrier}}',
                    RESCHEDULE: 'Has reprogramado tu entrega',
                    STOLEN: '{{carrier}} reportó el pedido como robado',
                    LOST: '{{carrier}} reportó el pedido como extraviado',
                    RETURNED: '{{carrier}} reportó que el pedido está retornando al almacén',
                    DELIVERED: '{{carrier}} reporta el pedido como entregado',
                    NON_REFUNDABLE_DAMAGED: '{{carrier}} reporta el pedido con daño severo',
                    REFUNDABLE: 'No será posible entregar este pedido. Escribe a {{store}}.',
                    CHANGE_ADDRESS: 'Dirección actualizada compartida con {{carrier}}'
                },
            }
        }
    },
    proofOfDelivery: {
        title: "Evidencia de entrega",
        received_by: "Recibido por",
        unavailable: "La evidencia de entrega no está disponible aún",
        proof: "Comprobante",
    },
    orderDetails: {
        shipment: "Envío",
        shipping_number: "No. de rastreo",
        customer_data: "Dirección de entrega",
        updated_address: 'Dirección de entrega actualizada',
        order_products: "Productos del pedido",
        previous_address: "Dirección anterior",
        updated_address: "Dirección actualizada",
        updated_reference: "Complemento de dirección enviado",
        pieces : "{{quantity}} pieza{{plural}}",
        shipping : {
            address: "Dirección: ",
            state: "Estado: ",
            city: "Ciudad: ",
            number: "Número: ",
            neighborhood: "Barrio: ",
            zip_code: "CP: ",
        }
    },
    findOrder : {
        find_my_order: "Rastrea mi pedido",
        help_with_order: "¿Necesitas ayuda con tu pedido?",
        form: {
            shipping_number: "Número de rastreo",
            email: "Correo electrónico del comprador",
            errors : {
                blank: "Introduce un número de rastreo y un correo electrónico válido.",
                INVALID_EMAIL: "El correo electrónico no coincide con el número de rastreo.",
                ERR_NETWORK: "No se puede conectar al servidor.",
                INVALID_SHIPPING_NUMBER: 'Número de rastreo no encontrado.'
            }
        }
    },
    helpOrder : {
        created_by: 'Reportado por {{user}}',
        you: 'ti',
        store_partner: 'aliado logístico',
        specialized_attention: 'Parece que tu pedido ha tenido un problema',
        specialized_attention_description_1: 'Por favor escríbenos a ',
        specialized_attention_description_2: ' para darte atención especializada por uno de nuestros agentes',
        shipping_incident: {
            status_label: 'Estado',
            status : {
                PENDING: "Esperando resolución de {{carrier}}",
                IN_REVIEW: "En revisión por {{carrier}}",
                RESOLVED: "Resuelto por {{carrier}}",
            },
            category : {
                CANCELED: "Entrega cancelada",
                DELAYED: "Pedido demorado",
                DAMAGED: 'Pedido dañado',
                MISSING_PRODUCT: 'Faltan productos',
                FALSE_DELIVERY: 'Entrega incorrecta',
                MISSING_REFERENCES: 'Complementar dirección',
                RESCHEDULE: 'Reprogramar fecha de entrega',
                MORE_HELP: 'Políticas de devolución y cancelación',
                CHANGE_ADDRESS: 'Cambiar dirección de entrega'
            },
            resolution: {
                STOLEN: '{{carrier}} reporta el pedido como robado',
                LOST: '{{carrier}} reporta el pedido como extraviado',
                RETURNED: '{{carrier}} retornó el pedido al almacén',
                DELIVERED: '{{carrier}} ha entregado el pedido',
                NON_REFUNDABLE_DAMAGED: '{{carrier}} reporta el pedido con daño severo',
                REFUNDABLE: 'No será posible entregar este pedido. Escribe a {{store}}.'
            },
            options : {
                CHANGE_ADDRESS: 'Quiero cambiar el lugar de entrega',
                CHANGE_ADDRESS_CUBBO: 'Cambiar la dirección de entrega',
                DELAYED: "Mi pedido esta demorado",
                DELAYED_CUBBO: "Mi pedido esta demorado",
                DAMAGED: 'Los productos llegaron dañados',
                DAMAGED_CUBBO: 'Los productos llegaron dañados',
                MISSING_PRODUCT: 'Faltan productos en mi pedido',
                MISSING_PRODUCT_CUBBO: 'Faltan productos en mi pedido',
                FALSE_DELIVERY: 'No he recibido mi pedido y aparece como entregado',
                FALSE_DELIVERY_CUBBO: 'No he recibido mi pedido y aparece como entregado',
                WITHOUT_MOVEMENT: 'Mi pedido no tiene movimiento',
                WITHOUT_MOVEMENT_CUBBO: 'Mi pedido no tiene movimiento',
                MISSING_REFERENCES: 'Añadir referencias para la entrega',
                MISSING_REFERENCES_CUBBO: 'Añadir referencias para la entrega',
                EXPIRED: 'El producto llegó expirado o no funciona',
                EXPIRED_CUBBO: 'El producto llegó expirado o no funciona',
                WRONG_PRODUCT: 'Recibí un pedido incorrecto, ninguno de los productos recibidos los ordené',
                WRONG_PRODUCT_CUBBO: 'Recibí un pedido incorrecto, ninguno de los productos recibidos los ordené',
                EXTRA_PRODUCT: 'Recibí productos adicionales que no ordené',
                EXTRA_PRODUCT_CUBBO: 'Recibí productos adicionales que no ordené',
                RESCHEDULE: 'Reprogramar fecha de entrega',
                RESCHEDULE_CUBBO: 'Reprogramar fecha de entrega',
                MORE_HELP: 'Políticas de devolución y cancelación',
                MORE_HELP_CUBBO: 'Políticas de devolución y cancelación',
                RATE_CARRIER: 'Déjanos tus sugerencias',
                RATE_CARRIER_CUBBO: 'Déjanos tus sugerencias'
            },
            errors : {
                default: "Este incidente ya fue reportado a {{carrier}}. Si después de 6 días hábiles aún no se ha resuelto, escribe a {{store}} para dar solución a tu caso lo antes posible.",
                MISSING_REFERENCES: 'Ya has complementado tu dirección previamente.',
                CHANGE_ADDRESS: 'Ya has cambiado tu dirección previamente.',
                RESCHEDULE: 'Ya has reprogramado la entrega previamente.',
                NO_ETA: 'No es posible reprogramar tu pedido ya que todavía no cuenta con fecha de entrega estimada',
                FIRST_DELIVERY_ATTEMPT: 'Ya ha habido un intento de entrega por lo que no es posible reprogramar tu pedido'
            }
        },
        need_help : '¿Necesitas de ayuda con algo más?',
        need_help_description: 'Para solicitar un cambio, devolución o cancelación del pedido, consulta las políticas en el sitio web de {{store}}',
        need_help_description_email: 'En caso de requerir apoyo adicional, escribe directo a '
    },
    reportIncident : {
        create: "Enviar",
        form: {
            labels: {
                description: 'Comentarios adicionales',
                package_images: 'Fotos de los 6 lados del paquete',
                label_images: 'Foto legible de la guía pegada al paquete',
                damaged_product_images: 'Foto de cada uno de los productos dañados',
                damaged_zone_images: 'Foto a detalle de la zona violada o dañada',
                sorry_for_incovenience: 'Lamentamos el incoveniente',
                dont_worry: 'No te preocupes, aún quedan {{days}} días para que el pedido llegue a su destino y sea entregado a tiempo.',
                dont_worry_time: 'No te preocupes, aún queda tiempo para que el pedido llegue a su destino y sea entregado a tiempo.',
                tracking_website: 'Si requieres más detalles sobre el envío, puedes consultar la página de rastreo de {{carrier}} ',
                tracking_cubbo: 'No te preocupes, Cubbo entregará tu pedido en máximo 48 horas.',
                EXTRA_PRODUCT:'Por favor, escribe directamente a {{store}} para gestionar el retorno de los productos enviados por equivocación.',
                thanks_for_reporting: '¡Gracias por reportarlo!',
                here: 'aquí',
                MISSING_REFERENCES : {
                    intro: 'Considera que los datos actuales no se pueden cambiar ya que, el pedido ya está en movimiento. Si lo que requieres es un cambio de dirección, contacta a ',
                    intro_cubbo: 'Para facilitar la entrega comparte al repartidor número interior, descripción de la fachada, teléfono de quien recibe, etc...',
                    current_address: 'Dirección actual de entrega',
                    additional_address: 'Datos de entrega complementarios',
                    additional_address_cubbo: 'Referencias para la entrega',
                    change_addres: '¿Tu dirección es incorrecta? Corrígela '
                },
                CHANGE_ADDRESS : {
                    order_in_movement: 'Tu pedido ya se encuentra en movimiento, por lo que no será posible cambiar la dirección de entrega.',
                    order_in_movement_cubbo: 'Tu pedido ya se encuentra en movimiento, por lo que algunos datos no se podrán modificar.',
                    complement_address: 'Puedes complementar tu dirección sin modificar los datos actuales ',
                    contact_email: 'Si la dirección que proporcionaste es incorrecta, escribe directamente a ',
                    update_address: 'Actualizar dirección',
                    state: "Estado",
                    city: "Ciudad",
                    zipcode: "CP",
                    address: "Calle y número",
                    references: "Colonia"
                },
                EXPIRED: {
                    contact: 'Por favor, contacta a ',
                    solve_problem: ' para conciliar una solución sobre este incidente.'
                },
                WRONG_PRODUCT : {
                    reason: 'Probablemente tu pedido sufrió de un error de empaquetado y recibiste el pedido de alguien más.',
                    contact: 'Por favor, contacta a ',
                    solve_problem: ' para conciliar una solución sobre este incidente y que puedas recibir tu compra lo antes posible.'
                },
                RESCHEDULE: {
                    your_order: 'Tu pedido se entregará el ',
                    timezone_range: 'en un horario estimado de 8:30 am a 9:00 pm',
                    reschedule: 'Si reprogramas la entrega, tu nueva fecha de entregá será: ',
                    button: 'Reprogramar para el ',
                    scheduled_delivery: 'Entrega estimada',
                    reschedule_delivery: 'Repogramar fecha de entrega',
                    delivery_here: 'Se entregará aquí: ',
                    incorrect_address: 'La dirección es incorrecta',
                    desire: '¿ Deseas',
                    confirm: 'confirmar',
                    order_reschedule: 'la reprogramación de tu pedido para el ',
                    confirm_request: 'Confirmar',
                    cancel_request: 'Cancelar'
                },
                check_policies: 'Consulta las políticas de cambios y devoluciones en el sitio web de {{store}}'
            },
            errors : {
                UNRESOLVED_INCIDENT: 'Este pedido ya tiene un incidente no resuelto.',
                ERROR_ORDER_ALREADY_RETURNED: 'Este pedido ya fue retornado.',
                ERROR_ORDER_NOT_SHIPPED: "Este pedido aún no ha sido enviado.",
                ERROR_INVALID_CATEGORY_FOR_ORDER_STATUS: "No es posible crear este tipo de incidencias debido al estado de este pedido.",
                ERROR_MAX_IMAGES: 'El primer y tercer campo están limitados a 6 imágenes, el segundo campo está limitado a 2 imágenes. Cada imagen no debe exceder los 5 MB.',
                ERROR_ZIP_CODE_DIGITS: 'Código postal inválido, deben ser 5 dígitos',
                ERROR_ZIP_CODE_COVERAGE: 'La paquetería que lleva tu pedido no tiene cobertura en este nuevo CP. Ingresa otro CP o regresa al menú anterior para descartar el cambio'
            },
            add_image: "Añadir imagen"
        }
    },
    incidentCreated : {
        go_home: 'Regresar al inicio',
        send_email: 'Enviar correo',
        intro: 'Hemos compartido tu solicitud de',
        sucess: 'con la paquetería.',
        description: 'En máximo 72 horas, la paquetería debe atender a esta solicitud'
    },
    formatted_relative_time: {
        today: "Hoy",
        yesterday: "Ayer",
        tomorrow: "Mañana",
        date: "{{day}} {{month}}",
        range: "Entre el {{initial}} {{initial_month}} - {{final}} {{final_month}}",
        from_now: "Hace {{days}} días",
    },
    fileInputField: {
        not_pdf: "El archivo no es PDF",
        max_file: "El archivo es muy grande. Máximo",
        mb: "MB",
        min_file: "El archivo es muy pequeño. Mínimo ",
        max_images: "Sólo puedes agregar hasta",
        max_images_text: "imágenes"
    },

    imageInputField: {
        not_an_image: "El archivo no es una imagen .PNG/JPG/JPEG",
        max_image: "La imagen es muy grande. Máximo",
        mb: "MB.",
        min_image: "La imagen es muy pequeña. Mínimo",
        max_images: "Sólo puedes agregar hasta",
        max_images_text: "imágenes"
    },
    awaiting_order: {
        title: "¡Gracias por tu compra!",
        description_start: "En unos minutos podrás monitorear el movimiento de tu ",
        description_order: "pedido #{{order_number}} ",
        description_end: "desde esta página"
    },
    edit_address: {
        title_description: "Cambiar la dirección de entrega",
        submit_address_button: "Enviar cambios",
        no_coverage: "No existe cobertura para el código postal ingresado",
        submit_editing_order: "Actualizando la dirección de entrega...",
        errors:{
            ERROR_EXACT_LENGTH: {
                shipping_zip_code: "El código postal debe ser de [EXACT_QUANTITY] dígitos",
            },
            ERROR_MIN_LENGTH: {
                shipping_zip_code_international: "El código postal es requerido",
                shipping_zip_code: "El código postal debe tener al menos [MIN_QUANTITY] dígitos",
                shipping_state: "El estado es requerido",
                shipping_city: "La ciudad es requerida",
                shipping_address_1: "La dirección es requerida",
                shipping_address_2: "La dirección es requerida",
                shipping_neighborhood: "El barrio es requerido",
                shipping_number: "El número es requerido"
            },
            ERROR_MAX_LENGTH: {
                shipping_zip_code: "El código postal debe tener máximo [MAX_QUANTITY] dígitos",
                shipping_state: "El estado debe tener máximo [MAX_QUANTITY] caracteres",
                shipping_city: "La ciudad debe tener máximo [MAX_QUANTITY] caracteres",
                shipping_address_1: "La dirección debe tener máximo [MAX_QUANTITY] caracteres",
                shipping_address_2: "La dirección debe tener máximo [MAX_QUANTITY] caracteres",
                shipping_neighborhood: "El barrio debe tener máximo [MAX_QUANTITY] caracteres",
                shipping_number: "El número debe tener máximo [MAX_QUANTITY] caracteres"
            },
            ERROR_INVALID_CONTENT: {
                shipping_zip_code: "Ingresa un código postal válido",
                shipping_state: "Ingresa un estado válido",
                shipping_city: "Ingresa una ciudad válida",
                shipping_address_1: "Ingresa una dirección válida",
                shipping_address_2: "Ingresa una dirección válida",
                shipping_neighborhood: "Ingresa un barrio válido",
                shipping_number: "Ingresa un número válido"
            }
        },
        edit_authorization: {
            title: "Pregunta de seguridad",
            description: "Para cambiar la dirección de entrega de este pedido, responde la siguiente información",
            fields: {
                email: "Correo",
                tax_id: "CPF"
            },
            errors: {
                ERROR_MIN_LENGTH: {
                    email: "El correo es requerido",
                    tax_id: "CPF es requerido"
                },
                ERROR_INVALID_CONTENT: {
                    email: "Ingresa un correo válido",
                    tax_id: "Formato incorrecto de CPF"
                }
            },
            submit_button: "Continuar",
            validating_submit: "Validando la información...",
            edit_unauthorized: "Información incorrecta, verifica la información proporcionada"
        },
        fields: {
            shipping_address_1: "Calle y número",
            shipping_address_2: "Colonia",
            shipping_state: "Estado",
            shipping_city: "Ciudad",
            shipping_zip_code: "CP",
            shipping_neighborhood: "Barrio",
            shipping_number: "Número"
        }
    }
}