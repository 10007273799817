import axios from "axios"

const qs = require('qs')

// Serialize nested objects
const objectSerializer = function (config) {
    config.paramsSerializer = params => {
        return qs.stringify(params, {
            arrayFormat: "brackets",
            encode: false,
            strictNullHandling: true
        })
    }
    return config
}

export default (history = null) => {
    const baseURL = process.env.REACT_APP_PLATFORM_URL

    const axiosInstance = axios.create({
        baseURL: baseURL,
        headers: {},
    })

    axiosInstance.interceptors.request.use(objectSerializer)

    return axiosInstance;
}