
export const sendAnalytics = (eventName, data) => {
    const sortedOrderHistory = data?.order?.order_histories?.sort((a, b) => new Date(a.reference_timestamp) - new Date(b.reference_timestamp))

    const analyticsContent = {
        order_id: data?.order?.id,
        store_id: data?.order?.store?.id,
        current_new_status: sortedOrderHistory?.length > 0 ? sortedOrderHistory[sortedOrderHistory.length - 1].new_status : null
    }

    window.analytics.track(eventName, analyticsContent)
}
